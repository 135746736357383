import { api } from '@/fsd/data/api/api.service';
import Constants from '@/models/Constants';
import Permit from '@/models/Permit';
import { UserLanguageEnum } from '@/models/User';
import { AudioService } from '@/services/audio.service';
import EventService from '@/services/event.service';
import { ModeService } from '@/services/mode.service';
import { VersionService } from '@/services/version.service';
import { useIndicators } from '@/store/indicators';
import { useItems } from '@/store/modules/items';
import { useOrders } from '@/store/modules/orders';
import { useProducts } from '@/store/modules/products';
import { useShelves } from '@/store/modules/shelves';
import { languages } from '@/temp/constants';
import { errorMessages } from '@/temp/constants/translations';
import { logger } from '@/temp/plugins/logs';
import { getErrorText, setAdditional } from '@/utils';
import { QueryParams } from '@/utils/query-configurator';
import dayjs from 'dayjs';
import { defineStore } from 'pinia';
import data from '../../../public/version.json';

import { LangService } from '@/fsd/data/services/lang.service';
import { PlatformService } from '@/fsd/data/services/platform.service';
import { useBranding } from '@/fsd/features/tools/useBranding';
import { Modal } from '@/fsd/shared/tools/modalNotification';
import { OrderTypeEnum } from '@/models/orders/BaseOrder';
import { JuniorRestrictions } from '@/types/config';
import uuidv1 from 'uuid/v1';

export interface User {
  user: string;
  fullname: string;
  mode: 'wms';
  store: string;
  token: string;
  email?: string;
  experiments?: any;
  lang: string;
  role: string;
  sessions: Record<string, { source: 'tsd' | string }>;
  company_id: string;
  // Флаг, что показывает нам то, что исполнитель несовершеннолетний
  is_junior: boolean;
}

export enum ThemeEnum {
  main = 'main',
  deli = 'deli',
  dark = 'dark',
}

type Options =
  | undefined
  | {
      user: User;
      //текущее время бека
      now: string;
      permits: Permit[];
      constants: Constants;
    };
export type ChatMetaData = {
  [key: string]: string;
};

export enum SupportTypeEnum {
  chatterbox = 'chatterbox',
  auto_edit = 'auto_edit',
  sapsan = 'sapsan',
}

export const useUser = defineStore('user', {
  state() {
    return {
      user: ModeService.isProduction() ? undefined : (JSON.parse(localStorage.getItem('user')!) as User),
      language: UserLanguageEnum.ru_RU,
      dataLanguage: '',
      theme: ThemeEnum.main,
      device: (ModeService.isDevelopment() ? uuidv1() : '') as string,
      options: undefined as Options,
      chatMetaData: {} as ChatMetaData,
      // отклонение во времени устройства от времени сервера в мс, если положительное, то клиент - "опаздывает"
      timeOffset: 0,
      // Версия apk, полученная от устройства ТСД
      apkVersion: '',
      queryParams: {} as QueryParams,
      // тип поддержки. в России это chattterbox, для наны это autoedit
      supportType: SupportTypeEnum.chatterbox,
      // Ограничения по типам документов для молодых кладанов
      juniorRestrictions: {} as JuniorRestrictions,
      weightLimit: 10000,
    };
  },
  getters: {
    isRTL(state) {
      return [UserLanguageEnum.he_HE, UserLanguageEnum.ar_SA].includes(state.language);
    },
    role(state) {
      if (state.options) {
        return state.options.user.role;
      }
      return 'quest';
    },
    isAuthenticated(state) {
      return Boolean(state.user);
    },
    version: () => {
      return process.env.VUE_APP_VERSION || '-';
    },
    appVersion: () => {
      return data.appVersion;
    },
    packmanVersion: () => {
      return data.packmanVersion;
    },
    name(state) {
      if (!this.isAuthenticated) {
        return '';
      }
      if (state.options?.user?.fullname) {
        //Имя
        return state.options.user.fullname;
      }
      //Ник
      return state.user?.fullname || '';
    },
    userId(state) {
      if (state.user) {
        return state.user.user;
      }
      return '';
    },
    session(state) {
      const sessions = state.options?.user.sessions;
      if (!sessions) return;
      const currentSession = Object.entries(sessions).find(([_, session]) => session.source === 'tsd');

      const sessionId = currentSession ? currentSession[0] : undefined;

      return sessionId;
    },
    sherlockBorder(state) {
      return state.options?.constants.store.options_setup.sherlock_border;
    },
    storeId(state) {
      if (state.options) {
        // @ts-expect-error
        return state.options.user.store_id;
      }
      return '';
    },
    experimentByName: state => name => {
      // если пользователь залогинен и привязан к складу
      if (state.options && state.options.constants.store) {
        return state.options.constants.store.options[name];
      }
      return false;
    },
    currency(state) {
      return state.options?.constants.store.currency;
    },
    permitByName: state => name => {
      if (state.options) {
        const permit = state.options.permits.find(item => item.name === name);
        if (permit) {
          return permit.value;
        }
        // если возвращаем undefined, значит мы не знаем раскатали бек или еще нет
        return undefined;
      }
      return false;
    },
    token(state) {
      if (!this.isAuthenticated) {
        return '';
      }
      return `Bearer ${state.user!.token}`;
    },
    isJunior(state) {
      return state.options?.user.is_junior || false;
    },
    getMaxWeightForOrder(state) {
      // acceptance_max_weight
      // move_max_weight
      // hand_move_max_weight
      // order_max_weight
      // stowage_max_weight
      // sale_stowage_max_weight
      // weight_stowage_max_weight
      return (type: OrderTypeEnum) => {
        const postfix = '_max_weight';
        const key = type + postfix;
        return state.juniorRestrictions[key];
      };
    },
  },
  actions: {
    set(payload) {
      if (payload) {
        Ya.Rum.updateErrors({
          loggedin: true,
        });
        setAdditional({
          userId: payload.user,
          userStoreId: payload.store,
          platform: PlatformService.getMode(),
        });
      } else {
        Ya.Rum.updateErrors({
          loggedin: false,
        });
        setAdditional({
          userId: this.user && this.user.user,
          userStoreId: this.user && this.user.store,
          platform: PlatformService.getMode(),
        });
      }

      this.user = payload;
      if (payload?.lang && languages[payload.lang]) {
        this.language = payload.lang;
      } else {
        this.language = UserLanguageEnum.ru_RU;
      }

      if (!ModeService.isProduction()) {
        if (payload) {
          localStorage.setItem('user', JSON.stringify(payload));
        } else {
          localStorage.removeItem('user');
        }
      } else {
        localStorage.removeItem('user');
      }
    },
    setDevice(payload) {
      this.device = payload;
      setAdditional({
        deviceId: payload,
      });
    },
    setLanguageMutation(lang: UserLanguageEnum) {
      lang = languages[lang] ? lang : UserLanguageEnum.ru_RU;
      this.language = lang;
      LangService.setLang(lang);
      if (lang === UserLanguageEnum.he_HE) {
        this.theme = ThemeEnum.deli;
      }
    },
    setDataLanguageMutation(dataLanguage: string | null) {
      this.dataLanguage = dataLanguage ?? UserLanguageEnum.ru_RU;
    },
    setOptions(payload) {
      this.options = payload;
      if (payload) {
        this.timeOffset = dayjs(payload.now).diff(dayjs());
      } else {
        this.timeOffset = 0;
      }
    },
    setTheme(payload: ThemeEnum) {
      this.theme = payload;
    },
    setPermit(permits) {
      if (this.options) {
        this.options.permits = permits;
      }
    },
    setToken(token) {
      if (this.user) {
        this.user.token = token;
      } else {
        // @ts-expect-error
        this.user = {
          token,
        };
      }
    },
    // Мета данные для открытия чата с поддержкой. Прокидываются в query при открытии iframe
    setChatMetaData(payload: ChatMetaData) {
      this.chatMetaData = payload;
    },
    // Устанавливаем текущую версию apk
    setApkVersion(payload: string) {
      this.apkVersion = payload;
    },
    // параметры из квери для кастомизации приложения
    setQueryParams(payload: QueryParams) {
      this.queryParams = payload;
    },
    setSupportType(payload: SupportTypeEnum) {
      this.supportType = payload;
    },
    setJuniorRestrictions(payload: JuniorRestrictions = {}) {
      this.juniorRestrictions = payload;
    },
    setStore(store_id: User['store']) {
      if (this.user) {
        this.user.store = store_id;
      }
    },
    async setTokenAction(token) {
      this.setToken(token);
      await this.loadOptions();
      this.set({
        // @ts-expect-error pinia
        fullname: this.options.user.fullname,
        // @ts-expect-error pinia
        permits: this.options.permits,
        // @ts-expect-error pinia
        store: this.options.user.store_id,
        // @ts-expect-error pinia
        token: this.user.token,
        // @ts-expect-error pinia
        user: this.options.user.user_id,
      });
    },
    async loadOptions() {
      try {
        const response = await api.user.options();
        const { setBranding } = useBranding();
        setBranding(response.data?.constants?.domain_configs?.branding);
        this.setOptions(response.data);
        this.setLanguageMutation(response.data.user.lang);
        this.setDataLanguageMutation(response.data.user.data_language);
        await this.loadSupportTypeConfig();
        await this.loadJuniorRestrictionsConfig();
      } catch (error) {
        logger.error(error, { type: 'store', method: 'loadOptions' });
      }
    },
    async loadSupportTypeConfig() {
      try {
        const store_id = this.storeId;
        if (!store_id) {
          return;
        }
        const response = await api.configs.store.load({
          store_id,
          group: 'options',
          name: 'order_edit',
        });

        // для русских складов возвращает просто {}
        const supportType = response.data?.result?.order_edit?.value?.option || SupportTypeEnum.chatterbox;
        this.setSupportType(supportType);
      } catch (e) {
        logger.error(e);
      }
    },
    async loadJuniorRestrictionsConfig() {
      if (!this.isJunior) return;
      try {
        const store_id = this.storeId;
        if (!store_id) {
          return;
        }
        const response = await api.configs.store.load({
          store_id,
          group: 'options',
          name: 'executer_junior_restrictions',
        });
        const junior_restrictions = response.data?.result?.executer_junior_restrictions?.value;
        this.setJuniorRestrictions(junior_restrictions);
      } catch (e) {
        logger.error(e);
      }
    },
    async upgrade(payload: { pin: string }) {
      try {
        const resp = await api.user.upgrade(payload);
        this.setPermit(resp.data.permits);
        this.setToken(resp.data.token);
        return resp;
      } catch (error: any) {
        logger.error(error, { method: 'upgrade', type: 'api', source: 'user.ts' });
        throw error;
      }
    },
    async downgrade() {
      try {
        const resp = await api.user.downgrade();
        this.setPermit(resp.data.permits);
        this.setToken(resp.data.token);
        return resp;
      } catch (error) {
        logger.error(error, { method: 'downgrade', type: 'api', source: 'user.ts' });
        throw error;
      }
    },
    async setLanguage(lang: UserLanguageEnum) {
      await api.user.options_save({ lang });
      this.setLanguageMutation(lang);
    },
    async setContentLanguage(dataLanguage: string) {
      await api.user.options_save({ data_language: dataLanguage });
      this.dataLanguage = dataLanguage;
    },
    async getApkVersion() {
      try {
        const response = await VersionService.getApkVersion();
        this.setApkVersion(response);
      } catch (e) {
        return;
      }
    },
    async logout(freeDevice = true) {
      // сообщаем бекенду о том, что мы разлогиниваемся
      if (freeDevice && this.session) await api.user.logout({ device: this.session });
      // чистим хранилища
      useUser().clear();
      useProducts().clear();
      useItems().clear();
      useIndicators().clearIndicators();
      useOrders().clear();
      useShelves().clear();
      // останавливаем поллинг ивентов
      useOrders().stopPolling();
      EventService.stopEvents();
      // пушим на страницу входа
    },
    async login(payload) {
      try {
        const { data } = await api.user.assign_device(payload);
        this.set(data);
        await this.loadOptions();
      } catch (error: any) {
        AudioService.playError();
        const title = errorMessages.ER_LOGIN;
        const text = getErrorText(error);
        Modal.show({ title, text });
        throw error;
      }
    },
    clear() {
      this.set(undefined);
      this.setOptions(undefined);
    },
  },
});
