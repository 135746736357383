<template>
  <div class="w-full h-14 flex items-stretch bg-control-minor rounded-2xl">
    <button
      data-test="counter minus"
      class="w-20 flex justify-center items-center fill-text-primary disabled:fill-text-secondary"
      :disabled="value <= min || disabled"
      @click="onMouseClick(-1)"
      @touchstart="onStart(-1)"
      @touchcancel="onStop"
      @touchend="onStop"
    >
      <IconMinus class="fill-inherit" />
    </button>
    <div
      data-test="counter calc-activator"
      class="w-full h-[42px] mt-[7px] py-3 flex items-center rounded-xl bg-main justify-center"
      @click="$emit('showCalc')"
    >
      <Title4 data-test="counter value">
        {{ value }}
      </Title4>
    </div>
    <button
      data-test="counter plus"
      class="w-20 flex justify-center items-center fill-text-primary disabled:fill-text-secondary"
      :disabled="value >= max || disabled"
      @click="onMouseClick(1)"
      @touchstart="onStart(1)"
      @touchcancel="onStop"
      @touchend="onStop"
    >
      <IconPlus class="fill-inherit" />
    </button>
  </div>
</template>
<script setup lang="ts">
import { CounterFieldProps } from '@/fsd/entities/counter/types';
import IconMinus from '@/fsd/shared/icons/icon-minus.vue';
import IconPlus from '@/fsd/shared/icons/icon-plus.vue';
import { ModeService } from '@/services/mode.service';
import { onBeforeUnmount } from 'vue';

const props = withDefaults(defineProps<CounterFieldProps>(), {
  max: Number.MAX_SAFE_INTEGER,
  min: 0,
  value: 0,
  disabled: false,
});

let clickIncrease: number = 1;
let clickIntense: number = 1;
let hasClickCount = false;
let timerId: number | undefined = undefined;

const emits = defineEmits<{
  (e: 'change', value: number): void;
  (e: 'showCalc'): void;
}>();

function change(direction: 1 | -1) {
  const newCount = props.value + Math.floor(direction * clickIncrease);

  if (newCount <= props.max && newCount >= props.min) {
    emits('change', newCount);
  }
}

function onMouseClick(direction: 1 | -1) {
  if (ModeService.isDevelopment()) {
    change(direction);
  }
}

// лонг-тап
function onStart(direction: 1 | -1) {
  if (!hasClickCount) {
    change(direction);
    hasClickCount = true;
  }
  if (timerId) {
    return;
  }
  timerId = setTimeout(() => {
    timerId = undefined;
    if (hasClickCount) {
      change(direction);
      if (clickIntense <= 4) {
        clickIntense *= 1.15;
      }
      if (clickIncrease <= 10) {
        clickIncrease *= 1.03;
      }
      onStart(direction);
    }
  }, 400 / clickIntense);
}
// лонг-тап
function onStop() {
  hasClickCount = false;
  clickIntense = 1;
  clickIncrease = 1;
  if (timerId) {
    clearTimeout(timerId);
    timerId = undefined;
  }
}

onBeforeUnmount(() => {
  if (timerId) {
    clearTimeout(timerId);
  }
});
</script>
