import { OrangeIconWarning } from '@/fsd/shared/icons/warning/OrangeIconWarning';
import { Modal } from '@/fsd/shared/tools/modalNotification';
import { $gettext } from '@/temp/plugins/gettext';
import { markRaw } from 'vue';

export default () => {
  return Modal.show({
    title: $gettext('Отсканирована отсутствующая в заказе марка'),
    text: $gettext('Нужно отсканировать ту же марку, что и при сборке'),
    component: markRaw(OrangeIconWarning),
  });
};
