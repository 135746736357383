<template>
  <div class="bg-warmGray-550 rounded-full w-10 h-10 flex items-center justify-center">
    <IconScales color="white" class="m-2.5 w-full h-full" />
  </div>
</template>

<script lang="ts">
import IconScales from '@/temp/icons/icon-scales.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'IconScalesForModal',
  components: { IconScales },
});
</script>
