import { OrangeIconWarning } from '@/fsd/shared/icons/warning/OrangeIconWarning';
import { Modal } from '@/fsd/shared/tools/modalNotification';
import { $gettext } from '@/temp/plugins/gettext';
import { markRaw } from 'vue';

export default () => {
  return Modal.show({
    title: $gettext('Требуется марка "честный знак"'),
    text: $gettext('Отсканируйте марку "честный знак"'),
    component: markRaw(OrangeIconWarning),
  });
};
