<template>
  <Layout>
    <template #header>
      <OrderRetailHeaderChange :order="order" @back="closeChange" />
    </template>
    <template #default>
      <div class="h-full bg-element flex flex-col gap-2">
        <div class="bg-main rounded-b-3xl p-4">
          <Title2 class="font-bold px-1">{{ $gettext('Добавление замены') }}</Title2>
          <OrderRetailSuggestCard
            class="mt-2"
            :collected="suggest.result_count || collected || 0"
            :suggest="suggest"
            :need-buttons="false"
            @menu="$emit('openSuggestMenu', suggest)"
            @remove="$emit('removeSuggest', suggest)"
          />
        </div>
        <div v-if="childrenSuggest.length" class="bg-main rounded-t-3xl p-4 h-full">
          <Title4 class="font-bold px-1 mt-2">{{ $gettext('Заменить на') }}</Title4>
          <OrderRetailSuggestCardLite
            v-for="child in childrenSuggest"
            :key="child.suggest_id"
            class="my-3"
            :collected="child.result_count || 0"
            :suggest="child"
            @remove="() => removeChild(child)"
          />
        </div>
        <EmptyScreen v-else class="bg-main rounded-t-3xl" :text="$gettext('Отсканируйте товары на замену')" />
      </div>
    </template>
    <template #footer>
      <OrderRetailFooterChange
        :suggest="suggest"
        :children-suggest="childrenSuggest"
        :order="order"
        :collected="collected"
        @done="$emit('changeDone', suggest)"
      />
    </template>
  </Layout>
</template>

<script setup lang="ts">
import { prepareBarcodes } from '@/fsd/entities/suggest/tools/useShelf2Box';
import {
  OrderRetailRescanModal,
  OrderRetailSuggestCard,
  OrderRetailSuggestCardLite,
  resetSuggests,
} from '@/fsd/features/suggest';
import { Alerts } from '@/fsd/shared/tools/alertNotification';
import { Modal } from '@/fsd/shared/tools/modalNotification';
import { callAction } from '@/fsd/shared/ui/action';
import { EmptyScreen } from '@/fsd/shared/ui/emptyScreen';
import { ButtonPositionsEnum } from '@/fsd/shared/universalModal';
import { useRequestBarcode } from '@/hooks/useRequestBarcode';
import Product from '@/models/Product';
import Suggest, { SuggestStatusEnum } from '@/models/Suggest';
import OrderOrderRetail from '@/models/orders/OrderOrderRetail';
import { useOrders } from '@/store/modules/orders';
import { useProducts } from '@/store/modules/products';
import { $gettext } from '@/temp/plugins/gettext';
import Layout from '@/ui/common/layout.vue';
import { useLoader } from '@/ui/common/loader/useLoader';
import Title2 from '@/ui/common/typo/title-2.vue';
import { computed, defineEmits, defineProps, onMounted, ref, watch } from 'vue';
import OrderRetailFooterChange from './order-retail-footer-change.vue';
import OrderRetailHeaderChange from './order-retail-header-change.vue';

const productsStore = useProducts();
const ordersStore = useOrders();
const { showLoader } = useLoader();

const childrenCollected = ref<Record<string, string[]>>({});
const hasNewChildren = ref<boolean>(false);

interface ChangeProductProps {
  suggest_id: Suggest['suggest_id'];
  order: OrderOrderRetail;
  collected: number;
}

const props = defineProps<ChangeProductProps>();

const emits = defineEmits<{
  (e: 'close'): void;
  (e: 'openSuggestMenu', suggest: Suggest): void;
  (e: 'removeSuggest', suggest: Suggest): void;
  (e: 'suggestDone', suggest: Suggest, barcodes?: string[]): void;
  (e: 'changeDone', suggest: Suggest);
}>();

const suggest = computed<Suggest>(() => {
  return props.order.suggestById(props.suggest_id)!;
});

const allChildrenSuggest = computed<Suggest[]>(() => {
  return suggest.value.allChildrenSuggest;
});

const childrenSuggest = computed<Suggest[]>(() => {
  return suggest.value.fullChildrenSuggest;
});

const nonClosedChildSuggest = computed<Suggest[]>(() => {
  return suggest.value.nonClosedChildrenSuggest;
});

const finishChildSuggest = async (product_id: Product['product_id']): Promise<void> => {
  const childSuggest = suggest.value.allChildrenSuggest.find(child => {
    if (child.product_id === product_id) return child;
  });

  if (childSuggest && childrenCollected.value[product_id]) {
    hasNewChildren.value = true;
    emits('suggestDone', childSuggest, childrenCollected.value[product_id]);
  }
};

const updateChildrenCollected = (product_id: Product['product_id'], barcode: string) => {
  if (childrenCollected.value[product_id]) {
    childrenCollected.value[product_id].push(barcode);
  } else {
    childrenCollected.value[product_id] = [barcode];
  }
};

onMounted(() => {
  childrenSuggest.value.forEach(suggest => {
    suggest.barcodes.forEach(barcode => {
      updateChildrenCollected(suggest.product_id, barcode);
    });
  });

  if (nonClosedChildSuggest.value.length) {
    reScanNonClosedChild();
  }
});

const hasProductInOrder = async (product_id: Product['product_id']): Promise<boolean> => {
  const suggest = props.order.suggests.find(suggest => suggest.product_id === product_id);

  if (suggest && !suggest?.isChildSuggest) {
    await Modal.show({
      title: $gettext('Данный продукт уже существует в заказе'),
      text: $gettext('Чтобы его добавить, откройте карточку этого товара в списке и просканируйте товар'),
    });
    return true;
  }

  return false;
};

const hasProductInChildren = (product_id: Product['product_id']): boolean => {
  return !!allChildrenSuggest.value.find(suggest => suggest.product_id === product_id);
};

const addProduct = async (product: Product): Promise<void> => {
  const { closeLoader } = showLoader();

  try {
    const barcodes = childrenCollected.value[product.product_id] || [];
    await ordersStore.signal({
      order_id: props.order.order_id,
      signal: 'more_product',
      data: {
        product_id: product.product_id,
        count: product.isTrueWeight ? Product.weightFromBarcode(barcodes) : barcodes.length,
        parent_suggest_id: suggest.value.suggest_id,
        barcodes: prepareBarcodes(barcodes),
        status: SuggestStatusEnum.done,
      },
    });
    await new Promise<void>(resolve => {
      const unWatch = watch(allChildrenSuggest, suggests => {
        for (const s of suggests) {
          if (s.status === SuggestStatusEnum.done && s.product_id === product.product_id) {
            resolve();
            unWatch();
            return;
          }
        }
      });
    });
  } catch (e) {
    console.error(e);
  } finally {
    closeLoader();
  }
};

useRequestBarcode(async barcode => {
  const { closeLoader } = showLoader();
  try {
    const product = await productsStore.getProductByBarcode(barcode);

    if (hasProductInChildren(product.product_id)) {
      updateChildrenCollected(product.product_id, barcode);
      await finishChildSuggest(product.product_id);
      return true;
    }
    closeLoader();
    const hasProduct = await hasProductInOrder(product.product_id);

    if (!hasProduct) {
      updateChildrenCollected(product.product_id, barcode);
      await addProduct(product);
    }

    return true;
  } catch (error) {
    Alerts.error($gettext('Отсканирован неверный баркод'));
    return true;
  } finally {
    closeLoader();
  }
});

const closeChange = async () => {
  if (childrenSuggest.value.length && hasNewChildren.value) {
    const confirm = await Modal.show({
      title: $gettext('Отмена замен'),
      text: $gettext('Все текущие замены будут удалены'),
      btnPosition: ButtonPositionsEnum.horizontal,
      confirmBtnTitle: $gettext('Ок'),
      closeBtnTitle: $gettext('Отменить'),
    });

    if (confirm) {
      await resetSuggests(props.order, childrenSuggest.value);
      const { closeLoader } = showLoader();
      await new Promise<void>(resolve => {
        const unWatch = watch(childrenSuggest, suggests => {
          if (!suggests.length) {
            resolve();
            unWatch();
            return;
          }
        });
      });
      closeLoader();
      emits('close');
    }
  } else {
    emits('close');
  }
};

const removeChild = (suggest: Suggest) => {
  childrenCollected.value[suggest.product_id] = [];
  emits('removeSuggest', suggest);
};

const reScanNonClosedChild = async (): Promise<void> => {
  await callAction(OrderRetailRescanModal, { suggest: suggest.value });

  if (nonClosedChildSuggest.value.length) {
    await resetSuggests(props.order, nonClosedChildSuggest.value);
    const { closeLoader } = showLoader();
    await new Promise<void>(resolve => {
      const unWatch = watch(nonClosedChildSuggest, suggests => {
        if (!suggests.length) {
          resolve();
          unWatch();
          return;
        }
      });
    });
    closeLoader();
  }
};
</script>
