<template>
  <p class="px-[3px] text-center" :class="fpsIndicatorColorClass">{{ fps }}</p>
</template>

<script setup lang="ts">
import { logger } from '@/temp/plugins/logs';
import { useFps } from '@vueuse/core';
import { computed, watch } from 'vue';

const fps = useFps({ every: 120 });

const fpsIndicatorColorClass = computed<string[]>(() => {
  const classes: string[] = [];
  if (fps.value > 60) {
    classes.push('w-[33px]');
  } else {
    classes.push('w-[24px]');
  }

  if (fps.value >= 40) {
    classes.push('text-green-normal');
  } else if (fps.value >= 20) {
    classes.push('text-yellow-dark');
  } else {
    classes.push('text-red-normal');
  }

  return classes;
});

let sendedError = false;
watch(fps, val => {
  if (val <= 5 && !sendedError) {
    sendedError = true;
    logger.error('status/fps', {
      additional: {
        fps: val,
      },
    });
  }
  if (val > 20) {
    sendedError = false;
  }
});
</script>
