<template>
  <SignalQualityIcon class="w-4" :class="signalQualityIndicatorColorClass" />
</template>

<script setup lang="ts">
import { useIndicators } from '@/store/indicators';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import SignalQualityIcon from './icons/SignalQualityIcon.vue';

const { requestTime } = storeToRefs(useIndicators());

const signalQualityIndicatorColorClass = computed<string>(() => {
  if (requestTime.value >= 10) {
    return 'text-red-normal';
  }
  if (requestTime.value >= 3) {
    return 'text-yellow-dark';
  }
  return 'text-green-normal';
});
</script>
