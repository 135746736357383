<template>
  <Layout>
    <template #header>
      <Bar v-if="!loading" :caption="shelf?.title" type="shelf" :menu-config="menuConfig" @close-click="toHomePage" />
    </template>
    <template #default>
      <div v-if="!loading" class="mt-4 flex flex-col flex-1 overflow-auto">
        <SuggestCard
          v-for="item in availableItems"
          :key="item.product_id"
          :available-item="item"
          :suggest-menu-config="productMenuConfig(item)"
          card-type="item"
          need-menu
          doc-type="shelf"
          @click="openItemCard(item.product_id)"
        />
        <SuggestCard
          v-for="product in availableProducts"
          :key="product.product_id"
          :available-product="product"
          :suggest-menu-config="productMenuConfig(product)"
          card-type="product"
          :need-menu="needShowMenu"
          doc-type="shelf"
          @click="openProductCard(product.product_id)"
        />
      </div>

      <ReturnFromWriteoff
        v-if="returnFromWriteoff.visible.value"
        :shelf-id="shelf_id"
        @finish="() => returnFromWriteoff.executeAndHide(reload)"
      />
      <CreateRepacking v-if="createRepacking.visible.value" :shelf-id="shelf_id" @close="createRepacking.hide" />
    </template>
    <template #footer>
      <div class="p-4">
        <UiButton background-color="secondary" @click="toHomePage">
          {{ $gettext('Закрыть') }}
        </UiButton>
      </div>
    </template>
  </Layout>
</template>

<script setup lang="ts">
import { api } from '@/fsd/data/api/api.service';
import { useCheckShelfResources } from '@/fsd/data/utils/checkResourse';
import { useSubscribeOnSuggests } from '@/fsd/data/utils/subscribeOnSuggests';
import { useCheck } from '@/fsd/entities/suggest/tools/useCheck';
import { useEndOrder } from '@/fsd/features/order/utils/useEndOrder';
import { useRumPage } from '@/fsd/shared/metrics';
import { Alerts } from '@/fsd/shared/tools/alertNotification';
import { Modal } from '@/fsd/shared/tools/modalNotification';
import { ButtonPositionsEnum } from '@/fsd/shared/universalModal';
import { useComponent } from '@/hooks/useComponent';
import { useRequestBarcode } from '@/hooks/useRequestBarcode';
import { AvailableItem } from '@/models/Item';
import ItemByBarcode from '@/models/ItemByBarcode';
import Product from '@/models/Product';
import ProductByBarcode from '@/models/ProductByBarcode';
import Shelf from '@/models/Shelf';
import ShelfByBarcode from '@/models/ShelfByBarcode';
import BaseOrder from '@/models/orders/BaseOrder';
import { AudioService } from '@/services/audio.service';
import { OrderCheckRequest } from '@/services/requests';
import { OrderCheckResponse } from '@/services/response';
import { useItems } from '@/store/modules/items';
import { useOrders } from '@/store/modules/orders';
import { useProducts } from '@/store/modules/products';
import { useShelves } from '@/store/modules/shelves';
import { useUser } from '@/store/modules/user';
import { permits } from '@/temp/constants';
import { $gettext } from '@/temp/plugins/gettext';
import { logger } from '@/temp/plugins/logs';
import { AvailableProduct } from '@/types/product';
import Bar from '@/ui/common/bar/bar.vue';
import Layout from '@/ui/common/layout.vue';
import { useLoader } from '@/ui/common/loader/useLoader';
import { MenuItemConfig } from '@/ui/common/menu/types';
import SuggestCard from '@/ui/common/suggest-card/suggest-card.vue';
import UiButton from '@/ui/common/ui-button.vue';
import CreateRepacking from '@/views/ShelfCard/CreateRepacking.vue';
import ReturnFromWriteoff from '@/views/ShelfCard/ReturnFromWriteoff.vue';
import { AxiosError, AxiosResponse } from 'axios';
import uuidv1 from 'uuid/v1';
import { computed, ref, toRefs, watch } from 'vue';
import { useRouter } from 'vue-router';

const props = defineProps<{ shelf_id: Shelf['shelf_id'] }>();

const { shelf_id } = toRefs(props);
const uiStateOrderId = ref<string>('');

const { showLoader } = useLoader();
const createRepacking = useComponent();
const returnFromWriteoff = useComponent();
const router = useRouter();

let { loading, reload } = useCheckShelfResources(shelf_id.value);
watch(shelf_id, () => {
  const result = useCheckShelfResources(shelf_id.value);
  loading = result.loading;
  reload = result.reload;
});

useRumPage(loading);

const order = computed((): BaseOrder | undefined => {
  return useOrders().orderById(uiStateOrderId.value);
});

const shelf = computed((): Shelf => {
  return useShelves().shelfById(shelf_id.value)!;
});

const available = computed((): AvailableProduct[] | AvailableItem[] => {
  return useShelves().availableById(shelf_id.value);
});

const availableProducts = computed((): AvailableProduct[] => {
  let products = available.value.filter(p => useProducts().productById(p.product_id));
  if (useUser().isJunior) {
    products = products.filter(p => !useProducts().productById(p.product_id)?.underage_restricted);
  }
  return products;
});

const availableItems = computed((): AvailableItem[] => {
  return available.value.filter(i => {
    return Boolean(useItems().itemById(i.product_id));
  });
});

const productMenuConfig = computed((): ((product: AvailableProduct | AvailableItem) => MenuItemConfig[]) => {
  return product => {
    const menuConfig: MenuItemConfig[] = [];

    const removeProductBtn: MenuItemConfig = {
      buttonText: $gettext('Удалить товары с этой полки'),
      condition: () => Boolean(useUser().permitByName(permits.tsd_create_check)),
      onClick: () => {
        onDelete(product);
      },
    };
    menuConfig.push(removeProductBtn);

    return menuConfig;
  };
});

const needShowMenu = computed((): boolean => {
  return Boolean(useUser().permitByName(permits.tsd_create_check));
});

const menuConfig = computed((): MenuItemConfig[] => {
  const menuConfig: MenuItemConfig[] = [];

  const returnFromWriteoffBtn: MenuItemConfig = {
    buttonText: $gettext('Вернуть товар со списания'),
    onClick: () => {
      returnFromWriteoff.show();
    },
    dataTest: 'bar menu-item return-from-writeoff',
    condition: () =>
      Boolean(
        !loading.value &&
          ['trash', 'kitchen_trash'].includes(shelf.value!.type) &&
          useUser().permitByName(permits.tsd_trash_revert),
      ),
  };
  menuConfig.push(returnFromWriteoffBtn);

  const createRepackingBtn: MenuItemConfig = {
    buttonText: 'Создать перефасовку',
    onClick: () => {
      createRepacking.show();
    },
    dataTest: 'bar menu-item create-repacking',
    condition: () =>
      Boolean(
        !loading.value &&
          ['repacking', 'kitchen_trash'].includes(shelf.value!.type) &&
          useUser().permitByName(permits.tsd_repacking),
      ),
  };
  menuConfig.push(createRepackingBtn);

  return menuConfig;
});
useRequestBarcode(async barcode => {
  const { closeLoader } = showLoader(undefined);
  try {
    const { data } = await api.barcode({ barcode });
    closeLoader();
    if (!data || !data.found.length) {
      Alerts.error($gettext('Не найден штрихкод %{barcode}', { barcode }));
      return true;
    }
    const found = data.found[0];
    if (ProductByBarcode.isProductByBarcode(found)) {
      const availableProduct = await filterMultiProduct(data.found, barcode);
      if (!availableProduct) return true;
      await openProductCard(availableProduct.product_id);
      return false;
    }

    if (ItemByBarcode.isItemByBarcode(found)) {
      await openItemCard(found.item_id);
      return false;
    }

    if (ShelfByBarcode.isShelfByBarcode(found)) {
      await router.push({ name: 'shelf-card', params: { shelf_id: found.shelf_id } });
      return false;
    }
    AudioService.playError();
    return true;
  } catch (error) {
    closeLoader();
    Alerts.error($gettext('Не найден штрихкод'));
    return true;
  }
});

const filterMultiProduct = async (found, barcode): Promise<AvailableProduct | undefined> => {
  try {
    if (found.length === 1) {
      return found[0];
    }
    logger.event('MULTI_ITEM_ON_BARCODE', barcode);
    for (const f of found) {
      const available = await useProducts().fetchAvailable(f.product_id);
      // @ts-expect-error pinia
      if (available.length > 0) {
        return f;
      }
    }
    Alerts.error('По этому штрихкоду найдено несколько товаров, сообщите в поддержку');
    return found[0];
  } catch (error) {
    logger.error(error, { method: 'filterMultiProduct', type: 'home' });
  }
};
const onDelete = async (product: AvailableProduct | AvailableItem): Promise<void> => {
  if (!['store', 'markdown'].includes(shelf.value.type)) {
    Alerts.error($gettext('Невозможно удалить товары с этой полки'));
    return;
  }
  const confirmed = await Modal.show({
    title: $gettext('Удалить товар с полки?'),
    btnPosition: ButtonPositionsEnum.horizontal,
    confirmBtnTitle: $gettext('Да, товар отсутсвует'),
  });
  if (confirmed) await onDeleteConfirm(product);
};

const onDeleteConfirm = async (product: AvailableProduct | AvailableItem): Promise<void> => {
  const { closeLoader } = showLoader();
  try {
    if (!uiStateOrderId.value) {
      await createCheckOrder({
        shelf_id: shelf_id.value,
        product_id: product.product_id,
      });
    }
    await finishCheckSuggest(product);
    const result = await useEndOrder(order.value?.order_id);
    if (result) {
      await router.push({ name: 'home' });
    }
  } catch (error) {
    console.error(error);
  } finally {
    closeLoader();
  }
};

const finishCheckSuggest = async (product: AvailableProduct | AvailableItem): Promise<void> => {
  if (!order.value?.suggests) return;
  const suggest = order.value.suggests.find(
    s => s.status === 'request' && s.shelf_id === shelf_id.value && s.product_id === product.product_id,
  );
  if (!suggest) return;
  const result = await useCheck(order.value.order_id, {
    suggest_id: suggest.suggest_id,
    count: 0,
  });
  if (!result) return;
};

const createCheckOrder = async (option: {
  shelf_id: Shelf['shelf_id'];
  product_id: Product['product_id'];
}): Promise<void> => {
  const createPayload: OrderCheckRequest = {
    order_id: uuidv1(),
    check: {
      shelf_id: option.shelf_id,
      product_id: option.product_id,
    },
    mode: 'check_product_on_shelf',
    update_valids: false,
  };
  const { closeLoader, updateLoader } = showLoader($gettext('Отправляем запрос на создание пересчета'));
  try {
    const response: AxiosResponse<OrderCheckResponse> = await useOrders().createCheck(createPayload);
    uiStateOrderId.value = response.data.order.order_id;
    updateLoader($gettext('Резервируем товар для проведения пересчета'), uiStateOrderId.value);
    await useSubscribeOnSuggests(uiStateOrderId.value)(s => {
      if (!s) return false;
      if (s.size >= 1) {
        return true;
      }
      return false;
    });
    closeLoader();
  } catch (error: any) {
    closeLoader();
    if (error?.isAxiosError && error?.response) {
      const { response } = error as AxiosError;
      if (response?.status === 400 && response.data.message === 'Product in kitchen assortment') {
        Alerts.error($gettext('Нельзя пересчитывать готовую еду'));
        throw new Error('Нельзя пересчитывать готовую еду');
      }
      if (response?.status === 400 && response?.data.code === 'NON_QUANTS_PRODUCT') {
        await Modal.show({
          title: $gettext('Нельзя пересчитать непорционный товар'),
          text: $gettext('Переместите данный товар с кухонной полки и выполните пересчет') + '\nNON_QUANTS_PRODUCT',
        });
        throw new Error('Не удалось создать задание на пересчет');
      }
      await Alerts.error($gettext('Не удалось создать задание на пересчет'));
      throw new Error('Не удалось создать задание на пересчет');
    }
    AudioService.playError();
    logger.error(error, { method: 'createCheckOrder' });
  }
};

const toHomePage = (): void => {
  router.push({ name: 'home' });
};

const openProductCard = async (product_id): Promise<void> => {
  await router.push({ name: 'product-card', params: { product_id } });
};
const openItemCard = async (item_id): Promise<void> => {
  await router.push({ name: 'item-card', params: { item_id } });
};
</script>
