import retryBox2ShelfModal from '@/fsd/entities/modals/retryBox2ShelfModal';
import retryShelf2BoxModal from '@/fsd/entities/modals/retryShelf2BoxModal';
import erSuggestInvalidTrueMarkModal from '@/fsd/entities/modals/true_mark/order/erSuggestInvalidTrueMarkModal';
import erSuggestTrueMarkAlreadyTakenModal from '@/fsd/entities/modals/true_mark/order/erSuggestTrueMarkAlreadyTakenModal';
import erSuggestTrueMarkConsumedModal from '@/fsd/entities/modals/true_mark/order/erSuggestTrueMarkConsumedModal';
import erSuggestTrueMarkDuplicatedModal from '@/fsd/entities/modals/true_mark/order/erSuggestTrueMarkDuplicatedModal';
import erSuggestTrueMarkInAnotherOrder from '@/fsd/entities/modals/true_mark/order/erSuggestTrueMarkInAnotherOrderModal';
import erSuggestTrueMarkNotInCurrentOrderModal from '@/fsd/entities/modals/true_mark/order/erSuggestTrueMarkNotInCurrentOrderModal';
import erSuggestTrueMarkRequiredModal from '@/fsd/entities/modals/true_mark/order/erSuggestTrueMarkRequiredModal';
import erSuggestWrongProductTrueMark from '@/fsd/entities/modals/true_mark/order/erSuggestWrongProductTrueMarkModal';
import erSuggestWrongTrueMarkModal from '@/fsd/entities/modals/true_mark/order/erSuggestWrongTrueMarkModal';
import { useBox2Shelf } from '@/fsd/entities/suggest/tools/useBox2Shelf';
import { prepareBarcodes, useShelf2Box } from '@/fsd/entities/suggest/tools/useShelf2Box';
import { Alerts } from '@/fsd/shared/tools/alertNotification';
import { Modal } from '@/fsd/shared/tools/modalNotification';
import { checkBarcodeBySuggest } from '@/fsd/widgets/order/RequiredCard/useCollectOrder';
import {
  finishAssembling,
  finishCheckTrueMark,
  finishDoneRequest,
  finishEventWaiting,
  finishRequestBarcode,
  sendRequestBarcodeTime,
  startAssembling,
  startRequestBarcode,
} from '@/fsd/widgets/order/rum/useMeasureAssemblingPosition';
import { useRequestBarcode } from '@/hooks/useRequestBarcode';
import Product from '@/models/Product';
import Suggest, { SuggestStatusEnum } from '@/models/Suggest';
import TrueMarkSuggestWrapper from '@/models/TrueMarkSuggestWrapper';
import { OrderTargetEnum } from '@/models/orders/BaseOrder';
import OrderOrder from '@/models/orders/OrderOrder';
import { AudioService } from '@/services/audio.service';
import itemQueue from '@/services/queue/item-queue';
import productQueue from '@/services/queue/product-queue';
import { useOrders } from '@/store/modules/orders';
import { useUser } from '@/store/modules/user';
import { experiments } from '@/temp/constants';
import { modalNotifyId } from '@/temp/constants/common';
import { ERR } from '@/temp/constants/errors';
import { $gettext } from '@/temp/plugins/gettext';
import { TrueMark } from '@/temp/services/true-mark';
import { useLoader } from '@/ui/common/loader/useLoader';
import { needLessWeightModal } from '@/utils/modals';
import { Collected } from '@/views/Order/types';
import { notify } from '@kyvg/vue3-notification';
import { computed, reactive, ref, watch } from 'vue';

export const useCollectOrder = (order_id: OrderOrder['order_id']) => {
  const { showLoader } = useLoader();
  const expTrueMarkMerged = useUser().experimentByName(experiments.exp_true_mark_merged);

  const order = computed(() => {
    return useOrders().orderById(order_id) as OrderOrder | undefined;
  });
  const suggests = computed(() => {
    if (!order.value) return [];
    return order.value.suggests;
  });

  const trueMarkQueue = ref<Record<string, boolean>>({});

  const trueMarkMerge = (suggests: Suggest[]): (Suggest | TrueMarkSuggestWrapper)[] => {
    const mergedSuggests: (Suggest | TrueMarkSuggestWrapper)[] = [];

    suggests.forEach(suggest => {
      if (suggest.conditions.need_true_mark) {
        const virtualId = suggest.product_id + suggest.shelf_id + suggest.type;
        const virtualSuggest = mergedSuggests.find(s => s.suggest_id === virtualId);
        if (virtualSuggest && TrueMarkSuggestWrapper.isTrueMarkSuggestWrapper(virtualSuggest)) {
          virtualSuggest.addSuggest = suggest;
        } else {
          mergedSuggests.push(new TrueMarkSuggestWrapper({ ...suggest, suggests: [suggest], suggest_id: virtualId }));
        }
      } else {
        mergedSuggests.push(suggest);
      }
    });

    return mergedSuggests;
  };

  const suggestsForCollect = computed(() => {
    const mergedSuggests = expTrueMarkMerged ? trueMarkMerge(suggests.value) : suggests.value;
    if (order.value?.target === 'canceled') {
      return mergedSuggests
        .filter(suggest => !suggest.isPackaging)
        .filter(s => s.status === 'request' || s.type === 'box2shelf');
    }
    return mergedSuggests.filter(suggest => !suggest.isPackaging);
  });

  const firstRequestSuggestIndex = computed(() => {
    const idx = suggestsForCollect.value.findIndex(s => {
      return s.status === 'request';
    });
    if (idx !== -1) return idx;
    return 0;
  });
  const activeSuggestIndex = ref(firstRequestSuggestIndex.value);
  const activeSuggest = computed(() => {
    return suggestsForCollect.value[activeSuggestIndex.value];
  });
  const hasProblemOnActiveSuggest = computed(() => {
    if (!activeSuggest.value || !order.value || order.value.target === OrderTargetEnum.canceled) return false;
    return order.value.problems.filter(p => p.product_id).find(p => p.product_id === activeSuggest.value.product_id);
  });

  const hasSuggestsToCollect = computed(() => {
    return suggestsForCollect.value.some(s => s.status === 'request');
  });

  const collected = reactive<Record<Suggest['suggest_id'], Collected>>(
    suggestsForCollect.value.reduce((acc, s) => {
      acc[s.suggest_id] = {
        count: 0,
      };
      return acc;
    }, {}),
  );

  const setTrueMarkQueue = (suggest: Suggest) => {
    if (suggest.conditions.need_true_mark && expTrueMarkMerged) {
      trueMarkQueue.value[suggest.suggest_id] = true;
    }
  };

  const clearTrueMarkQueue = (suggest: Suggest) => {
    if (suggest.conditions.need_true_mark && expTrueMarkMerged && trueMarkQueue.value[suggest.suggest_id]) {
      delete trueMarkQueue.value[suggest.suggest_id];
    }
  };

  const getSuggestById = (
    suggest_id: Suggest['suggest_id'] | TrueMarkSuggestWrapper['suggest_id'],
  ): Suggest | TrueMarkSuggestWrapper | undefined => {
    const suggest = suggestsForCollect.value.find(s => s.suggest_id === suggest_id);
    if (
      expTrueMarkMerged &&
      suggest &&
      TrueMarkSuggestWrapper.isTrueMarkSuggestWrapper(suggest) &&
      suggest.suggests.length
    ) {
      return suggest.suggests.find(s => s.status === SuggestStatusEnum.request && !trueMarkQueue.value[s.suggest_id]);
    }
    return suggest;
  };

  const clearCollected = (suggest: Suggest) => {
    if (!suggest) return;
    collected[suggest.suggest_id] = { count: 0 };
  };

  const selectNext = () => {
    //найти не выполненный саджест и перейти на него.
    //сначала ищем ближайший следующий. потом первый невыполненный
    let nextIndex = suggestsForCollect.value
      .slice(activeSuggestIndex.value + 1)
      .findIndex(s => s.status === 'request' || s.status === 'blocked');
    if (nextIndex !== -1) {
      nextIndex += activeSuggestIndex.value + 1;
    } else {
      nextIndex = suggestsForCollect.value.findIndex(s => s.status === 'request');
    }
    if (nextIndex === -1) return;
    activeSuggestIndex.value = nextIndex;
  };

  const updateCollected = (suggest: Suggest, barcode: string, next?: boolean, weight?: number) => {
    if (!suggest) return;
    if (!collected[suggest.suggest_id]) {
      collected[suggest.suggest_id] = {};
    }
    const activeCollected = collected[suggest.suggest_id];

    // Обновил кол-во
    if (!activeCollected.count) {
      activeCollected.count = 0;
    }
    activeCollected.count += 1;

    // Обновил баркоды
    if (!activeCollected.barcodes) {
      activeCollected.barcodes = [];
    }
    activeCollected.barcodes!.push(barcode);

    // Обновил вес
    if (weight) {
      if (!activeCollected.weight) {
        activeCollected.weight = 0;
      }
      activeCollected.weight += weight;
    }

    if (suggest.conditions.need_true_mark) {
      activeCollected.true_mark = barcode;
    }

    if (suggest.count === activeCollected.count) {
      return completeSuggest(suggest, next);
    }
    return true;
  };

  const updateCollectedTrueWeight = (suggest: Suggest, barcode: string) => {
    const weight = Product.weightFromBarcode(barcode);
    if (!collected[suggest.suggest_id]) {
      collected[suggest.suggest_id] = {};
    }
    const activeCollected = collected[suggest.suggest_id];

    // Обновил кол-во
    if (!activeCollected.count) {
      activeCollected.count = 0;
    }
    activeCollected.count += weight;

    // Обновил баркоды
    if (!activeCollected.barcodes) {
      activeCollected.barcodes = [];
    }
    activeCollected.barcodes.push(barcode);
  };

  const completeSuggest = async (suggest: Suggest, next = true): Promise<void> => {
    if (!suggest || !order.value) return;
    sendRequestBarcodeTime(suggest.suggest_id);
    const { weight, count, true_mark, barcodes } = collected[suggest.suggest_id];
    if (suggest.type === 'box2shelf') {
      const result = await useBox2Shelf(
        order_id,
        {
          suggest_id: suggest.suggest_id,
          weight,
          count,
          true_mark,
          barcodes: suggest.conditions.need_barcodes && barcodes ? prepareBarcodes(barcodes) : undefined,
        },
        {
          onRequestError: async (e, retry) => {
            const response = e.response;
            switch (response?.data?.code) {
              case ERR.ER_SUGGEST_TRUE_MARK_REQUIRED:
                erSuggestTrueMarkRequiredModal();
                clearCollected(suggest);
                break;
              case ERR.ER_SUGGEST_INVALID_TRUE_MARK:
                erSuggestInvalidTrueMarkModal();
                clearCollected(suggest);
                break;
              case ERR.ER_SUGGEST_TRUE_MARK_DUPLICATED:
                erSuggestTrueMarkDuplicatedModal();
                clearCollected(suggest);
                break;
              case ERR.ER_SUGGEST_TRUE_MARK_CONSUMED:
                erSuggestTrueMarkConsumedModal();
                clearCollected(suggest);
                break;
              case ERR.ER_SUGGEST_WRONG_PRODUCT_TRUE_MARK:
                erSuggestWrongProductTrueMark();
                clearCollected(suggest);
                break;
              case ERR.ER_SUGGEST_TRUE_MARK_IN_ANOTHER_ORDER:
                erSuggestTrueMarkInAnotherOrder();
                clearCollected(suggest);
                break;
              case ERR.ER_SUGGEST_TRUE_MARK_ALREADY_TAKEN:
                erSuggestTrueMarkAlreadyTakenModal();
                clearCollected(suggest);
                break;
              case ERR.ER_SUGGEST_WRONG_TRUE_MARK:
                erSuggestWrongTrueMarkModal(suggest.type);
                clearCollected(suggest);
                break;
              case ERR.ER_SUGGEST_TRUE_MARK_NOT_IN_CURRENT_ORDER:
                erSuggestTrueMarkNotInCurrentOrderModal();
                clearCollected(suggest);
                break;
              default: {
                const confirmed = await retryBox2ShelfModal(e);
                if (!confirmed) return false;
                return retry();
              }
            }
            return false;
          },
          measureRequest: finishDoneRequest,
          measureEvent: finishEventWaiting,
        },
      );
      clearTrueMarkQueue(suggest);
      if (!result) return;
    } else {
      const result = await useShelf2Box(
        order_id,
        {
          suggest_id: suggest.suggest_id,
          weight,
          count,
          true_mark,
          barcodes: suggest.conditions.need_barcodes && barcodes ? prepareBarcodes(barcodes) : undefined,
        },
        {
          onRequestError: async (e, retry) => {
            AudioService.playError();
            const response = e.response;
            switch (response?.data?.code) {
              case ERR.ER_SUGGEST_TRUE_MARK_REQUIRED:
                erSuggestTrueMarkRequiredModal();
                clearCollected(suggest);
                break;
              case ERR.ER_SUGGEST_INVALID_TRUE_MARK:
                erSuggestInvalidTrueMarkModal();
                clearCollected(suggest);
                break;
              case ERR.ER_SUGGEST_TRUE_MARK_DUPLICATED:
                erSuggestTrueMarkDuplicatedModal();
                clearCollected(suggest);
                break;
              case ERR.ER_SUGGEST_TRUE_MARK_CONSUMED:
                erSuggestTrueMarkConsumedModal();
                clearCollected(suggest);
                break;
              case ERR.ER_SUGGEST_WRONG_PRODUCT_TRUE_MARK:
                erSuggestWrongProductTrueMark();
                clearCollected(suggest);
                break;
              case ERR.ER_SUGGEST_TRUE_MARK_IN_ANOTHER_ORDER:
                erSuggestTrueMarkInAnotherOrder();
                clearCollected(suggest);
                break;
              case ERR.ER_SUGGEST_TRUE_MARK_ALREADY_TAKEN:
                erSuggestTrueMarkAlreadyTakenModal();
                clearCollected(suggest);
                break;
              case ERR.ER_SUGGEST_WRONG_TRUE_MARK:
                erSuggestWrongTrueMarkModal(suggest.type);
                clearCollected(suggest);
                break;
              default: {
                const confirmed = await retryShelf2BoxModal(e);
                if (!confirmed) return false;
                return retry();
              }
            }
            return false;
          },
          measureRequest: finishDoneRequest,
          measureEvent: finishEventWaiting,
        },
      );
      clearTrueMarkQueue(suggest);
      if (!result) return;
    }
    // все успешно. можем закрывать измерение по саджесту
    finishAssembling();
    clearTrueMarkQueue(suggest);
    if (next) selectNext();
  };

  const handleItem = async (suggest: Suggest, barcode: string): Promise<void> => {
    const item = suggest?.item || (await itemQueue.load(suggest.product_id));
    if (!item || !suggest) {
      // ОШИБКА!!!
      return;
    }
    if (item.checkBarcode(barcode)) {
      if (!collected[suggest.suggest_id]) {
        collected[suggest.suggest_id] = {};
      }
      collected[suggest.suggest_id].count = 1;
      collected[suggest.suggest_id].barcodes = [barcode];
      await completeSuggest(suggest);
      //  Проверить собранное и добавить или закрыть саджест и отменить запрос баркода
    } else {
      Alerts.error($gettext('Отсканирован неверный штрихкод %{barcode}', { barcode }));
    }
    return;
  };
  const handleProduct = async (suggest: Suggest, barcode: string, next?: boolean): Promise<void> => {
    const product = suggest?.product || (await productQueue.load(suggest.product_id));
    if (!product) {
      // ОШИБКА!!!
      return;
    }

    // ЧЗ
    if (suggest.conditions.need_true_mark) {
      if (suggest.type === 'shelf2box') {
        const { closeLoader } = showLoader();
        try {
          const response = await TrueMark.check({ true_mark: barcode, order_id });
          closeLoader();
          finishCheckTrueMark(response?.duration || 0);
        } catch {
          closeLoader();
          clearTrueMarkQueue(suggest);
          return;
        }
      }
      await updateCollected(suggest, barcode, next);
      return;
    }

    // Настоящий весовой продукт
    if (product.isTrueWeight) {
      const weight = Product.weightFromBarcode(barcode);
      if (!weight) {
        Alerts.error($gettext('Отсканирован неверный штрихкод %{barcode}', { barcode }));
        return;
      }
      if (!suggest.max_count) {
        if (weight !== suggest.count) {
          Alerts.error($gettext('Отсканирован неверный штрихкод %{barcode}', { barcode }));
          return;
        }
        updateCollectedTrueWeight(suggest, barcode);
        await completeSuggest(suggest, next);
        return;
      }
      const curCollectedCount = collected[suggest.suggest_id]?.count || 0;
      // Слишком много
      if (curCollectedCount + weight > suggest.max_count) {
        const delta = curCollectedCount + weight - suggest.max_count;
        await needLessWeightModal(delta.toString());
        return;
      }

      updateCollectedTrueWeight(suggest, barcode);
      // Слишкм мало
      if (curCollectedCount + weight < suggest.min_count!) {
        return;
      }
      // в самый раз
      await completeSuggest(suggest, next);
      return;
    }

    // весовой продукт
    if (product.type_accounting === 'weight') {
      const weight = Product.weightFromBarcode(barcode);
      updateCollected(suggest, barcode, next, weight);
      return;
    }

    // это простой штучный продукт
    await updateCollected(suggest, barcode, next);
    return;
  };
  const barcodeHandler = async (barcode: string) => {
    if (!order.value) return false;
    if (!activeSuggest.value) return false;
    if (hasProblemOnActiveSuggest.value) return true;
    if (!needBarcodeRequest.value) return true;
    if (activeSuggest.value.status === 'blocked') return true;
    if (order.value.isOrderPaused) {
      await Modal.show({
        title: $gettext('Обрабатывается оператором'),
        text: $gettext('Данный заказ обрабатывается оператором, пожалуйста подождите'),
      });
      return true;
    }
    notify.close(modalNotifyId);
    const suggest = getSuggestById(activeSuggest.value.suggest_id);
    if (!suggest) return;
    // посылка
    if (activeSuggest.value.vars?.mode === 'item') {
      await handleItem(suggest, barcode);
      return true;
    }
    // продукт
    startRequestBarcode(suggest.suggest_id);
    const valid = await checkBarcodeBySuggest(barcode, activeSuggest.value);
    finishRequestBarcode(suggest.suggest_id);
    if (!valid) return true;

    const needNext = TrueMarkSuggestWrapper.isTrueMarkSuggestWrapper(activeSuggest.value)
      ? activeSuggest.value.isLastRequestSuggest
      : true;

    setTrueMarkQueue(suggest);

    await handleProduct(suggest, barcode, needNext);
    return true;
  };

  const { needBarcodeRequest } = useRequestBarcode(barcodeHandler, { immediate: false });
  const stopBarcodeRequest = () => {
    needBarcodeRequest.value = false;
  };
  const startBarcodeRequest = () => {
    if (needBarcodeRequest.value === true) return;
    needBarcodeRequest.value = true;
  };

  // если текущий саджест пропал, то идем к ближайшему в статусе реквест
  watch(
    activeSuggest,
    val => {
      if (val) {
        if (val.status === 'request') {
          startAssembling();
        }
        return;
      }
      activeSuggestIndex.value = firstRequestSuggestIndex.value;
    },
    { immediate: true },
  );

  watch(
    hasSuggestsToCollect,
    val => {
      if (val) {
        startBarcodeRequest();
      } else {
        stopBarcodeRequest();
      }
    },
    { immediate: true },
  );
  watch(activeSuggestIndex, () => {
    if (hasSuggestsToCollect.value) {
      startBarcodeRequest();
    }
  });
  return {
    suggestsForCollect,
    activeSuggest,
    activeSuggestIndex,
    selectNext,
    stopBarcodeRequest,
    startBarcodeRequest,
    collected,
    clearActiveCollected: () => clearCollected(activeSuggest.value),
    completeActiveSuggest: () => completeSuggest(activeSuggest.value),
  };
};
