<template>
  <Layout>
    <template #header>
      <Bar :text="barText" :caption="caption" :menu-config="menuConfig" @close-click="toHomePage" />
    </template>
    <template #default>
      <div class="flex flex-col items-justify h-full max-h-screen">
        <DynamicScroller
          :items="sortedList"
          key-field="order_id"
          :min-item-size="150"
          class="h-full"
          list-class="h-full"
        >
          <template #default="{ item, index, active }">
            <DynamicScrollerItem :item="item" :active="active" :data-index="index">
              <Order :key="item.order_id" :order-id="item.order_id" class="py-1 px-4" />
            </DynamicScrollerItem>
          </template>
          <template #empty>
            <div class="pt-4 w-full flex justify-center">
              <Body1 v-if="sortedList.length === 0" class="font-bold">
                {{ $gettext('Заданий пока нет') }}
              </Body1>
            </div>
          </template>
        </DynamicScroller>
      </div>
    </template>
  </Layout>
</template>

<script lang="ts">
import { api } from '@/fsd/data/api/api.service';
import { Order } from '@/fsd/entities/order/ui/order-card/';
import { useAckOrder } from '@/fsd/features/order/utils/useAckOrder';
import { rumSpaManager } from '@/fsd/shared/tools/RumSpaManager';
import { ButtonPositionsEnum } from '@/fsd/shared/universalModal';
import { waitDataChange } from '@/fsd/shared/utils/subscribe';
import Item from '@/models/Item';
import BaseOrder, { default as OrderType } from '@/models/orders/BaseOrder';
import { useOrders } from '@/store/modules/orders';
import { useUser } from '@/store/modules/user';
import { createdDateSorter, statusSorter } from '@/store/utils/sorters';
import { experiments, permits } from '@/temp/constants';
import { getOrderTypesInMenu } from '@/temp/constants/translations';
import Bar from '@/ui/common/bar/bar.vue';
import Layout from '@/ui/common/layout.vue';
import { useLoader } from '@/ui/common/loader/useLoader';
import type { MenuItemConfig } from '@/ui/common/menu/types';
import Body1 from '@/ui/common/typo/body-1.vue';
import { needUpgradeRoleModal } from '@/utils/modals';
import uuid from 'uuid/v1';
import { computed, defineComponent } from 'vue';
import { useRoute, useRouter } from 'vue-router';

export default defineComponent({
  components: {
    Body1,
    Bar,
    Layout,
    Order,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  setup() {
    const { showLoader } = useLoader();
    const ordersStore = useOrders();
    const userStore = useUser();
    const router = useRouter();

    const route = useRoute();
    const pageName = route.name as string;
    rumSpaManager.finishDataLoading(pageName, true);
    rumSpaManager.startDataRendering(pageName);
    return { showLoader, ordersStore, userStore, router };
  },
  data() {
    return {
      uiState: {
        showAllAcceptance: false,
      },
    };
  },
  computed: {
    list(): OrderType[] {
      switch (this.type) {
        case 'order':
          return this.ordersStore.sortedOrderOrders;
        case 'control':
          return this.ordersStore.controlOrders;
        case 'acceptance':
          return this.ordersStore.acceptanceOrders(this.uiState.showAllAcceptance);
        case 'writeoff':
          return this.ordersStore.writeoffOrders;
        case 'check':
          return this.ordersStore.checkOrders;
        case 'check_more':
          return this.ordersStore.blindCheckOrders;
        case 'check_product_on_shelf':
          return this.ordersStore.checkProductOnShelfOrders;
        case 'stowage':
          return this.ordersStore.stowageOrders;
        case 'refund':
          return this.ordersStore.refundOrders;
        case 'shipment':
          return this.ordersStore.shipmentOrders;
        case 'hand_move':
          return this.ordersStore.handMoveOrders;
        default:
          return [];
      }
    },
    sortedList(): OrderType[] {
      if (this.type === 'order') {
        return [...this.list, ...this.ordersStore.failedOrders];
      } else if (this.type === 'stowage') {
        return [...this.list].sort((a: BaseOrder, b: BaseOrder) => {
          switch (true) {
            case Boolean(a.status === 'processing' && b.status !== 'processing'):
              return -1;
            case Boolean(a.status !== 'processing' && b.status === 'processing'):
              return 1;
            case Boolean(Item.isItemInRequired(a.required[0])):
              return -1;
            case Boolean(Item.isItemInRequired(b.required[0])):
              return 1;
            default:
              return createdDateSorter(a, b);
          }
        });
      }
      return [...this.list].sort((a, b) => {
        return statusSorter(a, b) || createdDateSorter(a, b);
      });
    },
    caption(): string {
      return getOrderTypesInMenu[this.type];
    },
    barText(): string {
      return this.$ngettext('%{count} задание', '%{count} заданий', this.list.length, {
        count: String(this.list.length),
      });
    },
    menuConfig(): MenuItemConfig[] {
      const menuConfig: MenuItemConfig[] = [];

      if (this.type === 'acceptance') {
        const showAllAcceptanceBtn: MenuItemConfig = {
          buttonText: this.$gettext('Показать все приемки'),
          onClick: () => this.showAllAcceptance(),
          dataTest: 'list show-all-acceptance btn',
        };
        menuConfig.push(showAllAcceptanceBtn);
      }

      // пока маркет-экспресс не поддерживает приемку по qr - оставляем старый флоу
      if (this.type === 'acceptance' && !this.userStore.experimentByName(experiments.exp_waste)) {
        const createAcceptanceBtn: MenuItemConfig = {
          buttonText: this.$gettext('Создать приемку'),
          onClick: async () => {
            if (this.userStore.permitByName(permits.tsd_acceptance)) {
              this.router.push({ name: 'create-acceptance' });
            } else {
              const result = await needUpgradeRoleModal();
              if (result) {
                this.router.push({ name: 'settings-upgrade-role', params: { upgrade: 'true' } });
              }
            }
          },
          dataTest: 'create-acceptance',
        };
        menuConfig.push(createAcceptanceBtn);
      }

      if (this.type === 'acceptance' && this.userStore.experimentByName(experiments.exp_waste)) {
        const createMarketAcceptanceBtn: MenuItemConfig = {
          buttonText: this.$gettext('Создать приемку Маркета'),
          onClick: async () => {
            if (this.userStore.permitByName(permits.tsd_acceptance)) {
              this.router.push({ name: 'create-market-acceptance' });
            } else {
              const result = await needUpgradeRoleModal();
              if (result) {
                this.router.push({ name: 'settings-upgrade-role', params: { upgrade: 'true' } });
              }
            }
          },
          dataTest: 'create-market-acceptance',
        };
        menuConfig.push(createMarketAcceptanceBtn);
      }

      if (this.type === 'shipment' && this.userStore.permitByName(permits.tsd_shipment_items)) {
        const createShipmentBtn: MenuItemConfig = {
          buttonText: this.$gettext('Создать отгрузку'),
          onClick: () => this.createShipment(),
          dataTest: 'create-shipment',
        };
        menuConfig.push(createShipmentBtn);
      }

      if (this.type === 'shipment' && this.userStore.experimentByName(experiments.exp_waste)) {
        const createMarketShipmentBtn: MenuItemConfig = {
          buttonText: this.$gettext('Создать отгрузку по QR'),
          onClick: () => {
            this.router.push({ name: 'create-market-shipment' });
          },
          dataTest: 'create-market-shipment',
        };
        menuConfig.push(createMarketShipmentBtn);
      }

      return menuConfig;
    },
  },
  methods: {
    showAllAcceptance() {
      this.uiState.showAllAcceptance = true;
    },
    toHomePage(): Promise<any> {
      return this.router.push({ name: 'home' });
    },
    async createShipment(): Promise<void> {
      const confirmed = await this.$modal.show({
        title: this.$gettext('Вы уверены, что хотите создать отгрузку?'),
        btnPosition: ButtonPositionsEnum.horizontal,
      });
      if (!confirmed) return;
      const { closeLoader, updateLoader } = this.showLoader(this.$gettext('Создаём задание'));
      const external_id = uuid() as string;
      const order = computed(() => {
        return useOrders().orderByExternalId(external_id);
      });
      try {
        await api.order.shipment_items({ external_id });
        updateLoader(this.$gettext('Ожидаем создания задания'));
        await waitDataChange(order, o => {
          return Boolean(o);
        });
      } catch (e: any) {
        if (e?.response?.status === 410) {
          this.$alert.error(this.$gettext('Просроченные посылки отсутствуют'));
        } else {
          this.$alert.error(this.$gettext('Произошла ошибка при создании отгрузки'));
        }
      } finally {
        closeLoader();
      }
      const result = await useAckOrder(order.value?.order_id);
      if (result) {
        this.router.push({ name: 'shipment', params: { order_id: order.value?.order_id } });
      }
    },
  },
});
</script>
