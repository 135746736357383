import { defineStore } from 'pinia';

/**
 * Стор управления отображением модальных окон
 */
export const useModals = defineStore('modals', {
  state: () => ({
    /**
     * Стек обработчиков закрытия модальных окон, открытых на данный момент
     */
    modalCloseHandlers: [] as Function[],
  }),
  getters: {
    /**
     * True, если есть текущие открытые модальные окна
     * @param state
     */
    hasOpenModals(state) {
      return Boolean(state.modalCloseHandlers.length);
    },
  },
  actions: {
    /**
     * Закрывает последнее открытое модальное окно
     */
    closeLastModal() {
      const handler = this.modalCloseHandlers.pop();

      if (handler != null) {
        handler();
      }
    },
    /**
     * Добавляет обработчик закрытия модального окна в стек открытых модалок
     * @param handler - обработчик закрытия модального окна
     */
    addModalCloseHandler(handler) {
      this.modalCloseHandlers.push(handler);
    },
    /**
     * Удаляет обработчик закрытия модального окна из стека открытых модалок
     * @param handlerToRemove  - обработчик закрытия модального окна
     */
    removeModalCloseHandler(handlerToRemove) {
      this.modalCloseHandlers = this.modalCloseHandlers.filter(handler => handler !== handlerToRemove);
    },
  },
});
