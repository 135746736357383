<template>
  <svg height="100%" class="inline -mr-0.5" viewBox="0 0 14 40" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.6 0H14C14 18.5887 14 23.2827 14 40H5.6V37.9912C5.6 34.1287 3.51688 30.6423 1.75546 27.3985C0.77876 25.5998 -1.97999e-08 23.1713 0 20C1.97998e-08 16.8287 0.77876 14.4001 1.75546 12.6015C3.51687 9.35772 5.6 5.87132 5.6 2.0088V0Z"
      :fill="colorValue"
    />
  </svg>
</template>

<script setup lang="ts">
import { useColor } from '@/fsd/shared/hooks';

const { color } = defineProps<{ color?: string }>();
const { colorValue } = useColor({ color: () => color });
</script>
