import { Modal } from '@/fsd/shared/tools/modalNotification';
import { useUser } from '@/store/modules/user';
import { modalNotifyId } from '@/temp/constants/common';
import { $gettext } from '@/temp/plugins/gettext';
import { logger } from '@/temp/plugins/logs';
import { notify } from '@kyvg/vue3-notification';
import dayjs from 'dayjs';
import { storeToRefs } from 'pinia';
import { computed, watch } from 'vue';

const showModal = () => {
  notify.close(modalNotifyId);
  Modal.show({
    title: $gettext('Измените время в настройках устройства'),
    text: $gettext(
      'Из-за неправильного времени ТСД может работать некорректно. Если вам нужна помощь с настройками, обратитесь в поддержку',
    ),
    confirmBtnTitle: $gettext('Хорошо'),
    confirmTimeout: 5,
  });
};
export const checkClientTime = () => {
  const userStore = useUser();
  const { options } = storeToRefs(userStore);
  const now = computed(() => {
    return options.value?.now;
  });
  const unWatch = watch(now, serverTime => {
    if (!serverTime) return;
    const serverDate = dayjs(serverTime, 'YYYY-MM-DDTHH:mm:ssZZ');
    if (!serverDate) return;

    const stime = serverDate.unix();
    const now = (Date.now() / 1_000) >> 0;

    // если разница во времени между клиентом и сервером > 10 минут - показываем модалку
    if (Math.abs(now - stime) > 600) {
      showModal();
      logger.error('time-diverges', {
        method: 'checkClientTime',
        source: 'checkClientTime.ts',
        additional: {
          serverDate: serverDate,
          clientDate: dayjs().format('YYYY-MM-DDTHH:mm:ssZZ'),
          difference: Math.abs(now - stime),
        },
      });
    }
    unWatch();
  });
};
