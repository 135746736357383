import IconBlackUltima from '@/fsd/shared/icons/icon-black-ultima.vue';
import IconWarning from '@/fsd/shared/icons/warning/icon-warning.vue';
import { OrangeIconWarning } from '@/fsd/shared/icons/warning/OrangeIconWarning';
import { Modal } from '@/fsd/shared/tools/modalNotification';
import { ButtonPositionsEnum } from '@/fsd/shared/universalModal';
import IconArrowDownWeight from '@/temp/icons/icon-arrow-down-weight.vue';
import { $gettext } from '@/temp/plugins/gettext';
import { Notifications } from '@/temp/plugins/notification';
import IconFragileForModal from '@/utils/img/IconFragileForModal.vue';
import IconScalesForModal from '@/utils/img/IconScalesForModal.vue';
import { markRaw } from 'vue';

// ф-я открывает окошко для ввода пинкода

interface Options {
  onReturn?: () => void;
  text?: string;
}

export const needUpgradeRoleModal = async ({
  text = $gettext('Поднимите роль чтобы выполнить задание'),
}: Options = {}) => {
  return await Modal.show({
    title: $gettext('Недостаточно прав'),
    text,
    btnPosition: ButtonPositionsEnum.horizontal,
    confirmBtnTitle: $gettext('Поднять роль'),
    closeBtnTitle: $gettext('Вернуться'),
  });
};

export const erSuggestAcceptedCountModal = async () => {
  await Modal.show({
    title: $gettext('Введенное количество превышает допустимое'),
  });
};

export const erSuggestNonQuantsProduct = async () => {
  await Modal.show({
    title: $gettext('Нельзя пересчитать непорционный товар'),
    text:
      $gettext('Переместите данный товар с кухонной полки и выполните пересчет') + '\nER_SUGGEST_NON_QUANTS_PRODUCT',
  });
};

export const fragileModal = async () => {
  await Modal.show({
    title: $gettext('Хрупкие товары'),
    text:
      $gettext('Проверьте, чтобы у всех упаковок товаров были соблюдены следующие пункты:') +
      '\n · ' +
      $gettext('Целая упаковка') +
      '\n · ' +
      $gettext('Нет вмятин и трещин ') +
      '\n · ' +
      $gettext('Товар не вскрыт'),
    confirmBtnTitle: $gettext('Продолжить'),
    component: markRaw(IconFragileForModal),
  });
};

export const trueMarkModal = async () => {
  await Modal.show({
    title: $gettext('Отсканируйте Честный знак'),
    text: $gettext('Это небольшой QR-код на упаковке'),
    confirmBtnTitle: $gettext('Хорошо'),
    component: markRaw(OrangeIconWarning),
  });
};

export const weightModal = async () => {
  await Modal.show({
    title: $gettext('Весовой товар'),
    text: $gettext(
      'Взвесьте указанное количество товара на весах. Нажмите кнопку, которая соответствует товару, чтобы распечатать чек. Отсканируйте штрихкод указанный на чеке.',
    ),
    component: markRaw(IconScalesForModal),
  });
};

export const needLessWeightModal = async (delta: string) => {
  await Modal.show({
    title: $gettext('Уменьшите вес товара на %{count} гр', { count: delta }),
    text: $gettext(
      'Вы взвесили слишком много, верните часть товара на полку, взвесьте заново и отсканируйте новый чек.',
    ),
    confirmBtnTitle: $gettext('Хорошо'),
    component: markRaw(IconArrowDownWeight),
  });
};

export const ultimaModal = async () => {
  await Notifications.universalModal({
    title: $gettext('Посылка Ultima'),
    text: $gettext('Её нужно аккуратно вскрыть — товары внутри будут в специальной упаковке Ultima'),
    buttons: [
      {
        title: $gettext('Закрыть'),
        color: 'secondary',
        onClick: () => undefined,
      },
    ],
    position: 'bottom',
    iconTop: {
      icon: markRaw(IconBlackUltima),
      position: 'left',
    },
  });
};

export const overweightModal = async () => {
  await Notifications.universalModal({
    title: $gettext('При сборке этого товара не должно быть перевеса'),
    text: $gettext('Иначе не получится завершить сборку заказа'),
    buttons: [
      {
        title: $gettext('Закрыть'),
        color: 'secondary',
        onClick: () => undefined,
      },
    ],
    position: 'bottom',
    iconTop: {
      icon: markRaw(IconWarning),
      position: 'left',
      props: {
        bgColor: 'red-normal',
      },
    },
  });
};
