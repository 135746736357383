import { NotificationsUniversalModalProps } from '@/fsd/shared/universalModal';
import { modalNotifyId } from '@/temp/constants/common';
import Vue3Notification, { notify } from '@kyvg/vue3-notification';

export const Modal = {
  show(options: NotificationsUniversalModalProps) {
    return new Promise(resolve => {
      notify({
        group: 'new-universal-modal',
        id: modalNotifyId,
        duration: -1,
        data: {
          ...options,
          resolve,
        },
      });
    });
  },
};

export const modal = {
  install(app) {
    app.use(Vue3Notification);
    app.config.globalProperties.$modal = Modal;
  },
};

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $modal: IModal;
  }
}

export interface IModal {
  show: (config: NotificationsUniversalModalProps) => Promise<void | boolean>;
}
