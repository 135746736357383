<template>
  <span style="font-size: 24px; line-height: 26px" :class="colorClass">
    <slot />
  </span>
</template>

<script setup lang="ts">
import { useColor } from '@/fsd/shared/hooks';

const { color } = defineProps<{ color?: string }>();
const { colorClass } = useColor({ color: () => color });
</script>
