<template>
  <Notifications class="z-[999]" width="100%" group="action">
    <template #body="props">
      <component
        :is="props.item.data.component"
        v-bind="props.item.data.props"
        @close="props.close"
        @btn-click="result => executeAndClose(() => props.item.data.resolve(result), props.close)"
      />
    </template>
  </Notifications>
</template>

<script setup lang="ts">
const executeAndClose = (cb, close) => {
  cb();
  close();
};
</script>
