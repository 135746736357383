<template>
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="40" height="40" rx="20" fill="black" fill-opacity="0.08" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20.0048 30C14.5239 30 10 25.4739 10 20C10 14.5261 14.5336 10 20.0145 10C25.4857 10 30 14.5261 30 20C30 25.4739 25.4761 30 20.0048 30ZM20.0145 21.5764C21.9091 21.5667 23.3784 19.9807 23.3784 17.882C23.3978 15.9091 21.8995 14.265 20.0145 14.265C18.1295 14.265 16.6506 15.9091 16.6506 17.882C16.6506 19.9807 18.1199 21.5957 20.0145 21.5764ZM20.0145 28.3462C22.1798 28.3462 24.4804 27.4468 25.9401 25.8801C24.8284 24.2166 22.6245 23.2495 20.0145 23.2495C17.4335 23.2495 15.2102 24.1973 14.0889 25.8801C15.5486 27.4468 17.8492 28.3462 20.0145 28.3462Z"
      fill="#21201F"
    />
  </svg>
</template>
<script lang="ts" />
