import { CheckOptions } from '@/fsd/data/utils/check';
import checkMore, { CheckMoreOptions } from '@/fsd/data/utils/checkMore';
import retryCheckMoreModal from '@/fsd/entities/modals/retryCheckMoreModal';
import { Modal } from '@/fsd/shared/tools/modalNotification';
import { OrderDoneCheckMoreRequest } from '@/services/requests';
import { $gettext } from '@/temp/plugins/gettext';
import { useLoader } from '@/ui/common/loader/useLoader';
import { merge } from 'lodash';

export const useCheckMore = async (
  order_id: string,
  payload: OrderDoneCheckMoreRequest,
  options: CheckOptions = {},
) => {
  const { showLoader } = useLoader();

  let loader;

  const defaultOptions: CheckMoreOptions = {
    onValidateError: e =>
      Modal.show({
        title: $gettext('Не удалось отправить информацию о товаре на сервер'),
        text: e,
      }),
    beforeRequest: () => (loader = showLoader($gettext('Отправляем информацию о товаре на сервер'))),
    afterRequest: () => loader?.updateLoader($gettext('Ожидаем ответ сервера')),
    onRequestError: async (e, retry) => {
      const confirmed = await retryCheckMoreModal(e);
      if (!confirmed) return false;
      return retry();
    },
    onEventWaitingError: e => {
      Modal.show({
        title: $gettext('Не удалось отправить информацию о товаре на сервер'),
        text: e,
      });
    },
    closeLoader: () => loader?.closeLoader(),
  };
  return checkMore(order_id, payload, merge({}, defaultOptions, options));
};
