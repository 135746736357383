<template>
  <ActionWrapper>
    <div ref="container" class="flex flex-col">
      <Title4 class="font-bold mb-4 text-left" color="primary">
        {{
          $gettext('Отсканируйте %{title}, которые хотите убрать из сборки', {
            title: suggest.product.title,
          })
        }}
      </Title4>
      <div
        data-test="order-retail remove-products-num"
        class="flex flex-row justify-between mb-4 px-4 py-4 bg-element rounded-2xl"
      >
        <Body2>{{ $gettext('Убрать из сборки') }}</Body2>
        <Body2 class="font-bold">{{
          $gettext('%{count} из %{all} шт.', { count: String(removeNum), all: String(maxNum) })
        }}</Body2>
      </div>
      <div class="flex justify-center">
        <IconBarcode />
      </div>
      <div class="flex mt-6 gap-2">
        <UiButton
          data-test="order-retail remove-products-btn-cancel"
          background-color="secondary"
          @click="$emit('btnClick')"
        >
          {{ $gettext('Отменить') }}
        </UiButton>
        <UiButton
          data-test="order-retail remove-products-btn"
          :is-disabled="!removeNum"
          @click="$emit('btnClick', { removeNum, barcode: productBarcode })"
        >
          {{ $gettext('Подтвердить') }}
        </UiButton>
      </div>
    </div>
  </ActionWrapper>
</template>

<script setup lang="ts">
import { Alerts } from '@/fsd/shared/tools/alertNotification';
import { useRequestBarcode } from '@/hooks/useRequestBarcode';
import Suggest from '@/models/Suggest';
import { useProducts } from '@/store/modules/products';
import IconBarcode from '@/temp/icons/icon-barcode.vue';
import { $gettext } from '@/temp/plugins/gettext';
import ActionWrapper from '@/ui/common/notifications/actionWrapper.vue';
import Body2 from '@/ui/common/typo/body-2.vue';
import Title4 from '@/ui/common/typo/title-4.vue';
import UiButton from '@/ui/common/ui-button.vue';
import { defineEmits, defineProps, ref } from 'vue';

const productsStore = useProducts();

const removeNum = ref<number>(0);
const productBarcode = ref<string>('');

interface RemoveProductsProps {
  suggest: Suggest;
  maxNum: number;
}

const props = defineProps<RemoveProductsProps>();

defineEmits<{ (e: 'btnClick', data?: { removeNum: number; barcode: string }): void }>();

useRequestBarcode(async barcode => {
  try {
    const product = await productsStore.getProductByBarcode(barcode);
    if (props.suggest.product_id === product.product_id) {
      if (props.maxNum > removeNum.value) {
        removeNum.value++;
        productBarcode.value = barcode;
      }
    } else {
      Alerts.error($gettext('Отсканирован другой товар'));
    }
    return true;
  } catch (error) {
    Alerts.error($gettext('Отсканирован неверный баркод'));
    return true;
  }
});
</script>
