import { api } from '@/fsd/data/api/api.service';
import { BrandingEnum } from '@/models/Constants';
import { computed, ref } from 'vue';

const branding = ref<BrandingEnum>(BrandingEnum.yandex);

export const useBranding = () => {
  const loadBranding = async () => {
    try {
      const { data } = await api.user.options();
      const respBranding = data?.constants?.domain_configs?.branding;
      setBranding(respBranding);
    } catch (e) {
      console.error(e);
    }
  };

  const setBranding = respBranding => {
    if (respBranding && respBranding in BrandingEnum) {
      branding.value = respBranding;
      localStorage.setItem('branding', respBranding);
    }
  };

  const getBranding = async () => {
    const local = localStorage.getItem('branding');
    if (local && local in BrandingEnum) {
      branding.value = local as BrandingEnum;
    } else {
      await loadBranding();
    }
  };

  const isYandex = computed(() => {
    return branding.value === BrandingEnum.yandex;
  });
  const isB2B = computed(() => {
    return branding.value !== BrandingEnum.yandex;
  });

  return {
    branding,
    isYandex,
    isB2B,
    setBranding,
    getBranding,
  };
};
