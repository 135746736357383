import check, { CheckOptions } from '@/fsd/data/utils/check';
import { Shelf2BoxOptions } from '@/fsd/data/utils/shelf2box';
import retryCheckModal from '@/fsd/entities/modals/retryCheckModal';
import { Modal } from '@/fsd/shared/tools/modalNotification';
import { OrderDoneCheckRequest, ReasonsEnum } from '@/services/requests';
import { useSuggests } from '@/store/modules/suggests';
import { $gettext } from '@/temp/plugins/gettext';
import { useLoader } from '@/ui/common/loader/useLoader';
import { merge } from 'lodash';

export const useCheck = async (order_id: string, payload: OrderDoneCheckRequest, options: CheckOptions = {}) => {
  const { showLoader } = useLoader();

  let loader;

  const { suggest_id } = payload;

  const suggest = useSuggests().getSuggest(order_id, suggest_id)!;

  if (suggest.status === 'done') {
    if (suggest.isEditable && !payload.reason) {
      switch (true) {
        case Boolean(suggest.conditions.need_weight):
          payload.reason = { code: ReasonsEnum.CHANGE_WEIGHT, count: payload.count! };
          break;
        case Boolean(payload.valid && payload.count !== undefined):
          payload.reason = { code: ReasonsEnum.CHANGE_COUNT_VALID, count: payload.count! };
          break;
        case Boolean(payload.count !== undefined):
          payload.reason = { code: ReasonsEnum.CHANGE_COUNT, count: payload.count! };
          break;
      }
    }
  }

  const defaultOptions: Shelf2BoxOptions = {
    onValidateError: e =>
      Modal.show({
        title: $gettext('Не удалось отправить информацию о товаре на сервер'),
        text: e,
      }),
    beforeRequest: () => (loader = showLoader($gettext('Отправляем информацию о товаре на сервер'))),
    afterRequest: () => loader?.updateLoader($gettext('Ожидаем ответ сервера')),
    onRequestError: async (e, retry) => {
      const confirmed = await retryCheckModal(e);
      if (!confirmed) return false;
      return retry();
    },
    beforeEventWaiting: () => {},
    afterEventWaiting: () => {},
    onEventWaitingError: e => {
      Modal.show({
        title: $gettext('Не удалось отправить информацию о товаре на сервер'),
        text: e,
      });
    },
    closeLoader: () => loader?.closeLoader(),
  };
  return check(order_id, payload, merge({}, defaultOptions, options));
};
