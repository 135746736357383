<template>
  <SuggestDetailsLayout data-test="suggest-details container" :suggest_id="suggestId" :order_id="orderId">
    <template #default>
      <Bar
        :order="order"
        :caption="caption"
        :menu-config="suggestMenuConfig"
        :menu-title="title"
        :icons="{ left: 'back', right: 'menu' }"
        :is-fixed="needPicture"
        data-test="suggest bar"
        @close-click="$emit('cancel')"
      />
      <div class="p-4 relative border-t-2 border-element">
        <ProductPicture v-if="needPicture" :img-src="suggest.imageSrc" />
        <div v-if="needInfo" class="info">
          <Typography
            data-test="suggest-details-title"
            class="suggest-details-title"
            color="primary-text"
            type="h3"
            margin="12px 0"
            align="center"
          >
            {{ title }}
          </Typography>
          <div
            v-if="['acceptance', 'sale_stowage'].includes(order.type) && product?.fragile"
            class="mx-auto mb-3 w-fit"
          >
            <FragileBadge />
          </div>
          <div
            v-if="['acceptance', 'sale_stowage'].includes(order.type) && product?.true_mark"
            class="mx-auto mb-3 w-fit"
          >
            <TrueMarkBadge />
          </div>
          <div
            v-if="['acceptance', 'sale_stowage'].includes(order.type) && product?.isTrueWeight"
            class="mx-auto mb-3 w-fit"
          >
            <WeightBadge :plu_code="product.plu_code" />
          </div>
          <Typography type="sub-title-italic" align="center" class="mb-3">
            · {{ productStorageTypeTitle }} ·
          </Typography>
          <Rows
            :order="order"
            :product="product"
            :suggest="suggest"
            :model="models"
            :reasons="actualReasons"
            :step="activeStep.name"
            :mode="uiState.validType"
          />
          <PackageClassSelector
            v-if="activeStep.name === 'select-package-class' && (models.shelf || suggest.shelf)?.type === 'parcel'"
            :package_class="packageClass"
            @change="changePackageClass"
          />
        </div>
        <RadioGroup v-if="activeStep.name === 'reason'" v-model="models.reason" class="py-4" :items="actualReasons" />
        <template v-if="activeStep.name === 'multi-product-counter'">
          <MultiProductCounter
            :max-count="suggest.count"
            :type-accounting="product.type_accounting"
            :counter-configs="multiProductCounterConfig"
            :title="$gettext('Всего')"
            @change="saveCounts"
          />
        </template>
      </div>
    </template>
    <template #footer>
      <div class="mt-auto bg-main w-full" :data-test="`step ${activeStep.name}`">
        <CountField
          v-if="activeStep.name === 'count'"
          :active-step="activeStep.name"
          :suggest="suggest"
          :order="order"
          :product-id="product && product.product_id"
          :is-counter-next="stepManager.hasNextStep(models)"
          :mode="uiState.validType"
          :initial-value="models.count"
          @confirm-input="endCountStep"
        />
        <WeightField
          v-if="activeStep.name === 'weight'"
          :active-step="activeStep.name"
          :suggest="suggest"
          :order="order"
          :weights="models.weights"
          :product-id="productId"
          :has-next-step="stepManager.hasNextStep(models)"
          @confirm-input="endWeightStep"
          @scan-weight-product="onScanWeightProduct"
        />
        <DateField
          :active-step="activeStep.name"
          :suggest="suggest"
          :order="order"
          :product-id="productId"
          :mode="uiState.validType"
          :initial-value="initialDateValue"
          @confirm-date="endDateStep"
        />
        <ShelfField
          v-if="activeStep.name === 'shelf'"
          :active-step="activeStep.name"
          :suggest="suggest"
          :order="order"
          :product="product"
          :item="item"
          @confirm-shelf="endShelfStep"
        />
        <div v-if="activeStep.name === 'reason'" class="p-4">
          <Hint class="mb-2">{{ reasonHint }}</Hint>
          <UiButton
            :is-disabled="!models.reason"
            data-test="suggest-details reason-select finish-btn"
            @click="selectReason"
          >
            {{ order.type === 'stowage_market' ? $gettext('Завершить') : $gettext('Далее') }}
          </UiButton>
        </div>
        <div v-if="activeStep.name === 'select-package-class'" class="p-4">
          <Hint v-if="!suggest.result_count || suggest.shelf?.type === 'parcel_returned'" class="mb-2">
            {{ hintText }}
          </Hint>
          <UiButton
            :is-disabled="!packageClass"
            data-test="suggest-details select-package-class btn"
            @click="onFinishBtnClick"
          >
            {{
              suggest.result_count && suggest.shelf?.type !== 'parcel_returned'
                ? $gettext('Сохранить')
                : $gettext('Завершить')
            }}
          </UiButton>
        </div>
        <div v-if="activeStep.name === 'finish'" class="p-4">
          <Hint class="mb-2">{{ hintText }}</Hint>
          <UiButton data-test="suggest-details finish btn" @click="onFinishBtnClick">
            {{ $gettext('Завершить') }}
          </UiButton>
        </div>
        <div v-if="activeStep.name === 'edit-confirm'" class="p-4">
          <Hint class="mb-2">{{ $gettext('Товар уже проверен, для изменения нажмите кнопку Изменить') }}</Hint>
          <UiButton data-test="suggest-details change-control btn" @click="cancelSuggest">
            {{ $gettext('Изменить') }}
          </UiButton>
        </div>
        <div v-if="activeStep.name === 'multi-product-counter'" class="p-4">
          <UiButton data-test="suggest-details visual_control finish-btn" @click="onFinishBtnClick">
            {{ $gettext('Все верно') }}
          </UiButton>
        </div>
      </div>
    </template>
  </SuggestDetailsLayout>
</template>

<script lang="ts">
import { callAction } from '@/fsd/shared/ui/action';
import { ActionReportProps } from '@/fsd/shared/ui/action/types';
import { ButtonPositionsEnum } from '@/fsd/shared/universalModal';
import SuggestDetailsLayout from '@/fsd/widgets/suggest-details/SuggestDetailsLayout.vue';
import Product, { Defect, TypeAccountingEnum } from '@/models/Product';
import Shelf from '@/models/Shelf';
import Suggest, { SuggestTypeEnum } from '@/models/Suggest';
import BaseOrder from '@/models/orders/BaseOrder';
import { Step, StepManager } from '@/models/suggest/steps';
import { useItems } from '@/store/modules/items';
import { useOrders } from '@/store/modules/orders';
import { useProducts } from '@/store/modules/products';
import { useShelves } from '@/store/modules/shelves';
import { useUser } from '@/store/modules/user';
import { experiments } from '@/temp/constants';
import { storageTypesInfo } from '@/temp/constants/translations';
import RadioGroup from '@/temp/ui/inputs/radio-group/radio-group.vue';
import { AvailableProduct } from '@/types/product';
import Bar from '@/ui/common/bar/bar.vue';
import Hint from '@/ui/common/hint/hint.vue';
import type { MenuItemConfig } from '@/ui/common/menu/types';
import MultiProductCounter, { CounterConfig } from '@/ui/common/multi-product-counter/multi-product-counter.vue';
import Report from '@/ui/common/notifications/report.vue';
import PackageClassSelector from '@/ui/common/package-class-selector/package-class-selector.vue';
import ProductPicture from '@/ui/common/suggest-details/components/product-picture.vue';
import Rows from '@/ui/common/suggest-details/data-rows/rows.vue';
import CountField from '@/ui/common/suggest-details/fields/count-field.vue';
import DateField from '@/ui/common/suggest-details/fields/date-field.vue';
import ShelfField from '@/ui/common/suggest-details/fields/shelf-field.vue';
import WeightField from '@/ui/common/suggest-details/fields/weight-field.vue';
import { Data } from '@/ui/common/suggest-details/types';
import { suggestWriteoffResaons } from '@/ui/common/suggest-details/writeoff-reasons';
import Typography from '@/ui/common/typography.vue';
import UiButton from '@/ui/common/ui-button.vue';
import FragileBadge from '@/ui/order/badges/fragile-badge.vue';
import TrueMarkBadge from '@/ui/order/badges/true-mark-badge.vue';
import WeightBadge from '@/ui/order/badges/weight-badge.vue';
import { checkConditions } from '@/utils/checkConditions';
import { PropType, defineComponent, reactive } from 'vue';

export default defineComponent({
  name: 'SuggestDetails',
  components: {
    SuggestDetailsLayout,
    TrueMarkBadge,
    WeightBadge,
    FragileBadge,
    RadioGroup,
    Typography,
    ProductPicture,
    Hint,
    UiButton,
    Rows,
    CountField,
    WeightField,
    DateField,
    ShelfField,
    MultiProductCounter,
    PackageClassSelector,
    Bar,
  },
  props: {
    orderId: {
      type: String,
      required: true,
    },
    suggestId: {
      type: String,
      required: true,
    },
    suggestProp: {
      type: Object as PropType<Suggest>,
      default: undefined,
    },
    productId: {
      type: String,
      default: undefined,
    },
    barcode: {
      type: String,
      default: undefined,
    },
  },
  emits: ['cancel', 'finish', 'cancel-suggest'],
  setup(props) {
    const stepManager = reactive(new StepManager(props.orderId, props.suggestId, props.suggestProp));
    const itemsStore = useItems();
    const shelvesStore = useShelves();
    const productsStore = useProducts();
    const ordersStore = useOrders();
    const userStore = useUser();

    return { stepManager, itemsStore, shelvesStore, productsStore, userStore, ordersStore };
  },
  data(): Data {
    return {
      uiState: {
        validType: useUser().experimentByName(experiments.exp_manhattan) ? 'productionDate' : 'expirationDate',
      },
      models: {
        count: 0,
        counts: [],
        weight: 0,
        weights: [],
        date: undefined,
        shelf: undefined,
        reason: undefined,
      },
      activeStepNumber: 0,
      reasons: suggestWriteoffResaons,
      packageClass: undefined,
    };
  },
  computed: {
    title(): string {
      if (this.suggest.vars && this.suggest.vars.mode === 'item') {
        return this.item?.title || '';
      } else {
        return this.product.title;
      }
    },
    // если ордера или саджеста нет, то мы перестаем отрисовывать компонент и закрываем его. поэтому считаем, что эти данные всегда есть
    order(): BaseOrder {
      return this.ordersStore.orderById(this.orderId)!;
    },
    suggest(): Suggest {
      return this.suggestProp || this.order?.suggestById(this.suggestId)!;
    },
    shelf(): Shelf {
      return this.shelvesStore.shelfById(this.suggest.shelf_id)!;
    },
    product(): Product {
      if (this.productId) {
        return this.productsStore.productById(this.productId)!;
      }
      return this.productsStore.productById(this.suggest.product_id)!;
    },
    item() {
      return this.itemsStore.itemById(this.suggest.product_id);
    },
    confirmTitle(): string {
      if (['hand_move', 'kitchen_provision', 'robot_provision'].includes(this.order.type)) {
        if (this.suggest.type === 'shelf2box') {
          return this.$gettext('Вы положили %{count} шт. этого товара в корзину?', {
            count: String(this.models.count),
          });
        }
        if (this.suggest.type === 'box2shelf') {
          return this.$gettext('Вы уверены, что переместили %{count} шт. этого товара на полку %{shelf}?', {
            count: String(this.suggest.count),
            shelf: (this.models.shelf && this.models.shelf.title) || this.shelf.title,
          });
        }
      }
      if (this.order.type === 'visual_control') {
        return this.$gettext('Вы уверены, что собрали все товары с дефектами?');
      }
      if (this.suggest.weight) {
        if (this.suggest.type === 'box2shelf') {
          return this.$gettext('Вы уверены, что разместили %{weight} г. товара на полке %{shelf}?', {
            weight: String(this.weight),
            shelf: (this.models.shelf && this.models.shelf.title) || this.shelf.title,
          });
        }
        return this.$gettext('Вы уверены, что забрали %{weight} г. товара с полки %{shelf}?', {
          weight: String(this.weight),
          shelf: (this.models.shelf && this.models.shelf.title) || this.shelf.title,
        });
      }
      if (this.suggest.type === 'box2shelf') {
        return this.$ngettext(
          'Вы уверены, что разместили %{count} товар на полке %{shelf}?',
          'Вы уверены, что разместили %{count} товаров на полке %{shelf}?',
          this.models.count!,

          {
            count: String(this.models.count),
            shelf: (this.models.shelf && this.models.shelf.title) || this.shelf.title,
          },
        );
      }
      return this.$ngettext(
        'Вы уверены, что забрали %{count} товар с полки %{shelf}?',
        'Вы уверены, что забрали %{count} товаров с полки %{shelf}?',
        this.models.count!,

        {
          count: String(this.models.count),
          shelf: (this.models.shelf && this.models.shelf.title) || this.shelf.title,
        },
      );
    },
    confirmText(): string {
      if (['hand_move', 'kitchen_provision'].includes(this.order.type)) {
        if (this.suggest.type === 'box2shelf') {
          return this.$gettext('После подтверждения размещенный товар будет сразу же доступен для продажи');
        }
      }
      return '';
    },
    productStorageTypeTitle(): string {
      let tag;
      if (this.suggest.vars && this.suggest.vars.mode === 'item') {
        tag = this.item?.tags[0] || 'empty';
      } else {
        tag = this.product.tags[0] || 'empty';
      }
      return storageTypesInfo[tag];
    },
    hintText(): string {
      const hintTexts = {
        default: {
          shelf: this.$gettext('Отсканируйте полку на которую кладете товар'),
        },
        shipment: {
          finish: this.$gettext('Верните этот товар обратно'),
        },
        refund: {
          shelf: this.$gettext('Отсканируйте указанную полку'),
          finish: this.$gettext('Верните товары в корзину'),
        },
        part_refund: {
          shelf: this.$gettext('Отсканируйте указанную полку'),
          finish: this.$gettext('Верните товары в корзину'),
        },
        sale_stowage: {
          finish:
            this.suggest.type === 'box2shelf'
              ? this.$gettext('Разместите товары на полке')
              : this.$gettext('Верните товары в корзину'),
        },
        weight_stowage: {
          finish: this.$gettext('Разместите товары на полке'),
        },
        stowage_market: {
          finish: this.$gettext('Разместите посылку на полке %{shelf}', {
            shelf: this.models.shelf?.title || '',
          }),
          'select-package-class': this.packageClass
            ? this.$gettext('Разместите посылку на полке %{shelf}', {
                shelf: this.models.shelf?.title || '',
              })
            : this.$gettext('Укажите тип и размер посылки'),
        },
        visual_control: {
          finish: this.$gettext('Разместите указанное количество товаров на полку'),
        },
        hand_move: {
          finish:
            this.suggest.type === 'shelf2box'
              ? this.$gettext('Положите товары в корзину')
              : this.$gettext('Разместите товары на полке'),
        },
        kitchen_provision: {
          finish:
            this.suggest.type === 'shelf2box'
              ? this.$gettext('Положите товары в корзину')
              : this.$gettext('Разместите товары на полке'),
        },
        robot_provision: {
          finish:
            this.suggest.type === 'shelf2box'
              ? this.$gettext('Положите товары в корзину')
              : this.$gettext('Разместите товары на полке'),
        },
      };
      if (this.order.isControl) {
        return hintTexts['control'][this.activeStep.name];
      }
      const hintText = hintTexts[this.order.type] || hintTexts['default'];
      return hintText[this.activeStep.name];
    },
    activeStep(): Step {
      return this.stepManager.activeStep;
    },
    steps(): Step[] {
      return this.stepManager.steps;
    },
    suggestMenuConfig(): MenuItemConfig[] {
      const menuConfig: MenuItemConfig[] = [];

      const errorInCardBtn: MenuItemConfig = {
        buttonText: this.$gettext('Ошибка в карточке товара'),
        buttonSecondText: this.$gettext('Изображение, описание, другое'),
        dataTest: 'suggest-details error-card',
        onClick: () => {
          callAction<ActionReportProps, void>(Report, {
            title: 'Ошибка в карточке товара',
            productId: this.product.product_id,
          });
        },
        condition: () => this.userStore.experimentByName(experiments.exp_product_bug_ticket),
      };
      menuConfig.push(errorInCardBtn);

      const changeProductCountBtn: MenuItemConfig = {
        buttonText: this.$gettext('Изменить количество'),
        dataTest: 'suggest-details menu change-count-btn',
        onClick: () => this.onChangeProductCountClick(),
        condition: () =>
          checkConditions(this.suggest!, 'all', true) &&
          this.suggest!.status === 'request' &&
          this.steps.some(step => step.name === 'count'),
      };
      menuConfig.push(changeProductCountBtn);

      const changeProductValidBtn: MenuItemConfig = {
        buttonText: this.$gettext('Изменить срок годности'),
        dataTest: 'suggest-details menu change-valid-btn',
        onClick: () => this.onChangeProductValidClick(),
        condition: () => checkConditions(this.suggest!, 'need_valid', true) && this.suggest!.status === 'request',
      };
      menuConfig.push(changeProductValidBtn);

      const changeValidTypeBtn: MenuItemConfig = {
        buttonText:
          this.uiState.validType === 'expirationDate'
            ? this.$gettext('Ввод даты изготовления')
            : this.$gettext('Ввод срока годности'),
        buttonSecondText: this.$gettext('Изменить на'),
        dataTest: 'suggest-details menu change-valid-type-btn',
        onClick: () => this.changeValidType(),
        condition: () =>
          checkConditions(this.suggest!, 'need_valid', true) &&
          this.steps.findIndex(s => s.name === 'date') !== -1 &&
          this.userStore.experimentByName(experiments.exp_manhattan),
      };
      menuConfig.push(changeValidTypeBtn);

      const clearLastWeightBtn: MenuItemConfig = {
        buttonText: this.$gettext('Удалить последний введенный вес'),
        dataTest: 'suggest-details menu clear-last-weight-btn',
        onClick: () => this.clearLastWeight(),
        condition: () => this.activeStep.name === 'weight',
      };
      menuConfig.push(clearLastWeightBtn);

      const clearAllWeightsBtn: MenuItemConfig = {
        buttonText: this.$gettext('Очистить введенный вес'),
        dataTest: 'suggest-details menu clear-all-weights-btn',
        onClick: () => this.clearAllWeights(),
        condition: () => this.activeStep.name === 'weight',
      };
      menuConfig.push(clearAllWeightsBtn);

      const writeoffAllBtn: MenuItemConfig = {
        buttonText: this.$gettext('Списать все'),
        dataTest: 'writeoff menu writeoff-all',
        onClick: () => {
          this.models.count = this.suggest?.count!;
          this.checkStepsFinished();
        },
        condition: () => this.activeStep.name === 'count' && this.order.type === 'writeoff',
      };
      menuConfig.push(writeoffAllBtn);

      return menuConfig;
    },
    reasonHint(): string {
      if (this.order.type === 'sale_stowage' && this.models.shelf?.type === 'review') {
        return this.$gettext('Укажите причину размещения на полку Разбор');
      }
      return this.$gettext('Какая причина списания?');
    },
    actualReasons(): { [key: string]: string } {
      if (this.order.type === 'sale_stowage' && this.models.shelf?.type === 'review') {
        return this.reasons[`sale_stowage_review`];
      }
      return this.reasons[`${this.order.type}_${this.order.vars.stage}`] || {};
    },
    weight(): number | undefined {
      if (this.suggest.conditions.need_weight) {
        if (!this.suggest.conditions.all) {
          return this.suggest.weight!;
        }
        return this.models.weights.reduce((acc, value) => acc + value, 0);
      }
      return undefined;
    },
    multiProductCounterConfig(): CounterConfig[] {
      let defects: Defect[] = [];
      if (this.product.visual_control_defects) {
        defects = this.product.visual_control_defects;
      }
      if (defects.length === 0 && this.product.visual_control_images) {
        defects = this.product.visual_control_images.map(image => ({ image }));
      }
      if (defects.length === 0) {
        defects = [{ image: this.product.images[0] }];
      }
      return defects.map((d, index) => {
        return {
          image: d.image,
          initialValue: 0,
          title: d.title || this.multiCounterTitle(index + 1),
          description: d.desc,
        };
      });
    },
    multiCounterTitle(): (value: number) => string {
      return value => {
        return this.$gettext('Дефект №%{number}', { number: String(value) });
      };
    },
    needInfo(): boolean {
      return this.activeStep.name !== 'multi-product-counter';
    },
    needPicture(): boolean {
      return this.activeStep.name !== 'multi-product-counter';
    },
    caption(): string {
      const captions = {
        visual_control: this.$gettext('Проверка дефектов'),
      };
      return captions[this.order.type] || '';
    },
    totalCount(): number {
      return this.models.counts.reduce((acc, value) => {
        return acc + value;
      }, 0);
    },
    available(): AvailableProduct | undefined {
      const available = this.productsStore.availableByProductId(this.product?.product_id);
      return available?.find(a => a.shelf_id === this.suggest.shelf_id);
    },
    initialDateValue(): string | undefined {
      // без продуктов ввод даты не имеет смысла
      if (!this.product) return undefined;
      if (this.order.type == 'sale_stowage' || this.order.type == 'weight_stowage') {
        if (this.userStore.experimentByName('exp_short_need_valid') && this.product.valid <= 14) {
          return undefined;
        }
        return this.suggest.valid || undefined;
      }
      return undefined;
    },
    isCheckOrder(): boolean {
      return ['inventory_check_product_on_shelf'].includes(this.order.type);
    },
  },
  watch: {
    suggest(newValue) {
      if (!newValue) {
        this.$emit('cancel');
      }
    },
  },
  mounted(): void {
    this.initModel();
  },
  methods: {
    initModel(): void {
      if (this.product?.true_mark && this.order?.type === 'refund' && this?.suggest?.count === 1) {
        this.models.count = 1;
        this.nextStep();
      }
      if (this.suggest.conditions.need_weight && this.barcode) {
        this.models.weights = [Product.weightFromBarcode(this.barcode)];
        this.models.count = 1;
      }
      if (this.product?.type_accounting === TypeAccountingEnum.true_weight && this.barcode) {
        this.models.count = Product.weightFromBarcode(this.barcode) || this.suggest.count!;
      }
      if (this.suggest.result_count && !this.suggest.conditions.need_weight) {
        this.models.count = this.suggest.result_count;
      }
      if (
        this.order.type === 'sale_stowage' &&
        !this.suggest.conditions.need_weight &&
        !this.barcode &&
        this.suggest.conditions.max_count &&
        this.suggest.vars.stage === 'stowage'
      ) {
        this.models.count = this.suggest.count!;
      }
      if (this.order.isControl && this.suggest.type === 'box2shelf') {
        this.models.count = this.suggest.count!;
      }
      if (this.suggest.vars?.mode === 'item') {
        this.models.count = this.suggest.count!;
      }
      if (this.userStore.experimentByName(experiments.exp_manhattan)) {
        this.uiState.validType = 'productionDate';
      }
      if (['refund', 'part_refund', 'shipment'].includes(this.order.type) && this.order.target === 'canceled') {
        this.models.count = this.suggest.count!;
      }
      if (!this.suggest.conditions.all) {
        this.models.count = this.suggest.count!;
      }

      if (
        this.order.type === 'sale_stowage' &&
        !this.suggest.conditions.need_weight &&
        this.suggest.product?.children_id?.length
      ) {
        this.models.count = 0;
      }
      if (this.order.type === 'stowage_market') {
        if (this.suggest.item?.packageClass) {
          this.changePackageClass(this.suggest.item.packageClass);
        }
        if (
          this.suggest.result_count &&
          this.suggest.shelf?.type !== 'parcel_returned' &&
          this.userStore.experimentByName(experiments.exp_package_class)
        ) {
          this.setActiveStep('select-package-class');
        }
      }
    },
    async onFinishBtnClick(): Promise<void> {
      // вызываем конфирмы из родителя!
      // для документов, что уже вызывают конфирм сами, условие ниже!
      if (
        [
          'acceptance',
          'sale_stowage',
          'hand_move',
          'shipment',
          'stowage_market',
          'kitchen_provision',
          'robot_provision',
        ].includes(this.order.type)
      ) {
        this.finish();
        return;
      }
      if (this.order.type === 'visual_control') {
        if (this.totalCount === 0 && this.suggest.type === SuggestTypeEnum.shelf2box) {
          const confirmed = await this.$notification.confirmBottom({
            title: this.$gettext('Вы уверены, что не нашли ни одного товара с дефектами?'),
          });
          if (confirmed) {
            this.finish();
          }
          return;
        }
        if (this.suggest.vars.stage === 'visual_control') {
          this.finish();
          return;
        }
      }
      const confirmed = await this.$notification.confirmBottom({
        title: this.confirmTitle,
        text: this.confirmText,
      });
      if (confirmed) {
        this.checkStepsFinished();
      }
    },
    onChangeProductCountClick(): void {
      this.setActiveStep('count');
    },
    onChangeProductValidClick(): void {
      this.setActiveStep('date');
    },
    checkStepsFinished(): void {
      if (this.order.isControl) {
        if (this.suggest.count && this.models.count! >= this.suggest.count!) {
          this.$emit('finish', {
            count: this.models.count,
            date: this.suggest.conditions.valid,
          });
          return;
        }
      }
      if (this.isCheckOrder) {
        this.$emit('finish', {
          count: this.models.count,
        });
        return;
      }

      if (this.stepManager.hasNextStep(this.models)) {
        this.nextStep();
      } else {
        this.finish();
      }
    },
    nextStep(): void {
      this.stepManager.nextStep(this.models);
    },
    async finish(): Promise<void> {
      if (this.order.type === 'visual_control') {
        if (this.suggest.type === SuggestTypeEnum.box2shelf) {
          // для обратных саджестов возвращаем все, что есть в саджесте
          this.$emit('finish', {
            count: this.suggest.count,
          });
        } else {
          this.$emit('finish', {
            count: this.totalCount,
          });
        }
        return;
      }
      this.$emit('finish', {
        count: this.models.count,
        weight: this.weight,
        date: this.models.date,
        shelf_id: this.models.shelf?.shelf_id,
        reason: this.models.reason,
        product_id: this.productId,
        package_class: this.packageClass,
      });
    },
    setActiveStep(step: string) {
      this.stepManager.setActiveStep(step);
    },
    selectReason(): void {
      this.checkStepsFinished();
    },
    endWeightStep(): void {
      this.models.count = this.models.weights.length;
      this.checkStepsFinished();
    },
    endCountStep(value: number): void {
      this.models.count = value;
      this.checkStepsFinished();
    },
    endDateStep(value): void {
      this.models.date = value;
      this.checkStepsFinished();
    },
    endShelfStep(shelf: Shelf): void {
      this.models.shelf = shelf;
      this.checkStepsFinished();
    },
    changeValidType(): void {
      this.$alert.success(this.$gettext('Тип вводимой даты успешно изменен'));
      if (this.uiState.validType === 'productionDate') {
        this.uiState.validType = 'expirationDate';
      } else {
        this.uiState.validType = 'productionDate';
      }
    },
    onScanWeightProduct(weight: number): void {
      if (this.suggest.conditions.max_count && this.models.count! >= this.suggest.count!) {
        this.$alert.error(this.$gettext('Невозможно собрать товаров больше чем в задании'));
      } else {
        this.models.weights.push(weight);
        this.models.count = this.models.count! + 1;
      }
    },
    clearLastWeight(): void {
      this.models.weights.pop();
      this.models.count = this.models.count! - 1;
    },
    clearAllWeights(): void {
      this.models.weights = [];
      this.models.count = 0;
    },
    async cancelSuggest(): Promise<void> {
      const confirmed = await this.$modal.show({
        title: this.$gettext('Изменить результат проверки?'),
        btnPosition: ButtonPositionsEnum.horizontal,
      });
      if (!confirmed) {
        return;
      }
      this.models.count = 0;
      this.$emit('cancel-suggest');
      const unWatch = this.$watch(
        'suggest',
        val => {
          if (val.status === 'request') {
            this.stepManager.nextStep(this.models);
            unWatch();
          }
        },
        { deep: true },
      );
    },
    saveCounts(counts): void {
      this.models.counts = counts;
    },
    changePackageClass(value): void {
      this.packageClass = value;
    },
  },
});
</script>
