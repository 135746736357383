<template>
  <svg width="56" height="57" viewBox="0 0 56 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_2878_35111)">
      <rect x="8" y="5.5" width="40" height="40" rx="20" fill="#FA3E2C" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M24.4009 30.3016L28.0009 36.3016L31.6009 30.3016L29.2009 30.8774L32.7966 21.9016H23.2051L26.8009 30.8774L24.4009 30.3016ZM22.7243 20.7016H33.2773L33.9984 18.9016H22.0032L22.7243 20.7016ZM21.5225 17.7016L20.8008 15.9H35.2008L34.4791 17.7016H21.5225Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_2878_35111"
        x="0"
        y="0.5"
        width="56"
        height="56"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="3" />
        <feGaussianBlur stdDeviation="4" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.94902 0 0 0 0 0.266667 0 0 0 0 0.266667 0 0 0 0.35 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2878_35111" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2878_35111" result="shape" />
      </filter>
    </defs>
  </svg>
</template>

<script lang="ts" />
