<template>
  <div>
    <Empty v-if="!(ordersForCarousel.length || failedOrders.length)" />
    <GroupContainer v-else :requested-orders="ordersForCarousel" :failed-orders="failedOrders" />
  </div>
</template>

<script lang="ts" setup>
import { OrdersByWeight } from '@/fsd/widgets/home/carousel//types';
import { ORDERS_WEIGHT, maxWeight } from '@/fsd/widgets/home/carousel/orders_weights';
import { getWeight, sameWeightSort } from '@/fsd/widgets/home/carousel/utils';
import BaseOrder, { OrderTypeEnum, OrderWorkStatusEnum } from '@/models/orders/BaseOrder';
import OrderOrder from '@/models/orders/OrderOrder';
import { useOrders } from '@/store/modules/orders';
import Empty from '@/ui/home/empty/empty.vue';
import GroupContainer from '@/ui/home/group-item/group-container.vue';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';

const ordersStore = useOrders();
const { requestedOrders, failedOrders } = storeToRefs(ordersStore);

const ordersForCarousel = computed(() => {
  const filteredOrders = requestedOrders.value
    .filter(order => Object.keys(ORDERS_WEIGHT).includes(order.type))
    .filter(order => {
      return !(
        [OrderTypeEnum.order, OrderTypeEnum.order_retail].includes(order.type) &&
        (order as OrderOrder).isOrderPaused &&
        order.status === OrderWorkStatusEnum.request
      );
    });

  const ordersByWeight: OrdersByWeight = {};
  for (let i = 0; i <= maxWeight; i++) {
    ordersByWeight[i] = filteredOrders.filter(order => getWeight(order) === i);
  }

  const resultOrders: BaseOrder[] = [];
  for (let weight in ordersByWeight) {
    resultOrders.push(...sameWeightSort(ordersByWeight[weight]));
  }

  return resultOrders.slice(0, 10);
});
// Без этого не получится получить доступ к ordersForCarousel в тесте
defineExpose({ ordersForCarousel });
</script>
