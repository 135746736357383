// 1) Отправить запрос
// 2) Дождаться результат в ивенте
import { api } from '@/fsd/data/api/api.service';
import { useSubscribeOnSuggest } from '@/fsd/data/utils/subscribeOnSuggests';
import { getDuration, markEnd, markStart } from '@/fsd/shared/utils/timeMarkHelper';
import { SuggestStatusEnum } from '@/models/Suggest';
import { OrderDoneBox2ShelfLikeShelfRequest, OrderDoneBox2ShelfRequest } from '@/services/requests';
import { useOrders } from '@/store/modules/orders';
import { AxiosError } from 'axios';

export interface Box2ShelfOptions {
  onValidateError?: (e: string) => void;
  beforeRequest?: () => void;
  afterRequest?: () => void;
  measureRequest?: (duration: number) => void;
  onRequestError?: (e: AxiosError<any>, retry: () => Promise<any>) => void | Promise<boolean> | boolean;
  beforeEventWaiting?: () => void;
  afterEventWaiting?: () => void;
  measureEvent?: (duration: number) => void;
  onEventWaitingError?: (e: string) => void;
  closeLoader?: () => void;
}
const box2shelf = async (
  order_id: string,
  payload: OrderDoneBox2ShelfRequest,
  options: Box2ShelfOptions = {},
): Promise<boolean> => {
  const { suggest_id } = payload;
  if (!order_id || !suggest_id) {
    options.onValidateError?.('Suggest not found');
    return false;
  }
  try {
    options.beforeRequest?.();
    markStart('box2shelf-request' + order_id);
    const response = await api.order.done.box2shelf(payload);
    markEnd('box2shelf-request' + order_id);
    const duration = getDuration('box2shelf-request' + order_id);
    options.measureRequest?.(duration || 0);
    options.afterRequest?.();
    if (response.data?.suggests) {
      await useOrders().updateSuggestsFromResponse(response.data.suggests);
      const updatedSuggest = response.data.suggests.find(s => s.suggest_id === suggest_id);
      if (updatedSuggest && updatedSuggest.status === SuggestStatusEnum.done) {
        // выход! дальше можно не ждать, получили все, что нужно из ответа на запрос
        options.closeLoader?.();
        return true;
      }
    }
  } catch (e) {
    options.closeLoader?.();
    return options.onRequestError?.(e as AxiosError, () => box2shelf(order_id, payload, options)) || false;
  }
  try {
    markStart('box2shelf-event' + order_id);
    options.beforeEventWaiting?.();

    const isChangeShelf = payload.status === 'error' && payload.reason?.code === 'LIKE_SHELF';

    await useSubscribeOnSuggest(
      order_id,
      suggest_id,
    )(s => {
      if (!s) {
        return true;
      }
      if (isChangeShelf && s.status === 'request') {
        const targetShelf_id = (payload as OrderDoneBox2ShelfLikeShelfRequest).reason.shelf_id;
        if (s.shelf_id === targetShelf_id) {
          return true;
        } else {
          throw 'Change shelf error';
        }
      }
      if (s.status === 'failed') {
        throw 'Change shelf error';
      }
      if (s.status === 'done') {
        return true;
      }
      return false;
    });

    markEnd('box2shelf-event' + order_id);
    const duration = getDuration('box2shelf-event' + order_id);
    options.measureEvent?.(duration || 0);
    options.afterEventWaiting?.();
  } catch (e) {
    options.onEventWaitingError?.(e as string);
    options.closeLoader?.();
    return false;
  }
  options.closeLoader?.();
  return true;
};

export default box2shelf;
