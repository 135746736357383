<template>
  <PageLayout :order_id="order_id">
    <template #header>
      <Bar
        :order="order"
        :text="controlBarText"
        :caption="captionText"
        need-progress
        :progress-config="progressConfig"
        :total="suggests.length"
        :menu-config="menuConfig"
        @close-click="toHomePage"
      />
    </template>

    <template #default>
      <div class="flex flex-col h-full">
        <Hint v-if="filteredSuggests.length !== 0" class="my-2 mx-4">{{ hintText }}</Hint>
        <SuggestCardContainer
          doc-type="acceptance"
          :order="order"
          :suggest-menu-config="suggestMenuConfig"
          :suggests="
            filteredSuggests.map(s => {
              s.need_menu = s.status === 'request' || s.result_count > 0;
              return s;
            })
          "
          @open-suggest-menu="() => (uiStateNeedBarcodeRequest = false)"
          @close-suggest-menu="() => (uiStateNeedBarcodeRequest = true)"
        />
      </div>
      <SuggestDetails
        v-if="suggestDetails.visible.value"
        :suggest-id="suggestDetails.props.value.suggest_id"
        :suggest-prop="suggestDetails.props.value.virtual ? suggestDetails.props.value : undefined"
        :order-id="order.order_id"
        :barcode="uiState.barcode"
        @cancel="suggestDetails.hide"
        @finish="props => finishActiveSuggest(props, suggestDetails.props.value)"
      />

      <ShareOrder v-if="order && shareOrder.visible.value" :order="order" @close="shareOrder.hide" />
    </template>

    <template #footer>
      <LayoutFooter>
        <FilterMenu v-if="filter === 'all'" :menu-config="filterMenuConfig" />
        <UiButton v-if="filter !== 'all'" @click="setFilter('all')">
          {{ $gettext('Назад') }}
        </UiButton>

        <SliderButton
          v-else
          data-test="acceptance finish btn"
          :disabled="!allSuggestDone"
          @slide-complete="finishOrder"
        >
          {{ $gettext('Завершить') }}
        </SliderButton>
      </LayoutFooter>
    </template>
  </PageLayout>
</template>

<script lang="ts">
import { useSubscribeOnOrderStatus } from '@/fsd/data/utils/subscribeOnOrder';
import PageLayout from '@/fsd/entities/page/PageLayout.vue';
import { getDoneSuggests } from '@/fsd/entities/suggest/tools/suggestsFilters';
import { useCheck } from '@/fsd/entities/suggest/tools/useCheck';
import { useFilter } from '@/fsd/entities/suggest/tools/useFilter';
import { checkContractor } from '@/fsd/features/order/utils/checkContractor';
import { useDetachFromOrder } from '@/fsd/features/order/utils/useDetachFromOrder';
import { useEndOrder } from '@/fsd/features/order/utils/useEndOrder';
import { ButtonPositionsEnum } from '@/fsd/shared/universalModal';
import { useComponent } from '@/hooks/useComponent';
import { useHandleOrderStatus } from '@/hooks/useHandleOrderStatus';
import requestBarcode from '@/mixins/requestBarcode';
import requestProductCode from '@/mixins/requestProductCode';
import { TypeAccountingEnum } from '@/models/Product';
import Suggest from '@/models/Suggest';
import AcceptanceOrder from '@/models/orders/AcceptanceOrder';
import { OrderTargetEnum } from '@/models/orders/BaseOrder';
import { AudioService } from '@/services/audio.service';
import { ModeService } from '@/services/mode.service';
import { useItems } from '@/store/modules/items';
import { useOrders } from '@/store/modules/orders';
import { useProducts } from '@/store/modules/products';
import { useUser } from '@/store/modules/user';
import { experiments } from '@/temp/constants';
import { getQuantUnit } from '@/temp/constants/translations/quantUnits';
import { logger } from '@/temp/plugins/logs';
import { QrActions } from '@/temp/services/qr-actions';
import Bar from '@/ui/common/bar/bar.vue';
import FilterMenu from '@/ui/common/filter-menu/filter-menu.vue';
import Hint from '@/ui/common/hint/hint.vue';
import LayoutFooter from '@/ui/common/layout/layout-footer.vue';
import { useLoader } from '@/ui/common/loader/useLoader';
import type { MenuItemConfig } from '@/ui/common/menu/types';
import ShareOrder from '@/ui/common/share-order/share-order.vue';
import SliderButton from '@/ui/common/slider-button/slider-button.vue';
import { getWeightToView } from '@/ui/common/suggest-card/formatters/count-formatter';
import SuggestDetails from '@/ui/common/suggest-details/suggest-details.vue';
import { Model } from '@/ui/common/suggest-details/types';
import UiButton from '@/ui/common/ui-button.vue';
import { checkConditions } from '@/utils/checkConditions';
import SuggestCardContainer from '@/views/common/suggest-card-container.vue';
import { AxiosError } from 'axios';
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';

interface Data {
  uiState: {
    barcode?: string;
  };
  uiStateNeedBarcodeRequest: boolean;
}

export default defineComponent({
  name: 'Acceptance',
  components: {
    PageLayout,
    LayoutFooter,
    SuggestCardContainer,
    Bar,
    Hint,
    UiButton,
    SuggestDetails,
    FilterMenu,
    ShareOrder,
    SliderButton,
  },
  mixins: [requestProductCode, requestBarcode],
  props: {
    order_id: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { showLoader } = useLoader();
    const ordersStore = useOrders();
    const itemsStore = useItems();
    const productsStore = useProducts();
    const userStore = useUser();
    const shareOrder = useComponent();
    const suggestDetails = useComponent<Suggest>();
    const router = useRouter();

    const { detachBtn } = useDetachFromOrder(props.order_id);
    const { filter, setFilter, filterMenuConfig, progressConfig, suggests, requestSuggests, filteredSuggests } =
      useFilter(props.order_id);

    useHandleOrderStatus(props.order_id);

    return {
      showLoader,
      ordersStore,
      itemsStore,
      productsStore,
      userStore,
      shareOrder,
      suggestDetails,
      router,
      detachBtn,
      filter,
      setFilter,
      filterMenuConfig,
      progressConfig,
      suggests,
      requestSuggests,
      filteredSuggests,
    };
  },
  data(): Data {
    return {
      uiState: {
        barcode: undefined,
      },
      uiStateNeedBarcodeRequest: true,
    };
  },
  computed: {
    order(): AcceptanceOrder | undefined {
      return this.ordersStore.orderById(this.order_id) as AcceptanceOrder;
    },
    allSuggestDone(): boolean {
      return this.requestSuggests.length === 0;
    },
    menuConfig(): MenuItemConfig[] {
      const menuConfig: MenuItemConfig[] = [];
      menuConfig.push(this.detachBtn);

      const shareOrderBtn: MenuItemConfig = {
        buttonText: this.$gettext('Разделить задание'),
        onClick: () => this.showShareOrder(),
        condition: () => this.userStore.experimentByName(experiments.exp_tsd_companion),
      };
      menuConfig.push(shareOrderBtn);

      return menuConfig;
    },
    contractorName(): string {
      if (!this.order || !this.order.attr.contractor) {
        return '';
      }
      return this.order.attr.contractor;
    },
    controlBarText(): string {
      switch (this.filter) {
        case 'all':
          return this.$gettext('Осталось %{request} из %{all} товаров', {
            request: String(this.requestSuggests.length),
            all: String(this.suggests.length),
          });
        case 'done':
        case 'request':
        case 'part':
        case 'noProduct':
          return this.$ngettext(
            '%{filtered} товар с таким статусом',
            '%{filtered} товаров с таким статусом',
            this.filteredSuggests.length,
            { filtered: String(this.filteredSuggests.length) },
          );
      }
      return this.contractorName;
    },
    suggestMenuConfig(): (suggest: Suggest) => MenuItemConfig[] {
      return suggest => {
        const menuConfig: MenuItemConfig[] = [];
        const noProductBtn: MenuItemConfig = {
          buttonText: this.$gettext('Отметить как отсутствующий'),
          onClick: () => {
            this.onNoProductClick(suggest);
          },
          dataTest: 'no-product btn',
          condition: () => {
            return checkConditions(suggest, 'all', true) && (suggest.status === 'request' || suggest.result_count! > 0);
          },
        };
        menuConfig.push(noProductBtn);
        const openDetailsBtn: MenuItemConfig = {
          buttonText: this.$gettext('Принять товар'),
          onClick: async () => {
            if (this.isMustBeTrust) {
              this.$modal.show({ title: this.$gettext('Приемка разрешена только по QR коду') });
              return;
            }
            this.uiStateNeedBarcodeRequest = false;
            await this.suggestDetails.asyncShow(suggest);
            this.uiStateNeedBarcodeRequest = true;
          },
          dataTest: 'open-details btn',
          condition: () => {
            return (
              this.productsStore.productById(suggest.product_id)?.type_accounting === TypeAccountingEnum.true_weight &&
              this.userStore.experimentByName(experiments.exp_weighty_touch)
            );
          },
        };
        menuConfig.push(openDetailsBtn);

        return menuConfig;
      };
    },
    captionText(): string {
      switch (this.filter) {
        case 'all':
          return this.$gettext('Приемка');
        case 'done':
          return this.$gettext('Товары со статусом “Готово”');
        case 'request':
          return this.$gettext('Не отсканированные товары');
        case 'part':
          return this.$gettext('Товары со статусом “Почти”');
        case 'noProduct':
          return this.$gettext('Товары со статусом “Нет”');
      }
      return this.$gettext('Приемка');
    },

    isWeight(): boolean {
      return this.suggests.some(s => s.conditions.need_weight);
    },
    isMayBeTrust(): boolean {
      // приемка с весовым не может быть доверительной
      return Boolean(
        this.order!.attr.request_type === 'move_order' &&
          this.requestSuggests.length === this.suggests.length &&
          !this.isWeight,
      );
    },

    isMustBeTrust(): boolean {
      return Boolean(this.userStore.experimentByName(experiments['exp_lightman']) && this.order!.attr.trust_code);
    },
    hintText(): string {
      return this.$gettext('Отсканируйте товар');
    },
  },
  methods: {
    async requestBarcode(): Promise<boolean> {
      const onScanBarcode = async rawBarcode => {
        let barcode = rawBarcode;
        // TODO это для обратной совместимости, в будущем надо будет серьезнее распилить этапы с подписью и без
        if (QrActions.isAction(barcode)) {
          logger('Получен баркод с подписью');
          barcode = await QrActions.trustAcceptance(barcode);
        }
        if ([this.order!.attr.request_id, this.order!.attr.trust_code].includes(barcode)) {
          if (this.order!.attr.request_type === 'move_order') {
            //  проверяем на наличие закрытых саджестов
            if (getDoneSuggests(this.suggests).length > 0) {
              this.$alert.error(
                this.$gettext('Невозможно завершить приемку доверительно, если уже есть принятые товары !'),
              );
              return false;
            }
          }
          await this.checkConfirmedTrustAcceptance(this.trustAcceptance);
          return false;
        }
        if (!ModeService.isProduction() && barcode === this.order!.attr.doc_number) {
          await this.checkConfirmedTrustAcceptance(this.trustAcceptance);
          return false;
        }
        if (this.isMustBeTrust) {
          this.$modal.show({ title: this.$gettext('Приемка разрешена только по QR коду') });
          return false;
        }
        return true;
      };
      const result = await this.requestProductCode({ checkSuggests: true, onScanBarcode });
      if (!result.findSuggest) {
        if (this.order?.attr.acceptance_mode !== 'pre-check' || !result.product) {
          return true;
        }
        // проверить возможность добавления продукта
        const canSendMoreProduct = await checkContractor(this.order_id, result.product.product_id);
        if (!canSendMoreProduct) {
          AudioService.playError();
          return true;
        }
        const virtualSuggest = new Suggest({
          order_id: this.order?.order_id,
          product_id: result.product!.product_id,
          shelf_id: this.order.shelves[0],
          count: 0,
          conditions: {
            all: true,
            max_count: false,
            need_valid: true,
          },
          virtual: true,
        });
        await this.suggestDetails.asyncShow(virtualSuggest);
        return true;
        // открыть типичное окно для приемки,спросить кол-во, добавить саджест в приемку, вернуть плользователя на главную, не блочить ему сценарий.
      }

      this.uiState.barcode = result.barcode;
      const suggest = result.findSuggest!;
      //если нет закрытых саджестов и есть поле request_type==='move_order спрашиваем точно ли кладовщик не хочет принимать доверительно
      if (this.isMayBeTrust) {
        const confirmed = await this.$modal.show({
          title: this.$gettext('Эта приемка должна быть выполнена доверительно'),
          text: this.$gettext(
            'Вы хотите принять все товары не доверительно – это действие необратимо. Вы уверены, что хотите продолжить?',
          ),
          btnPosition: ButtonPositionsEnum.horizontal,
          confirmBtnTitle: this.$gettext('Продолжить'),
          closeBtnTitle: this.$gettext('Назад'),
        });
        if (confirmed) {
          await this.suggestDetails.asyncShow(suggest);
          return true;
        }
        return true;
      }
      if (
        result.childProduct?.type_accounting === 'unit' &&
        result.childProduct.parent_id &&
        suggest.conditions.need_weight
      ) {
        this.$modal.show({ title: this.$gettext('Сканируйте ШК с коробки') });
        return true;
      }
      if (suggest.vars.mode === 'item') {
        await this.finishActiveSuggest({ count: 1 }, suggest);
        return true;
      } else {
        await this.suggestDetails.asyncShow(suggest);
        return true;
      }
    },
    async checkConfirmedTrustAcceptance(trustAcceptance: any): Promise<void> {
      const confirmed = await this.$modal.show({
        title: this.$gettext('Вы хотите принять весь товар без проверки?'),
        text: this.$gettext(
          'Если вы подтвердите это действие, то весь товар будет принят автоматически и проверять его не потребуется',
        ),
        btnPosition: ButtonPositionsEnum.horizontal,
      });
      if (confirmed) {
        trustAcceptance();
      }
    },
    toHomePage(): void {
      this.router.push({ name: 'home' });
    },
    onNoProductClick(suggest: Suggest): void {
      if (this.isMustBeTrust) {
        this.$modal.show({ title: this.$gettext('Приемка разрешена только по QR коду') });
        return;
      }
      if (suggest.conditions.need_weight) {
        this.finishActiveSuggest({ count: 0, weight: 0 }, suggest);
      } else {
        this.finishActiveSuggest({ count: 0 }, suggest);
      }
    },
    async finishActiveSuggest(
      { count = 0, weight, date }: Pick<Model, 'count' | 'weight' | 'date'>,
      suggest: Suggest,
    ): Promise<void> {
      if (!suggest) {
        this.$alert.error(this.$gettext('Произошла ошибка при выполнении задания'));
        logger.error('empty active suggest', {
          type: 'code',
          page: 'accceptance',
          method: 'finishActiveSuggest',
          message: 'empty active suggest',
        });
        return;
      }
      const activeProduct = suggest.product;
      const activeItem = suggest.vars.mode !== 'item' ? undefined : suggest.item;
      if (count !== 0) {
        const getTitle = () => {
          if (activeProduct?.quants && activeProduct?.quants > 1 && activeProduct.quant_unit) {
            return this.$ngettext(
              'Вы уверены, что хотите принять %{count} упаковок товара (%{unit_count} %{unit})?',
              'Вы уверены, что хотите принять %{count} упаковок товара (%{unit_count} %{unit})?',
              count,
              {
                count: String(count),
                unit_count: String(activeProduct?.quants * count),
                unit: getQuantUnit(activeProduct?.quant_unit),
              },
            );
          }
          if (activeItem) {
            return this.$gettext('Вы уверены, что хотите принять %{item}?', {
              item: activeItem?.title,
            });
          }
          if (activeProduct?.type_accounting === TypeAccountingEnum.true_weight) {
            return this.$gettext('Вы уверены, что хотите принять %{weightPart} %{product}?', {
              weightPart: String(getWeightToView(count)),
              product: String(activeProduct?.title),
            });
          }
          return this.$gettext('Вы уверены, что хотите принять %{count} шт. %{product}?', {
            count: String(count),
            product: String(activeProduct?.title),
          });
        };
        const confirmed = await this.$notification.confirmBottom({
          title: getTitle(),
        });
        if (!confirmed) return;
      } else {
        const getTitle = () => {
          if (activeItem) {
            return this.$gettext('Вы уверены, что посылка %{item} отсутствует?', {
              item: activeItem.title,
            });
          }
          return this.$gettext('Вы уверены, что товар %{product} отсутствует?', {
            count: String(count),
            product: String(activeProduct?.title),
          });
        };
        const confirmed = await this.$notification.confirmBottom({
          title: getTitle(),
        });
        if (!confirmed) return;
      }

      if (count === suggest.result_count) {
        this.suggestDetails.hide();
        this.uiStateNeedBarcodeRequest = true;
        return;
      }
      if (suggest.virtual) {
        // особый случай для б2б и документов с pre_check
        await this.ordersStore.signal({
          order_id: this.order!.order_id,
          signal: 'add_product',
          data: {
            product_id: suggest.product_id,
            valid: date,
            count,
          },
        });
      } else {
        const result = await useCheck(this.order_id, {
          suggest_id: suggest.suggest_id,
          count,
          weight,
          valid: date,
        });
        if (!result) return;
      }

      this.suggestDetails.hide();
      this.uiStateNeedBarcodeRequest = true;
    },
    async finishOrder(): Promise<void> {
      const isFinishedConfirm = await this.$notification.confirmBottom({
        title: this.$gettext('Вы уверены, что приняли все товары?'),
      });
      if (!isFinishedConfirm) return;
      this.uiStateNeedBarcodeRequest = false;
      const result = await useEndOrder(this.order_id);
      if (result) {
        this.toHomePage();
      } else {
        this.uiStateNeedBarcodeRequest = true;
      }
    },
    async trustAcceptance(): Promise<void> {
      const { closeLoader } = this.showLoader(
        this.$gettext('Отправляем запрос на доверительную приемку'),
        this.order_id,
      );
      try {
        await this.ordersStore.signal({
          order_id: this.order!.order_id,
          signal: 'acceptance_agree',
        });
        await useSubscribeOnOrderStatus(this.order_id)(s => {
          if (!s || s === OrderTargetEnum.complete) {
            this.$alert.success(this.$gettext('Доверительная приемка проведена'));
            this.toHomePage();
            return true;
          }
          if (s === OrderTargetEnum.failed) {
            return true;
          }
          return false;
        });
        closeLoader();
      } catch (e: any) {
        closeLoader();
        if (!e.isAxiosError) return;
        const axiosError = e as AxiosError;
        if (axiosError.response?.data.code === 'ER_SUGGEST_WEIGHT_REQUIRED') {
          this.$modal.show({
            title: this.$gettext('Невозможно принять доверительно'),
            text: this.$gettext('В приемке присутствуют весовые товары'),
          });
        }
      }
    },
    async showShareOrder(): Promise<void> {
      this.uiStateNeedBarcodeRequest = false;
      await this.shareOrder.asyncShow();
      this.uiStateNeedBarcodeRequest = true;
    },
  },
});
</script>
