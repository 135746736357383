import { OrangeIconWarning } from '@/fsd/shared/icons/warning/OrangeIconWarning';
import { Modal } from '@/fsd/shared/tools/modalNotification';
import { checkTrueMarkErrorsCode } from '@/temp/constants/translations/checkTrueMarkErrorsCode';
import { $gettext } from '@/temp/plugins/gettext';
import { markRaw } from 'vue';

export default () => {
  return Modal.show({
    title: checkTrueMarkErrorsCode.ER_SUGGEST_INVALID_TRUE_MARK,
    text: $gettext('Отложите для дальнейших указаний. Возьмите другой товар'),
    component: markRaw(OrangeIconWarning),
  });
};
