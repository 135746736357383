<template>
  <div class="flex w-10 h-10 justify-center items-center rounded-full bg-red-normal">
    <IconCircleCancel color="white" class="p-2.5 w-full h-full" />
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import IconCircleCancel from './icon-circle-cancel.vue';

export default defineComponent({
  name: 'IconCancelForModal',
  components: { IconCircleCancel },
});
</script>
