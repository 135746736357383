<template>
  <Overlay>
    <Layout data-test="shelf-details container" class="bg-main pointer-events-auto">
      <template #header>
        <Bar
          :caption="$gettext('Отсканированные товары')"
          :text="barText"
          :menu-config="menuConfig"
          :icons="{ left: 'back', right: 'menu' }"
          :menu-id="'sideMenu'"
          data-test="suggest bar"
          @close-click="close"
        />
        <ToggleButton
          v-if="!isDiffMode"
          class="px-4 py-2"
          :labels="{ checked: $gettext('Товары'), unchecked: $gettext('История') }"
          @input="changeListType"
        />
      </template>
      <div v-if="hasScannedProducts" class="flex flex-col h-full">
        <Hint id="hint" class="my-2 mx-4">{{ hintText }}</Hint>

        <div class="grow-1 overflow-hidden h-full max-h-screen">
          <DynamicScroller
            v-if="mode === 'products' || mode === 'diff'"
            :key="mode"
            :items="Object.values(mode === 'products' ? nonEmptyProducts : diffProducts).reverse()"
            key-field="product_id"
            class="h-full"
            list-class="h-full"
            :min-item-size="112"
          >
            <template #default="{ item: product, active }">
              <DynamicScrollerItem :item="product" :active="active">
                <ProductCard
                  :key="product.product_id"
                  :product-id="product.product_id"
                  :need-scale="true"
                  @long-press="openProductDetails(product.product_id)"
                >
                  <template #info>
                    <Row
                      v-if="!product.accounting_quantity"
                      data-test="suggest card count row"
                      :label="$gettext('Количество')"
                      :value="countToView(product.product_id, product.count)"
                    />
                    <MultiRow
                      v-else
                      data-test="suggest card count row"
                      :label="$gettext('Количество')"
                      :value="[
                        countToView(product.product_id, product.count),
                        countToView(product.product_id, product.accounting_quantity),
                      ]"
                    />
                  </template>
                </ProductCard>
              </DynamicScrollerItem>
            </template>
          </DynamicScroller>
          <DynamicScroller
            v-if="mode === 'history'"
            :items="allHistorySorted"
            class="h-full"
            list-class="h-full"
            key-field="time"
            :min-item-size="64"
          >
            <template #default="{ item, active }">
              <DynamicScrollerItem :item="item" :active="active">
                <ShelfHistoryItem :key="item.time" class="mx-2" :history-item="item" @delete="deleteItem(item)" />
              </DynamicScrollerItem>
            </template>
          </DynamicScroller>
        </div>
      </div>
      <template v-else>
        <div class="flex flex-col justify-center items-center p-4 h-full">
          <span class="text-3xl font-bold text-center mb-16">
            {{ $gettext('Отсканируйте поштучно все товары, которые лежат на палете') }}
          </span>
          <img class="empty-img" src="@/assets/img/scan.svg" alt="" />
        </div>
      </template>
      <ProductDetails
        v-if="productDetails.visible.value"
        :product-id="productDetails.props.value"
        :stock="products[productDetails.props.value]"
        @cancel="productDetails.hide"
        @input-count="count => productDetails.executeAndHide(saveProductCount, count, productDetails.props.value)"
        @delete-history-item="time => markDeletedHistoryItem(time, productDetails.props.value)"
      />

      <template #footer>
        <div class="flex py-3 px-4">
          <SliderButton
            v-if="!hasScannedProducts"
            data-test="shelf-details empty-shelf-btn"
            @slide-complete="emptyShelf"
          >
            {{ $gettext('На палете нет товаров') }}
          </SliderButton>
          <SliderButton v-else data-test="shelf-details finish-btn" @slide-complete="saveShelf">
            {{ $gettext('Завершить') }}
          </SliderButton>
        </div>
      </template>
    </Layout>
  </Overlay>
</template>

<script setup lang="ts">
import { OrangeIconWarning } from '@/fsd/shared/icons/warning/OrangeIconWarning';
import { Modal } from '@/fsd/shared/tools/modalNotification';
import { useComponent } from '@/hooks/useComponent';
import { useRequestBarcode } from '@/hooks/useRequestBarcode';
import Product from '@/models/Product';
import BaseOrder from '@/models/orders/BaseOrder';
import { useProducts } from '@/store/modules/products';
import { $gettext } from '@/temp/plugins/gettext';
import { Notifications } from '@/temp/plugins/notification';
import Bar from '@/ui/common/bar/bar.vue';
import ToggleButton from '@/ui/common/fields/toggle-button.vue';
import Hint from '@/ui/common/hint/hint.vue';
import Layout from '@/ui/common/layout.vue';
import { useLoader } from '@/ui/common/loader/useLoader';
import type { MenuItemConfig } from '@/ui/common/menu/types';
import Overlay from '@/ui/common/overlay/overlay.vue';
import ProductCard from '@/ui/common/product-card/product-card.vue';
import SliderButton from '@/ui/common/slider-button/slider-button.vue';
import { getCountToView } from '@/ui/common/suggest-card/formatters/count-formatter';
import MultiRow from '@/ui/common/suggest-card/multi-row.vue';
import Row from '@/ui/common/suggest-card/row.vue';
import ProductDetails from '@/ui/control-check/product-details.vue';
import ShelfHistoryItem from '@/ui/control-check/shelf-history-item.vue';
import { scrollToHint } from '@/utils';
import { HistoryItem, HistoryStatusEnum, HistoryTypeEnum, ShelfStock } from '@/views/control_check/types';
import axios from 'axios';
import { computed, defineEmits, defineProps, markRaw, ref } from 'vue';

const { showLoader } = useLoader();
const productsStore = useProducts();
const productDetails = useComponent<string>();

// показываем товары на палете

interface ShelfDetailsProps {
  shelf: string;
  order: BaseOrder;
  stock?: ShelfStock;
}

const props = defineProps<ShelfDetailsProps>();

const emits = defineEmits<{
  (e: 'empty'): void;
  (e: 'finish'): void;
  (e: 'cancel'): void;
  (e: 'changeReport', products: ShelfStock): void;
  (e: 'saveToS3'): void;
}>();

const mode = ref<'products' | 'diff' | 'history'>('products');
const products = ref<ShelfStock>(props.stock || {});

const barText = computed(() => $gettext('Палета %{shelf}', { shelf: props.shelf }));

const hasScannedProducts = computed(() => Object.keys(products.value).length > 0);

const allHistorySorted = computed<HistoryItem[]>(() => {
  const allHistory: HistoryItem[] = Object.keys(products.value).reduce((acc, key) => {
    acc.push(...products.value[key].history);
    return acc;
  }, [] as HistoryItem[]);
  return allHistory.sort((a, b) => b.time - a.time);
});

const nonEmptyProducts = computed<ShelfStock>(() => {
  const result: ShelfStock = {};
  for (const product_id in products.value) {
    const stock = products.value[product_id];
    if (stock.count > 0 || stock.accounting_quantity) {
      result[product_id] = stock;
    }
  }
  return result;
});

const diffProducts = computed<ShelfStock>(() => {
  const result: ShelfStock = {};
  for (const product_id in products.value) {
    const stock = products.value[product_id];
    if (stock.accounting_quantity !== stock.count) {
      result[product_id] = stock;
    }
  }
  return result;
});

const menuConfig = computed<MenuItemConfig[]>(() => [
  {
    buttonText: $gettext('Показать расхождения'),
    onClick: () => {
      showDiff();
    },
    condition: () => Boolean(props.order?.required.length),
  },
  {
    buttonText: $gettext('Сохранить промежуточные результаты'),
    onClick: () => emits('saveToS3'),
  },
]);

const isDiffMode = computed<boolean>(() => mode.value === 'diff');

const hintText = computed(() => {
  if (isDiffMode.value) {
    return $gettext('Досканируйте поштучно оставшиеся товары или выберите товар и откорректируйте его количество');
  }
  return $gettext('Отсканируйте поштучно все товары, которые лежат в палета');
});

const saveProduct = (product_id: string, type: HistoryTypeEnum = HistoryTypeEnum.scan, count: number = 1): void => {
  const productData = products.value[product_id];
  if (!productData) {
    products.value[product_id] = {
      product_id,
      count,
      history: [{ time: Date.now(), count, type: type, status: HistoryStatusEnum.active, product_id }],
    };
  } else {
    productData.count = productData.count + count;
    productData.history.push({ time: Date.now(), count, type, status: HistoryStatusEnum.active, product_id });
    delete products.value[product_id];
    products.value[product_id] = productData;
  }
  emits('changeReport', products.value);
};

const { needBarcodeRequest } = useRequestBarcode(async barcode => {
  const { closeLoader } = showLoader();
  try {
    const product = await productsStore.getProductByBarcode(barcode);
    // обработать весовые. ждем здеь ТОЛЬКО родителя.
    closeLoader();
    if (product.type_accounting === 'weight' && Product.weightFromBarcode(barcode)) {
      // ожидаем, что отсканировали дочерний весовой.
      if (product.parent_id) {
        saveProduct(product.parent_id, HistoryTypeEnum.scan, Product.weightFromBarcode(barcode));
      } else {
        // отсканировали родительский весовой. актуально для бананов
        saveProduct(product.product_id, HistoryTypeEnum.scan, Product.weightFromBarcode(barcode));
      }
    } else {
      saveProduct(product.product_id);
    }
    scrollToHint('#hint');
  } catch (e: any) {
    closeLoader();
    console.error(e);
    if ((axios.isAxiosError(e) && !e.response) || e.message === 'Продукт не был загружен') {
      await Modal.show({
        title: $gettext('Кажется, что у вас нет интернета'),
      });
      return true;
    }
    await Modal.show({
      title: $gettext('Отсканирован неверный штрих-код'),
      text: $gettext('Убедитесь, что вы сканируете штрих-код товара.'),
      component: markRaw(OrangeIconWarning),
    });
    return true;
  }
  return true;
});

const emptyShelf = async (): Promise<void> => {
  const confirmed = await Notifications.confirmBottom({
    title: $gettext('Вы уверены, что на палете нет товаров?'),
  });
  if (!confirmed) return;
  needBarcodeRequest.value = false;
  emits('empty');
};

const saveShelf = async (): Promise<void> => {
  const confirmed = await Notifications.confirmBottom({
    title: $gettext('Завершить проверку комплектации палеты?'),
    text: $gettext('Все товары на палете должны быть отсканированы и корректно подсчитаны.'),
  });
  if (!confirmed) return;
  needBarcodeRequest.value = false;
  emits('finish');
};

const openProductDetails = async (product_id: string): Promise<void> => {
  needBarcodeRequest.value = false;
  await productDetails.asyncShow(product_id);
  needBarcodeRequest.value = true;
};

const saveProductCount = (count: number, activeProduct: string): void => {
  if (!activeProduct) {
    //   error
    return;
  }
  const deltaCount = count - products.value[activeProduct].count;
  saveProduct(activeProduct, HistoryTypeEnum.input, deltaCount);
};

const markDeletedHistoryItem = (time: number, activeProduct?: string): void => {
  if (!activeProduct) {
    return;
  }
  const productData = products.value[activeProduct];
  const history = productData.history;

  const historyItemIdx = history.findIndex(h => h.time === time);
  if (historyItemIdx !== -1 && history[historyItemIdx].status !== HistoryStatusEnum.deleted) {
    history[historyItemIdx].status = HistoryStatusEnum.deleted;
    productData.count = productData.count - history[historyItemIdx].count;
  }
};

const changeListType = (checked: boolean): void => {
  if (checked) {
    mode.value = 'history';
  } else {
    mode.value = 'products';
  }
};

const deleteItem = (historyItem: HistoryItem): void => {
  historyItem.status = HistoryStatusEnum.deleted;
  const productData = products.value[historyItem.product_id];
  productData.count = productData.count - historyItem.count;
};

const showDiff = (): void => {
  mode.value = 'diff';
};
const closeDiff = (): void => {
  mode.value = 'products';
};
const close = (): void => {
  if (isDiffMode.value) {
    closeDiff();
  } else {
    emits('cancel');
  }
};

const countToView = (product_id: string, count: number): string => {
  const product = productsStore.productById(product_id);
  const data: any = { type_accounting: product?.type_accounting };
  if (product?.type_accounting === 'weight') {
    data.weight = count;
  } else {
    data.count = count;
  }

  return getCountToView(data);
};
</script>
