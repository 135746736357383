<template>
  <Layout>
    <div class="bg-day-cardDivider flex flex-col gap-1.5">
      <SortingStage :suggests="suggests" />
    </div>

    <template #footer>
      <LayoutFooter>
        <UiButton data-test="next-stage-btn" @click="$emit('done')">
          {{ $gettext('Далее') }}
        </UiButton>
      </LayoutFooter>
    </template>
  </Layout>
</template>

<script lang="ts">
import SortingStage from '@/fsd/widgets/order-retail/finish/sort/SortingStage.vue';
import Suggest from '@/models/Suggest';
import Layout from '@/ui/common/layout.vue';
import LayoutFooter from '@/ui/common/layout/layout-footer.vue';
import UiButton from '@/ui/common/ui-button.vue';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'SortingStageWrapper',
  components: { LayoutFooter, SortingStage, UiButton, Layout },
  props: {
    suggests: {
      type: Array as PropType<Suggest[]>,
      required: true,
    },
  },
  emits: ['done'],
});
</script>
