<template>
  <Overlay>
    <div class="report-menu-container">
      <Bar :caption="$gettext('Сообщить о проблеме')" :icons="{ left: 'back' }" @close-click="$emit('close')" />
      <Hint class="my-2 mx-4">
        {{ $gettext('Сообщите поддержке о нехватке товаров, заказ будет поставлен на паузу') }}
      </Hint>
      <SuggestCard :suggest="suggest" doc-type="onlyTitle" />
      <div class="info-row">
        <Typography type="text3" class="text-primary" margin="0 0 5px 0">
          {{ $gettext('Номер задания') }}
        </Typography>
        <Typography type="h2" color="primary-text">
          {{ order?.doc_number }}
        </Typography>
      </div>
      <div class="fill" />
      <UiButton
        data-test="order report-menu pause-btn"
        class="my-3 mx-4"
        style="width: auto"
        background-color="secondary"
        @click="reportConfirm"
      >
        {{ $gettext('Написать в чат поддержки') }}
      </UiButton>
    </div>
  </Overlay>
</template>

<script lang="ts">
import { getOrderTitle } from '@/fsd/entities/order/tools/getOrderTitle';
import { callAction } from '@/fsd/shared/ui/action';
import { ActionCounterProps } from '@/fsd/shared/ui/action/types';
import BaseOrder from '@/models/orders/BaseOrder';
import Product from '@/models/Product';
import Suggest from '@/models/Suggest';
import { useProducts } from '@/store/modules/products';
import { ProductInRequired } from '@/types/product';
import Bar from '@/ui/common/bar/bar.vue';
import Hint from '@/ui/common/hint/hint.vue';
import Counter from '@/ui/common/notifications/counter.vue';
import Overlay from '@/ui/common/overlay/overlay.vue';
import { getCountToView } from '@/ui/common/suggest-card/formatters/count-formatter';
import SuggestCard from '@/ui/common/suggest-card/suggest-card.vue';
import Typography from '@/ui/common/typography.vue';
import UiButton from '@/ui/common/ui-button.vue';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  components: {
    Overlay,
    Typography,
    Hint,
    SuggestCard,
    UiButton,
    Bar,
  },
  props: {
    order: {
      type: Object as PropType<BaseOrder>,
      required: true,
    },
    suggest: {
      type: Object as PropType<Suggest>,
      required: true,
    },
    id: {
      type: String,
      default: 'report-menu',
    },
  },
  emits: ['report', 'close'],
  setup() {
    const productsStore = useProducts();

    return { productsStore };
  },
  data() {
    return {
      count: 0,
    };
  },
  computed: {
    product(): Product | undefined {
      return this.productsStore.productById(this.suggest.product_id);
    },
    additionalTitle(): string {
      return getOrderTitle(this.order?.order_id).value;
    },
    maxCount(): number {
      if (this.order.problems.find(suggest => suggest.product_id === this.suggest.product_id)) {
        return this.order.required.find(p => (p as ProductInRequired).product_id === this.suggest.product_id)?.count!;
      }
      return this.suggest.count!;
    },
    reportMessage(): string {
      return this.$gettext(
        `Здравствуйте! Проблема с заказом %{doc_number}, не хватает товара: %{product_title}, %{count}`,
        {
          doc_number: this.order.attr.doc_number,
          product_title: this.product!.title,
          count: getCountToView({
            type_accounting: this.product!.type_accounting,
            count: this.count,
          }),
        },
      );
    },
  },

  methods: {
    async reportConfirm(): Promise<void> {
      if (!this.product) return;
      const confirmed = await this.$notification.confirmBottom({
        title: this.$gettext('Отправить сообщение в чат поддержки?'),
        titleAlign: 'center',
      });
      if (confirmed) {
        this.createReport();
      }
    },
    async createReport(): Promise<void> {
      this.count = await callAction<ActionCounterProps, number>(Counter, {
        title: this.$gettext('Сколько товара не хватает?'),
        text: this.$gettext('Сколько единиц товара, необходимого для заказа, не хватает на полке?'),
        max: this.maxCount,
        suggest: this.suggest as Suggest,
      });
      // пользователь закрыл модалку
      if (!this.count) {
        return;
      }
      this.$emit('report', {
        message: this.reportMessage,
        chatMetaData: {
          order_id: this.order.order_id,
          product_id: this.suggest.product_id,
          doc_number: this.order.external_id,
        },
        count: this.count,
      });
    },
  },
});
</script>

<style lang="scss" scoped>
.report-menu-container {
  margin-left: auto;
  padding: 0;
  height: 100%;
  width: 100vw;
  display: flex;
  background: var(--main-bg);
  box-sizing: border-box;
  flex: 0 0 auto;
  flex-direction: column;

  text-align: left;
  pointer-events: auto;
}

.title-container {
  display: flex;
  margin-top: 20px;
  padding: 20.5px 0;
}

.title {
  font-weight: 500;
  flex-grow: 1;
  font-size: 16px;
  color: #21201f;
  text-align: center;
}

.info-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.fill {
  flex-grow: 1;
}
</style>
