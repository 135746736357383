export enum orderUrls {
  acceptance = 'acceptance',
  acceptance_market = 'acceptance_market',
  control = 'control',
  writeoff = 'writeoff',
  stowage = 'stowage',
  sale_stowage = 'sale_stowage',
  stowage_market = 'stowage_market',
  weight_stowage = 'weight_stowage',
  order = 'order',
  order_retail = 'order_retail',
  recount = 'recount',
  check = 'check',
  check_more = 'check_more',
  inventory_check_more = 'check_more',
  check_product_on_shelf = 'check_product_on_shelf',
  inventory_check_product_on_shelf = 'inventory_check_product_on_shelf',
  refund = 'refund',
  shipment = 'shipment',
  shipment_rollback = 'shipment_rollback',
  visual_control = 'visual_control',
  hand_move = 'hand_move',
  check_final = 'check_final',
  repacking = 'repacking',
  control_check = 'control_check',
  kitchen_provision = 'hand_move',
  robot_provision = 'robot_provision',
}

export const orderTypeUrls = {
  ...orderUrls,
  check_valid_regular: orderUrls.control,
  check_valid_short: orderUrls.control,
  writeoff_prepare_day: orderUrls.control,
  part_refund: orderUrls.refund,
};
