<template>
  <PageLayout :order_id="order_id">
    <template #header>
      <Bar
        :order="order"
        :caption="controlBarCaption"
        :text="controlBarText"
        :menu-config="menuConfig"
        @close-click="toHomePage"
      />
    </template>

    <template #default>
      <div>
        <Hint class="mx-2 my-4">{{ $gettext('Отсканируйте товары и укажите их количество') }}</Hint>
        <SuggestCard
          v-for="suggest in suggests"
          :key="suggest.suggest_id"
          :suggest="suggest"
          :order="order"
          :suggest-menu-config="suggestMenuConfig(suggest)"
          need-menu
          doc-type="check"
          @open-suggest-menu="() => (uiStateNeedBarcodeRequest = false)"
          @close-suggest-menu="() => (uiStateNeedBarcodeRequest = true)"
        />
        <SuggestDetails
          v-if="suggestDetails.visible.value"
          :order-id="order.order_id"
          :suggest-id="suggestDetails.props.value.suggest_id"
          @finish="props => finishActiveSuggest(props, suggestDetails.props.value)"
          @cancel="suggestDetails.hide"
        />
      </div>

      <ScanShelf v-if="scanShelf.visible.value" @scanned="scanShelf.hide" />
      <ShareOrder v-if="order && shareOrder.visible.value" :order="order" @close="shareOrder.hide" />
    </template>

    <template #footer>
      <LayoutFooter>
        <UiButton
          data-test="check finish-btn"
          :disabled="!allSuggestDone || order.estatus === 'waiting_signal'"
          @click="finishOrder"
        >
          {{ $gettext('Завершить') }}
        </UiButton>
      </LayoutFooter>
    </template>
  </PageLayout>
</template>

<script lang="ts">
// данный компонент используется для проведения контрольных пересчетов после инвентаризаций,
// а также для всех автоматически созданных пересчетов
// типы документов: inventory_check_product_on_shelf, check, check_director

import retryCheckModal from '@/fsd/entities/modals/retryCheckModal';
import PageLayout from '@/fsd/entities/page/PageLayout.vue';
import { getClosedPackage, getProductTail } from '@/fsd/entities/product';
import { getRequestSuggests } from '@/fsd/entities/suggest/tools/suggestsFilters';
import { useCheck } from '@/fsd/entities/suggest/tools/useCheck';
import { useDetachFromOrder } from '@/fsd/features/order/utils/useDetachFromOrder';
import { useEndOrder } from '@/fsd/features/order/utils/useEndOrder';
import { useComponent } from '@/hooks/useComponent';
import { useHandleOrderStatus } from '@/hooks/useHandleOrderStatus';
import requestBarcode from '@/mixins/requestBarcode';
import requestProductCode from '@/mixins/requestProductCode';
import Product from '@/models/Product';
import Shelf from '@/models/Shelf';
import Suggest from '@/models/Suggest';
import CheckOrder from '@/models/orders/CheckOrder';
import { useOrders } from '@/store/modules/orders';
import { useProducts } from '@/store/modules/products';
import { useShelves } from '@/store/modules/shelves';
import { useUser } from '@/store/modules/user';
import { experiments } from '@/temp/constants';
import { getQuantUnit } from '@/temp/constants/translations/quantUnits';
import Bar from '@/ui/common/bar/bar.vue';
import Hint from '@/ui/common/hint/hint.vue';
import LayoutFooter from '@/ui/common/layout/layout-footer.vue';
import { useLoader } from '@/ui/common/loader/useLoader';
import type { MenuItemConfig } from '@/ui/common/menu/types';
import ScanShelf from '@/ui/common/scan-shelf/scan-shelf.vue';
import ShareOrder from '@/ui/common/share-order/share-order.vue';
import { getCountToView } from '@/ui/common/suggest-card/formatters/count-formatter';
import SuggestCard from '@/ui/common/suggest-card/suggest-card.vue';
import SuggestDetails from '@/ui/common/suggest-details/suggest-details.vue';
import { Model } from '@/ui/common/suggest-details/types';
import UiButton from '@/ui/common/ui-button.vue';
import { checkConditions } from '@/utils/checkConditions';
import { erSuggestAcceptedCountModal } from '@/utils/modals';
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';

interface Data {
  uiStateNeedBarcodeRequest: boolean;
}

export default defineComponent({
  name: 'Check',
  components: {
    PageLayout,
    LayoutFooter,
    Bar,
    Hint,
    UiButton,
    ScanShelf,
    SuggestCard,
    SuggestDetails,
    ShareOrder,
  },
  mixins: [requestProductCode, requestBarcode],
  props: {
    order_id: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { showLoader } = useLoader();
    const shelvesStore = useShelves();
    const productsStore = useProducts();
    const ordersStore = useOrders();
    const userStore = useUser();
    const shareOrder = useComponent();
    const suggestDetails = useComponent<Suggest>();
    const scanShelf = useComponent<void, Shelf>();
    const router = useRouter();
    const { detachBtn } = useDetachFromOrder(props.order_id);

    useHandleOrderStatus(props.order_id);

    return {
      showLoader,
      shelvesStore,
      productsStore,
      userStore,
      ordersStore,
      shareOrder,
      suggestDetails,
      scanShelf,
      router,
      detachBtn,
    };
  },
  data(): Data {
    return {
      uiStateNeedBarcodeRequest: true,
    };
  },
  computed: {
    order(): CheckOrder | undefined {
      return this.ordersStore.orderById(this.order_id) as any as CheckOrder;
    },
    suggests(): Suggest[] {
      if (!this.order?.suggests) return [];
      return this.order.suggests;
    },
    requestSuggests(): Suggest[] {
      return getRequestSuggests(this.suggests);
    },
    allSuggestDone(): boolean {
      return this.requestSuggests.length === 0;
    },
    suggestMenuConfig(): (suggest: Suggest) => MenuItemConfig[] {
      return suggest => {
        const menuConfig: MenuItemConfig[] = [];
        const noProductBtn: MenuItemConfig = {
          buttonText: this.$gettext('Отметить как отсутствующий'),
          onClick: () => {
            this.onNoProductClick(suggest);
          },
          condition: () => {
            return checkConditions(suggest, 'all', true) && (suggest.status === 'request' || suggest.result_count! > 0);
          },
        };
        menuConfig.push(noProductBtn);

        return menuConfig;
      };
    },

    contractorName(): string {
      return this.order?.contractor || '';
    },
    controlBarCaption(): string {
      return this.$gettext('Контрольный проход');
    },
    controlBarText(): string {
      return this.$gettext('Осталось %{request} из %{all} товаров', {
        request: String(this.requestSuggests.length),
        all: String(this.suggests.length),
      });
    },
    menuConfig(): MenuItemConfig[] {
      const menuConfig: MenuItemConfig[] = [];
      menuConfig.push(this.detachBtn);

      const shareOrderBtn: MenuItemConfig = {
        buttonText: this.$gettext('Разделить задание'),
        onClick: () => this.showShareOrder(),
        condition: () => this.userStore.experimentByName(experiments.exp_tsd_companion),
      };
      menuConfig.push(shareOrderBtn);

      return menuConfig;
    },
  },
  watch: {
    order: {
      handler(newValue) {
        //inventory_check_product_on_shelf специфичная штука
        if (newValue?.estatus === 'waiting_signal') {
          this.$modal.show({
            title: this.$gettext('Подсчет стеллажа завершен'),
            text: this.$gettext('Требуется ручное завершение подсчета в диспетчерской'),
          });
          this.toHomePage();
        }
      },
      deep: true,
    },
  },
  mounted() {
    if (this.order?.estatus === 'waiting_signal') {
      this.$modal.show({
        title: this.$gettext('Подсчет стеллажа завершен'),
        text: this.$gettext('Требуется ручное завершение подсчета в диспетчерской'),
      });
    }
  },
  methods: {
    async requestBarcode(): Promise<boolean> {
      const { product } = await this.requestProductCode({ checkSuggests: true });

      const suggests = this.suggests.filter(p => Product.isProduct(product) && p.product_id === product.product_id);
      if (suggests.length === 1) {
        await this.suggestDetails.asyncShow(suggests[0]);
        return true;
      }
      const shelf = await this.scanShelf.asyncShow();
      if (!shelf) return true;
      const suggest = suggests.find(s => s.shelf_id === shelf.shelf_id);
      // на одну полку и продукт может быть только один саджест
      if (suggest) {
        await this.suggestDetails.asyncShow(suggest);
        return true;
      } else {
        this.$alert.error(this.$gettext('Отсканирована неверная полка'));
        return true;
      }
    },
    toHomePage(): void {
      this.router.push({ name: 'home' });
    },
    async onNoProductClick(suggest: Suggest): Promise<void> {
      const confirmed = await this.$notification.confirmBottom({
        title: this.$gettext('Нет товара?'),
        text: this.$gettext(
          'Посмотрите, пожалуйста, внимательно еще раз и если товара действительно нет, то подтвердите это',
        ),
        ok: this.$gettext('Товар нашелся'),
        decline: this.$gettext('Товара нет'),
      });
      if (!confirmed) {
        await this.finishActiveSuggest({ count: 0 }, suggest);
      }
    },
    async finishActiveSuggest({ count = 0 }: Pick<Model, 'count'>, suggest: Suggest): Promise<void> {
      const product = this.productsStore.productById(suggest.product_id)!;
      const activeShelf = this.shelvesStore.shelfById(suggest.shelf_id)!;
      if (!this.order) {
        return;
      }
      const getTitle = () => {
        switch (true) {
          // целевая полка ПФ и товар квантовый
          case activeShelf.isKitchenShelf && product.quants > 1:
            return this.$ngettext(
              'Вы уверены, что на полке %{shelf} находится %{count} закрытых упаковок и %{tail} %{unit} этого товара?',
              'Вы уверены, что на полке %{shelf} находится %{count} закрытых упаковок и %{tail} %{unit} этого товара?',
              getClosedPackage(product, count),
              {
                count: String(getClosedPackage(product, count)),
                shelf: activeShelf.title,
                tail: String(getProductTail(product, count)),
                unit: getQuantUnit(product.quant_unit, getProductTail(product, count)),
              },
            );
          // Товар квантовый и у него указаны единицы измерения
          case Boolean(product.isProductQuant && product.quant_unit):
            return this.$ngettext(
              'Вы уверены, что на полке %{shelf} находится %{count} упаковок(%{unit_count} %{unit}) этого товара?',
              'Вы уверены, что на полке %{shelf} находится %{count} упаковок(%{unit_count} %{unit}) этого товара?',
              count,
              {
                count: String(count),
                shelf: activeShelf.title,
                unit_count: String(product.quants! * count),
                unit: getQuantUnit(product.quant_unit, 10),
              },
            );
          default:
            return this.$gettext('Вы уверены, что на полке %{shelf} находится %{count} этого товара?', {
              shelf: activeShelf.title,
              count: getCountToView({ count: count, type_accounting: product.type_accounting }),
            });
        }
      };
      const confirmed = await this.$notification.confirmBottom({
        title: getTitle(),
      });
      if (!confirmed) return;

      const result = await useCheck(
        this.order_id,
        {
          suggest_id: suggest.suggest_id,
          count,
        },
        {
          onRequestError: async (e, retry) => {
            const response = e.response;
            switch (response?.data?.code) {
              case 'ER_SUGGEST_ACCEPTED_COUNT':
                await erSuggestAcceptedCountModal();
                break;
              default: {
                const confirmed = await retryCheckModal(e);
                if (!confirmed) return false;
                return retry();
              }
            }
            return false;
          },
        },
      );
      if (!result) return;

      this.suggestDetails.hide();
    },
    async finishOrder(): Promise<void> {
      const confirmed = await this.$notification.confirmBottom({
        title: this.$gettext('Завершить задание?'),
      });
      if (!confirmed) return;
      this.uiStateNeedBarcodeRequest = false;
      const result = await useEndOrder(this.order_id);
      if (result) {
        this.toHomePage();
      } else {
        this.uiStateNeedBarcodeRequest = true;
      }
    },
    async showShareOrder(): Promise<void> {
      this.uiStateNeedBarcodeRequest = false;
      await this.shareOrder.asyncShow();
      this.uiStateNeedBarcodeRequest = true;
    },
  },
});
</script>
