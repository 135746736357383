<template>
  <div
    id="root"
    class="flex flex-col min-h-full bg-black"
    :class="{
      'is-rtl-main': userStore.isRTL,
      'deli-theme': userStore.theme === 'deli',
      'dark-theme': userStore.theme === 'dark',
    }"
  >
    <SimpleLoader v-if="isLoading" />
    <template v-else>
      <StatusBar class="h-5" @toggle-devtools="isVisibleDevtools = !isVisibleDevtools" />

      <div class="relative rounded-2xl h-[calc(100vh-20px)] flex overflow-hidden">
        <RouterView v-show="!scannerStore.isActiveCamera" />
        <Devtools v-if="isVisibleDevtools" />
      </div>

      <QrAim v-if="scannerStore.isActiveCamera" />
    </template>

    <Updater />
    <NotificationsGroup />
    <AlertsGroup />
    <ModalGroup />
    <ActionGroup />
    <Loader />
  </div>
</template>

<script lang="ts" setup>
import Updater from '@/components/updater/updater.vue';
import Devtools from '@/devtools/devtools.vue';
import { checkClientTime } from '@/fsd/data/utils/checkClientTime';
import { useCheckAssignCourier } from '@/fsd/features/order/remainders/useCheckAssignCourier';
import { useCheckReadyMeals } from '@/fsd/features/order/remainders/useCheckReadyMeals';
import { StatusBar } from '@/fsd/features/status-bar';
import { useCheckNetwork } from '@/fsd/features/tools/useCheckNetwork';
import { useWebViewUpdate } from '@/fsd/features/tools/useWebViewUpdate/useWebViewUpdate';
import QrAim from '@/fsd/shared/ui/QrAim/QrAim.vue';
import ActionGroup from '@/fsd/shared/ui/action/ActionGroup.vue';
import AlertsGroup from '@/fsd/shared/ui/notifications/AlertsGroup.vue';
import ModalGroup from '@/fsd/shared/ui/notifications/ModalGroup.vue';
import { SimpleLoader } from '@/fsd/shared/ui/simpleLoader';
import { useUpdates } from '@/hooks/useUpdates';
import { useOrders } from '@/store/modules/orders';
import { useScanner } from '@/store/modules/scanner';
import { useUser } from '@/store/modules/user';
import NotificationsGroup from '@/temp/components/notifications-group.vue';
import Loader from '@/ui/common/loader/loader.vue';
import { initParams } from '@/utils/query-configurator';
import { storeToRefs } from 'pinia';
import { onMounted, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useBranding } from './fsd/features/tools/useBranding';

const isLoading = ref(true);
const isVisibleDevtools = ref(false);
const router = useRouter();
const scannerStore = useScanner();
const ordersStore = useOrders();
const userStore = useUser();
const { getBranding } = useBranding();

// запускаем отслеживание готовой еды. оповещаем сборщика о возможности продолжить сборку
useCheckReadyMeals();
// Запускаем отслеживание назначений курьера для документов с maybe_rover
useCheckAssignCourier();
// Проверяем время на клиенте, как только оно будет получено
checkClientTime();

useWebViewUpdate();

// если пользователь разлогинился, то пушим на страницу логина
const { isAuthenticated } = storeToRefs(userStore);
useCheckNetwork();
watch(isAuthenticated, val => {
  if (!val) router.push('/login');
});

watch(
  () => userStore.storeId,
  (value, oldValue) => {
    if (value && !oldValue) {
      ordersStore.startPolling();
    }
    if (!value && oldValue) {
      ordersStore.stopPolling();
    }
  },
  { immediate: true },
);

onMounted(() => {
  // Запрашиваем брендинг при монтировании
  Promise.allSettled([initParams(), getBranding()]).then(() => {
    isLoading.value = false;
    useUpdates();
  });
});
</script>
