<template>
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.44391 1.89597H11.5956C11.8775 1.89597 12.106 2.12449 12.106 2.40638C12.106 2.68827 11.8775 2.91679 11.5956 2.91679H11.5152C11.5295 2.93971 11.5424 2.96429 11.5537 2.99055L13.537 7.61873C13.8257 8.29234 13.3316 9.04166 12.5987 9.04166H9.57002C8.83714 9.04166 8.34303 8.29229 8.63175 7.61868L10.6154 2.99052C10.6267 2.96427 10.6396 2.9397 10.6539 2.91679H8.44381C8.32187 3.26169 8.07987 3.54986 7.76803 3.73109L7.76803 11.6955H9.35033C9.74498 11.6955 10.0649 12.0154 10.0649 12.4101C10.0649 12.8047 9.74498 13.1247 9.35033 13.1247H7.02762C7.01925 13.1249 7.01085 13.1251 7.00241 13.1251C6.99397 13.1251 6.98557 13.1249 6.9772 13.1247H4.65452C4.25987 13.1247 3.93994 12.8047 3.93994 12.4101C3.93994 12.0154 4.25987 11.6955 4.65452 11.6955H6.23679L6.23679 3.73417C5.92238 3.55313 5.67832 3.26365 5.55568 2.91679H3.34795C3.3623 2.93971 3.3752 2.96429 3.38646 2.99055L5.3698 7.61873C5.65847 8.29234 5.16436 9.04166 4.4315 9.04166H3.93893H1.89526H1.40279C0.669906 9.04166 0.175794 8.29229 0.46451 7.61868L2.44817 2.99052C2.45942 2.96427 2.47232 2.9397 2.48666 2.91679H2.40812C2.12622 2.91679 1.8977 2.68827 1.8977 2.40638C1.8977 2.12449 2.12622 1.89597 2.40812 1.89597H5.55559C5.76576 1.30116 6.33298 0.875 6.99975 0.875C7.66651 0.875 8.23374 1.30116 8.44391 1.89597ZM1.83966 7.00154L2.91727 4.48733L3.99471 7.00155C3.97591 7.00052 3.95698 7 3.93792 7H1.89627C1.87727 7 1.8584 7.00052 1.83966 7.00154ZM11.0845 4.48733L10.007 7.00129C10.0257 7.00028 10.0445 6.99976 10.0634 6.99976H12.105C12.1241 6.99976 12.143 7.00028 12.1618 7.00131L11.0845 4.48733Z"
      :fill="isColor ? colorValue : 'currentColor'"
    />
  </svg>
</template>
<script setup lang="ts">
import { useColor } from '@/fsd/shared/hooks';

const { color, isColor = true } = defineProps<{ color?: string; isColor?: boolean }>();
const { colorValue } = useColor({ color: () => color });
</script>
