<template>
  <Layout>
    <template v-if="!loading" #header>
      <Bar @close-click="toHomePage" />
    </template>
    <template #default>
      <div v-if="!loading" class="flex flex-col gap-2 pb-4">
        <ProductTitle :product_id="product_id" class="mx-4" />
        <ProductMainInfo :product_id="product_id" class="mx-4" />
        <template v-if="available?.length && currentStep === 'src'">
          <Separator />
          <ProductAvailable :product_id="product_id" class="mx-4" />
          <Separator />
          <ProductAvailableTotal :product_id="product_id" class="mx-4" />
        </template>
        <!--Выбрали исходную полку-->
        <template v-if="currentStep !== 'src' && availableOnSrc">
          <Separator />
          <div class="flex flex-col mx-4 gap-6">
            <InfoRow v-if="srcShelf" :title="$gettext('Полка')" :value="srcShelf.title" />
            <InfoRow v-if="availableOnSrc" :title="$gettext('На полке')" :value="getCountView(availableOnSrc.count)" />
            <InfoRow v-if="availableOnSrc" :title="$gettext('Резерв')" :value="getCountView(availableOnSrc.reserved)" />
          </div>
          <Separator v-if="movePayload.count || movePayload.dst_shelf_id" />
          <div class="flex flex-col mx-4 gap-6">
            <InfoRow
              v-if="movePayload.count"
              :title="$gettext('Перемещаем')"
              :value="getCountView(movePayload.count)"
            />
            <InfoRow v-if="destShelf" :title="$gettext('Перемещаем на')" :value="destShelf.title" />
          </div>
        </template>
      </div>
      <div v-else class="h-full flex flex-col items-center justify-center">
        <LoaderIndicator class="mb-4" />
        <body1 class="mb-2">
          {{ $gettext('Загрузка') }}
        </body1>
        <caption1 class="mb-2">
          {{ $gettext('Пожалуйста, подождите') }}
        </caption1>
      </div>
    </template>
    <template v-if="!loading" #footer>
      <LayoutFooter class="flex-col">
        <template v-if="currentStep === 'src'">
          <Hint class="mb-2">
            {{ $gettext('Отсканируйте нужную полку') }}
          </Hint>
          <Counter disabled :value="1" icon-key="next" />
        </template>
        <template v-if="currentStep === 'count'">
          <Hint class="mb-2">
            {{
              product.quant_unit && product.quants > 1
                ? $gettext('Сколько упаковок хотите переместить')
                : $gettext('Сколько товаров хотите переместить')
            }}
          </Hint>
          <Counter
            :min="1"
            :max="max"
            :product_id="product_id"
            :value="max"
            icon-key="next"
            @confirm="value => setCount({ value })"
          />
        </template>
        <template v-if="currentStep === 'dest'">
          <Hint class="mb-2">
            {{ $gettext('Отсканируйте полку на которую перемещаете товар') }}
          </Hint>
          <UiButton disabled>
            {{ $gettext('Завершить') }}
          </UiButton>
        </template>
        <template v-if="currentStep === 'finish'">
          <Hint class="mb-2">
            {{ $gettext('Разместите товар на полке') }}
          </Hint>
          <UiButton data-test="finish move btn" @click="finishMove">
            {{ $gettext('Завершить') }}
          </UiButton>
        </template>
      </LayoutFooter>
    </template>
  </Layout>
</template>

<script lang="ts" setup>
import { api } from '@/fsd/data/api/api.service';
import { useCheckProductResources } from '@/fsd/data/utils/checkResourse';
import Counter from '@/fsd/entities/counter';
import IconCancelForModal from '@/fsd/shared/icons/IconCancelForModal.vue';
import { useRumPage } from '@/fsd/shared/metrics';
import { Alerts } from '@/fsd/shared/tools/alertNotification';
import { Modal } from '@/fsd/shared/tools/modalNotification';
import { ButtonPositionsEnum } from '@/fsd/shared/universalModal';
import InfoRow from '@/fsd/widgets/productCard/InfoRow.vue';
import ProductAvailable from '@/fsd/widgets/productCard/ProductAvailable.vue';
import ProductAvailableTotal from '@/fsd/widgets/productCard/ProductAvailableTotal.vue';
import ProductMainInfo from '@/fsd/widgets/productCard/ProductMainInfo.vue';
import ProductTitle from '@/fsd/widgets/productCard/ProductTitle.vue';
import { useRequestBarcode } from '@/hooks/useRequestBarcode';
import Product from '@/models/Product';
import Shelf from '@/models/Shelf';
import { OrderTypeEnum } from '@/models/orders/BaseOrder';
import shelfQueue from '@/services/queue/shelf-queue';
import { OrderMoveRequest } from '@/services/requests';
import { useProducts } from '@/store/modules/products';
import { useShelves } from '@/store/modules/shelves';
import { useUser } from '@/store/modules/user';
import { experiments, permits } from '@/temp/constants';
import { errorMessages, shelfTypes, successMessages } from '@/temp/constants/translations';
import { getQuantUnit } from '@/temp/constants/translations/quantUnits';
import { $gettext, $ngettext } from '@/temp/plugins/gettext';
import { logger } from '@/temp/plugins/logs';
import { Notifications } from '@/temp/plugins/notification';
import Bar from '@/ui/common/bar/bar.vue';
import Hint from '@/ui/common/hint/hint.vue';
import Layout from '@/ui/common/layout.vue';
import LayoutFooter from '@/ui/common/layout/layout-footer.vue';
import LoaderIndicator from '@/ui/common/loader-indicator.vue';
import { useLoader } from '@/ui/common/loader/useLoader';
import { getCountToView } from '@/ui/common/suggest-card/formatters/count-formatter';
import UiButton from '@/ui/common/ui-button.vue';
import Separator from '@/ui/home/product-card/separator.vue';
import { subscribeOnOrderStatus } from '@/utils/subscribeOnOrder';
import { isAxiosError } from 'axios';
import uuidv1 from 'uuid/v1';
import { computed, markRaw, onMounted, ref, watch } from 'vue';
import { useRouter } from 'vue-router';

type Step = 'src' | 'count' | 'dest' | 'finish';

interface MovePayload {
  count: number;
  src_shelf_id: Shelf['shelf_id'];
  dst_shelf_id: Shelf['shelf_id'];
}

const props = defineProps<{
  product_id: Product['product_id'];
}>();
const { product_id } = props;

const router = useRouter();

const { loading } = useCheckProductResources(product_id);
const { showLoader } = useLoader();

const movePayload = ref<Partial<MovePayload>>({});
const currentStepIndex = ref(0);

useRumPage(loading);

const available = computed(() => {
  return useProducts().availableByProductId(product_id);
});
const availableOnSrc = computed(() => {
  if (!available.value) return;
  return available.value.find(a => a.shelf_id === movePayload.value.src_shelf_id);
});
const srcShelf = computed(() => {
  return useShelves().shelfById(movePayload.value.src_shelf_id);
});
const destShelf = computed(() => {
  return useShelves().shelfById(movePayload.value.dst_shelf_id);
});
const product = computed(() => {
  return useProducts().productById(product_id);
});
const steps = computed(() => {
  const steps: Step[] = [];
  steps.push('src');
  steps.push('count');
  steps.push('dest');
  steps.push('finish');
  return steps;
});
const currentStep = computed(() => {
  return steps.value[currentStepIndex.value];
});
const max = computed(() => {
  if (!availableOnSrc.value || !product.value) return undefined;
  const available = availableOnSrc.value;
  const availableCount = available.is_components ? Math.floor(available.count / available.quants) : available.count;

  if (useUser().isJunior) {
    const maxWeight = useUser().getMaxWeightForOrder(OrderTypeEnum.move);
    const maxCount = Math.floor(maxWeight / product.value.weight);
    return Math.min(maxCount, maxWeight);
  }
  return availableCount;
});
const nextStep = () => {
  if (currentStepIndex.value === steps.value.length - 1) {
    // finishSuggest();
  } else {
    currentStepIndex.value = currentStepIndex.value + 1;
    if (currentStep.value === 'dest') {
      getDestShelf();
    }
  }
};
const toHomePage = () => {
  router.push({ name: 'home' });
};
const setSrcShelf = (shelf_id: Shelf['shelf_id']) => {
  movePayload.value.src_shelf_id = shelf_id;
  nextStep();
};
const setDestShelf = (shelf_id: Shelf['shelf_id']) => {
  movePayload.value.dst_shelf_id = shelf_id;
  nextStep();
};
const setCount = ({ value }: { value: number }) => {
  movePayload.value.count = value;
  nextStep();
};
const checkAvailable = async () => {
  if (!available.value || !available.value.length) {
    //  ошибка, нельзя переместить то, чего нет
    toHomePage();
    return;
  }
  if (available.value.length === 1) {
    //  Остатки есть только на одной полке.
    const shelf_id = available.value[0].shelf_id;
    const shelf = await shelfQueue.load(shelf_id);
    const valid = await validateSrcShelf(shelf);
    if (!valid) {
      toHomePage();
      return;
    }
    setSrcShelf(shelf_id);
    return;
  }
  getSrcShelf();
};
const validateSrcShelf = async (s: Shelf) => {
  if (!product.value) return false;
  if (s.status === 'disabled') {
    await Modal.show({
      title: $gettext('Полка (ячейка), с которой вы берете продукт, отключена'),
    });
    return false;
  }
  if (!['store', 'markdown', 'office', 'kitchen_components', 'kitchen_on_demand', 'review'].includes(s.type)) {
    await Modal.show({
      title: $gettext('С полки %{srcShelf} ничего перенести нельзя', {
        srcShelf: shelfTypes(s.type),
      }),
      component: markRaw(IconCancelForModal),
    });
    return false;
  }
  const availableOnShelf = available.value!.find(a => a.shelf_id === s.shelf_id);
  if (!availableOnShelf) {
    Alerts.error($gettext('Отсканирована неверная полка. На полке отсутствует данный товар.'));
    return false;
  }
  if (availableOnShelf.is_components) {
    if (product.value.quants > availableOnShelf.count) {
      await Modal.show({
        title: $gettext('В остатках количество %{unit} меньше чем в полной упаковке', {
          unit: getQuantUnit(product.value.quant_unit),
        }),
      });
      return false;
    }
  }
  return true;
};
const validateDestShelf = async (dest: Shelf) => {
  const source = srcShelf.value;
  if (!source) {
    logger.error('Перемещение. Не выбрана полка источник при проверке полки назначения');
    await Modal.show({
      title: $gettext('Что-то с сетью, попробуйте ещё раз'),
    });
    toHomePage();
    return false;
  }
  if (source.shelf_id === dest.shelf_id) {
    await Modal.show({
      title: $gettext('Отсканируйте полку на которую перемещаете товар'),
    });
  }
  if (dest.status === 'disabled') {
    await Modal.show({
      title: $gettext('Данная полка (ячейка) отключена'),
    });
    return false;
  }

  const tsd_move_to_markdown = useUser().permitByName(permits.tsd_move_to_markdown);
  const tsd_move_repacking = useUser().permitByName(permits.tsd_move_repacking);
  const exp_illidan = useUser().experimentByName(experiments.exp_illidan);
  // используем разрешительную логику (все, что не разрешено - запрещено)
  // создаем по блоку для каждого типа исходной полки
  // если достаточно базовоо уведомления, то просто выставляем valid  в false, иначе генерируем уведомление на месте и завершаем ф-ю
  // первоначально смотрим по типу исходной полки (обычно условие идет в виде с полки "а" можно перемещать на полки "б", "ц" и тд)
  let valid = false;
  if (source.type === 'store') {
    valid = ['store', 'markdown', 'office', 'kitchen_components', 'repacking', 'review'].includes(dest.type);
  }
  if (source.type === 'markdown') {
    valid = ['store', 'markdown', 'repacking', 'review'].includes(dest.type);
  }
  if (source.type === 'office') {
    valid = ['store', 'office', 'kitchen_components', 'repacking', 'review'].includes(dest.type);
  }
  if (source.type === 'kitchen_components') {
    valid = ['kitchen_components', 'store'].includes(dest.type);
  }
  if (source.type === 'kitchen_on_demand') {
    valid = ['kitchen_on_demand', 'review'].includes(dest.type);
  }
  if (source.type === 'review') {
    valid = ['store', 'markdown', 'office', 'kitchen_components', 'kitchen_on_demand', 'review'].includes(dest.type);
  }

  // затем применяем условия по пермитам, экспериментам и тд
  if (dest.type === 'markdown' && !tsd_move_to_markdown) {
    // Если пытаемся вручную переместить товар на полку уценки
    valid = false;
  }
  if (dest.type === 'repacking' && !tsd_move_repacking) {
    // запрещаем перемещение на маркдаун, если нет пермита
    Alerts.error($gettext('Недостаточно прав для перемещения по полку перефасовки. Поднимите роль'));
    return false;
  }
  if (source.type === 'office' && dest.type === 'store' && exp_illidan) {
    // запрещаем перемещение с офис на стор, если нет эксперимента
    valid = false;
  }
  if (source.type === 'store' && dest.type === 'office' && exp_illidan) {
    // запрещаем перемещение со стора на офис, если нет эксперимента
    valid = false;
  }

  if (!valid) {
    await Modal.show({
      title: $gettext('С полки %{srcShelf} перенести на %{destShelf} нельзя', {
        srcShelf: shelfTypes(source.type),
        destShelf: shelfTypes(dest.type),
      }),
      component: markRaw(IconCancelForModal),
    });
    return false;
  }
  return true;
};
const getSrcShelf = () => {
  useRequestBarcode(async barcode => {
    const { closeLoader } = showLoader($gettext('Ищем полку по ШК'));
    try {
      await useShelves().getShelfByBarcode(barcode);
      closeLoader();
    } catch (error) {
      closeLoader();
      Alerts.error($gettext('Не найден штрихкод'));
      return true;
    }
    const shelf = useShelves().shelfByBarcode(barcode);
    if (!shelf) {
      Alerts.error($gettext('Не найден штрихкод'));
      return true;
    }
    const valid = await validateSrcShelf(shelf);
    if (!valid) return true;
    setSrcShelf(shelf.shelf_id);
    return false;
  });
};
const getDestShelf = () => {
  useRequestBarcode(async barcode => {
    const { closeLoader } = showLoader($gettext('Ищем полку по ШК'));
    try {
      await useShelves().getShelfByBarcode(barcode);
      closeLoader();
    } catch (error) {
      closeLoader();
      Alerts.error($gettext('Не найден штрихкод'));
      return true;
    }
    const shelf = useShelves().shelfByBarcode(barcode);
    if (!shelf) {
      Alerts.error($gettext('Не найден штрихкод'));
      return true;
    }
    const valid = await validateDestShelf(shelf);
    if (!valid) return true;
    setDestShelf(shelf.shelf_id);
    return false;
  });
};

const finishMove = async () => {
  if (!availableOnSrc.value || !product.value) {
    return;
  }
  const count = movePayload.value.count!;
  const dest = destShelf.value!;
  // вводим всегда целые пачки, а перемещаем в том формате в котором товар хранится.

  let title = '';
  if (product.value.quants > 1 && product.value.quant_unit) {
    title = $ngettext(
      'Вы уверены, что разместили %{count} упаковок этого товара(%{unit_count} %{unit}) на полке %{shelf}?',
      'Вы уверены, что разместили %{count} упаковок этого товара(%{unit_count} %{unit}) на полке %{shelf}?',
      count,
      {
        count: String(count),
        shelf: dest.title,
        unit_count: String(count * product.value.quants),
        unit: getQuantUnit(product.value.quant_unit),
      },
    );
  } else {
    title = $gettext('Вы уверены, что разместили %{count} этого товара на полке %{shelf}?', {
      count: getCountToView({ count, type_accounting: product.value.type_accounting }),
      shelf: dest.title,
    });
  }

  const confirmed = await Notifications.confirmBottom({ title });
  if (!confirmed) return;
  createMove();
};
const createMove = async () => {
  if (!availableOnSrc.value || !product.value) return;

  const count = movePayload.value.count!;
  // вводим всегда целые пачки, а перемещаем в том формате в котором товар хранится.
  const countForMove = availableOnSrc.value.is_components ? count * product.value.quants : count;

  const payload: OrderMoveRequest = {
    order_id: uuidv1(),
    move: [
      {
        product_id: props.product_id,
        count: countForMove,
        src_shelf_id: movePayload.value.src_shelf_id!,
        dst_shelf_id: movePayload.value.dst_shelf_id!,
      },
    ],
  };
  const { closeLoader } = showLoader($gettext('Выполняем перемещение товаров'));
  try {
    const { data } = await api.order.move(payload);
    const order_id = data.order.order_id;
    const cbSuc = (data, unSub) => {
      unSub();
      closeLoader();
      Alerts.success(successMessages.SUC_MOVING);
      toHomePage();
    };
    const cbFail = (data, unSub) => {
      unSub();
      closeLoader();
      Modal.show({ title: errorMessages.ER_MOVING, text: errorMessages.ER_MOVING_DETAILS });
      toHomePage();
    };
    subscribeOnOrderStatus({ order_id, cbSuc, cbFail });
  } catch (error: unknown) {
    closeLoader();
    if (isAxiosError(error)) {
      const response = error.response;
      switch (response?.data.details?.errors[0]?.code) {
        case 'ER_NOT_QUANTIZED': {
          Modal.show({
            title: $gettext('Запрещено перемещать товары без "Порций" на кухонные полки'),
          });
          break;
        }
        case 'ER_NOT_WHOLE_PACK': {
          Modal.show({
            title: $gettext('Запрещено перемещать нецелые пачки'),
          });
          break;
        }
        case 'ER_WRONG_DST_SHELF': {
          Modal.show({
            title: $gettext('Невозможно переместить товар на %{destShelf}', {
              destShelf: destShelf.value ? shelfTypes(destShelf.value.type) : '-',
            }),
          });
          break;
        }
        case 'ER_COUNT_OR_RESERVE': {
          Modal.show({
            title: $gettext('Нельзя переместить товар'),
            text:
              $gettext('Товар зарезервирован или вы пытаетесь переместить больше, чем есть на остатках') +
              '\nER_COUNT_OR_RESERVE',
          });
          break;
        }
        default: {
          const confirm = await Modal.show({
            title: $gettext('Не удалось переместить товар'),
            text: `code: ${response?.status} \nmessage: ${response?.data?.message || ''}`,
            btnPosition: ButtonPositionsEnum.horizontal,
            confirmBtnTitle: $gettext('Повторить'),
            closeBtnTitle: $gettext('Отмена'),
          });
          if (confirm) createMove();
        }
      }
    } else {
      Modal.show({
        title: $gettext('Что-то с сетью, попробуйте ещё раз'),
        text: String(error),
      });
      toHomePage();
    }
  }
};
const getCountView = (count: number) => {
  if (!product.value) return '';
  return getCountToView({
    type_accounting: product.value.type_accounting,
    count,
  });
};

onMounted(() => {
  if (loading.value) {
    const unWatchLoading = watch(loading, l => {
      if (l) return;
      unWatchLoading();
      //   ждем завершения загрузки
      checkAvailable();
    });
  } else {
    checkAvailable();
  }
});
</script>
