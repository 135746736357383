import { TagsEnum } from '@/models/Product';
import { BaseModel } from 'sjs-base-model';

export enum ShelfTypeEnum {
  store = 'store',
  incoming = 'incoming',
  out = 'out',
  trash = 'trash',
  acceptance = 'acceptance',
  markdown = 'markdown',
  office = 'office',
  kitchen_on_demand = 'kitchen_on_demand',
  parcel = 'parcel',
  kitchen_components = 'kitchen_components',
  kitchen_trash = 'kitchen_trash',
  repacking = 'repacking',
  review = 'review',
  parcel_returned = 'parcel_returned',
  store_robot = 'store_robot',
}

export default class Shelf extends BaseModel {
  public shelf_id: string = '';
  public store_id: string = '';
  public external_id: string = '';
  public title: string = '';
  public type: ShelfTypeEnum = ShelfTypeEnum.store;
  public order: number = 0;
  public rack: string | null = null;
  public tags: TagsEnum[] = [];
  public created: string = '';
  public updated: string = '';
  public serial: number = 0;
  public barcode: string = '';
  public status: 'active' | 'disabled' = 'active';

  constructor(data: any) {
    super({ expand: true });
    this.update(data);
  }

  //Полка для кухни
  get isKitchenShelf(): boolean {
    return ['kitchen_components', 'kitchen_trash'].includes(this?.type);
  }

  static isShelf(s: unknown): s is Shelf {
    return s instanceof Shelf;
  }
}
