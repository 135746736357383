import { get } from 'lodash';
import { computed, MaybeRefOrGetter, toValue } from 'vue';
import config from '../../../../tailwind.config';

interface Colors {
  color?: MaybeRefOrGetter<string | undefined>;
  bgColor?: MaybeRefOrGetter<string | undefined>;
}

export const useColor = ({ color, bgColor }: Colors) => {
  const getColor = (path: string | string[] = '') => {
    const colors = config.theme.extend.colors;
    const currentColorValue = get(colors, path);
    return currentColorValue || colors.warmGray['600'];
  };

  const colorClass = computed(() => `text-${toValue(color)}`);

  const colorValue = computed(() => {
    const path = toValue(color)?.split('-');
    return getColor(path);
  });

  const bgColorClass = computed(() => `bg-${color}`);

  const bgColorValue = computed(() => {
    const path = toValue(bgColor)?.split('-');
    return getColor(path);
  });

  return { colorClass, colorValue, bgColorClass, bgColorValue };
};
