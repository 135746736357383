<template>
  <Layout>
    <template #header>
      <Bar
        :caption="$gettext('Отсканируйте марку')"
        :menu-config="[]"
        :icons="{ left: 'back' }"
        @close-click="toHomePage"
      />
    </template>

    <IconTrueMark class="w-4/5 h-4/5 mx-auto" />
  </Layout>
</template>

<script lang="ts" setup>
import { Modal } from '@/fsd/shared/tools/modalNotification';
import { useRequestBarcode } from '@/hooks/useRequestBarcode';
import IconTrueMark from '@/temp/icons/icon-true-mark.vue';
import { $gettext } from '@/temp/plugins/gettext';
import { TrueMark } from '@/temp/services/true-mark';
import Bar from '@/ui/common/bar/bar.vue';
import Layout from '@/ui/common/layout.vue';
import { useLoader } from '@/ui/common/loader/useLoader';
import { useRouter } from 'vue-router';

const { showLoader } = useLoader();
const router = useRouter();

const toHomePage = () => {
  router.push({ name: 'home' });
};

useRequestBarcode(async true_mark => {
  const { closeLoader } = showLoader();

  try {
    await TrueMark.check({ true_mark });
    closeLoader();
    await Modal.show({ title: $gettext('Марка валидна') });
  } catch {
    closeLoader();
  }
});
</script>
