<template>
  <Overlay>
    <div class="bg-main flex h-full pointer-events-auto">
      <Layout>
        <template #header>
          <Bar :caption="$gettext('Настройки')" :icons="{ left: 'back' }" :menu-config="[]" @close-click="toHomePage" />
        </template>
        <template #default>
          <Language />
          <LanguageData v-if="isDataLangEnable" />
          <Role :upgrade="upgrade" />
          <Theme v-if="needTheme" />
          <PingIndicators />
          <AppUpdater v-if="showAppUpdater" />
        </template>
        <template #footer>
          <div class="m-3 my-4">
            <UiButton background-color="secondary" @click="toHomePage">
              {{ $gettext('Назад') }}
            </UiButton>
            <div class="flex flex-col items-center mt-3">
              <VersionText />
              <Caption2 color="day-textMinor" class="text-center">
                {{ date }}
                {{ userAgent }}
              </Caption2>
            </div>
          </div>
        </template>
      </Layout>
    </div>
  </Overlay>
</template>

<script lang="ts">
import AppUpdater from '@/components/updater/app-updater.vue';
import { useReactiveDate } from '@/hooks/useReactiveDate';
import { ModeService } from '@/services/mode.service';
import { useUser } from '@/store/modules/user';
import { experiments } from '@/temp/constants';
import Bar from '@/ui/common/bar/bar.vue';
import Layout from '@/ui/common/layout.vue';
import Overlay from '@/ui/common/overlay/overlay.vue';
import Caption2 from '@/ui/common/typo/caption-2.vue';
import UiButton from '@/ui/common/ui-button.vue';
import LanguageData from '@/ui/home/settings/options/LanguageData.vue';
import Language from '@/ui/home/settings/options/language.vue';
import PingIndicators from '@/ui/home/settings/options/ping-indicators.vue';
import Role from '@/ui/home/settings/options/role.vue';
import Theme from '@/ui/home/settings/options/theme.vue';
import VersionText from '@/ui/home/version/version-text.vue';
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'Settings',
  components: {
    LanguageData,
    Overlay,
    Caption2,
    AppUpdater,
    Bar,
    Layout,
    UiButton,
    Language,
    Role,
    Theme,
    PingIndicators,
    VersionText,
  },
  props: {
    upgrade: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const userStore = useUser();
    const date = useReactiveDate();
    const router = useRouter();

    return { userStore, date, router };
  },
  computed: {
    needTheme(): boolean {
      return !ModeService.isProduction();
    },
    currentVersion() {
      return this.userStore.apkVersion;
    },
    // Новая версия apk
    apkNewVersion(): string {
      return ModeService.isProduction() ? this.userStore.appVersion : this.userStore.packmanVersion;
    },
    showAppUpdater(): boolean {
      return false;
      // if (!this.currentVersion?.length) {
      //   return false;
      // }
      // return this.currentVersion !== this.apkNewVersion;
    },
    userAgent(): string {
      return window.navigator.userAgent;
    },
    isDataLangEnable(): boolean {
      return this.userStore.experimentByName(experiments.exp_separate_data_lang);
    },
  },
  async mounted() {
    await this.userStore.getApkVersion();
  },
  methods: {
    toHomePage(): void {
      this.router.push({ name: 'home' });
    },
  },
});
</script>
