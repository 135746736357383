<template>
  <PageLayout :order_id="order_id">
    <template #header>
      <OrderHeader
        ref="header"
        :order_id="order_id"
        :suggest="activeSuggest"
        :collected="collected[activeSuggest.suggest_id]"
        @start-barcode-request="startBarcodeRequest"
        @stop-barcode-request="stopBarcodeRequest"
      />
      <ShelvesChain
        v-if="exp_untouchable"
        :suggests="suggestsForCollect"
        :index="activeSuggestIndex"
        @choose-suggest="index => (activeSuggestIndex = index)"
      />
    </template>
    <template #default>
      <!-- Сборка позиций  -->
      <template v-if="!isOrderDone">
        <RequiredCardWrapper
          :suggests="suggestsForCollect"
          :active-suggest-index="activeSuggestIndex"
          :order_id="order_id"
          :collected="collected"
          @finish="completeActiveSuggest"
          @input-sherlock-count="checkNeedReportAfterSherlock"
          @change-active-suggest="index => (activeSuggestIndex = index)"
        />
      </template>
      <FinishOrderCard
        v-if="isOrderDone"
        :order_id="order_id"
        :suggests="suggestsForCollect"
        :packing="order.packingSuggests"
        @done="finishOrder"
      />
    </template>
  </PageLayout>
</template>

<script setup lang="ts">
import PageLayout from '@/fsd/entities/page/PageLayout.vue';
import { useCheckBlockedSuggests } from '@/fsd/features/order/remainders/useCheckReadyMeals';
import { useEndOrder } from '@/fsd/features/order/utils/useEndOrder';
import { Alerts } from '@/fsd/shared/tools/alertNotification';
import RequiredCardWrapper from '@/fsd/widgets/order/RequiredCard/RequiredCardWrapper.vue';
import FinishOrderCard from '@/fsd/widgets/order/finish-order/finish-order-card.vue';
import { useRUMOrderFinishing } from '@/fsd/widgets/order/finish-order/tools/useMeasureOrderFinish';
import ShelvesChain from '@/fsd/widgets/order/order-with-shelves-chain/shelves-chain.vue';
import { useHandleOrderStatus } from '@/hooks/useHandleOrderStatus';
import { OrderTargetEnum } from '@/models/orders/BaseOrder';
import OrderOrder from '@/models/orders/OrderOrder';
import { useOrders } from '@/store/modules/orders';
import { useUser } from '@/store/modules/user';
import { $gettext } from '@/temp/plugins/gettext';
import { logger } from '@/temp/plugins/logs';
import { deleteItem } from '@/utils/localStorageHelper';
import OrderHeader from '@/views/Order/OrderHeader.vue';
import { useCollectOrder } from '@/views/Order/useCollectOrder';
import { computed, onUnmounted, ref, watch } from 'vue';
import { useRouter } from 'vue-router';

interface Props {
  order_id: string;
}

const props = defineProps<Props>();
const router = useRouter();

const header = ref<InstanceType<typeof OrderHeader>>();

const { startOrderFinishing, finishOrderFinishing } = useRUMOrderFinishing();

const {
  suggestsForCollect,
  activeSuggestIndex,
  activeSuggest,
  collected,
  completeActiveSuggest,
  startBarcodeRequest,
  stopBarcodeRequest,
} = useCollectOrder(props.order_id);

useCheckBlockedSuggests(props.order_id);

useHandleOrderStatus(props.order_id, {
  cancel: () => {
    activeSuggestIndex.value = 0;
  },
});

const exp_untouchable = computed(() => {
  return useUser().experimentByName('exp_untouchable');
});

const order = computed<OrderOrder | undefined>(() => {
  return useOrders().orderById(props.order_id) as OrderOrder;
});

const isOrderDone = computed<boolean>(() => {
  if (order.value?.target !== OrderTargetEnum.complete) return false;
  return suggestsForCollect.value.every(s => s.isCompleted);
});

const toHomePage = (): Promise<any> => {
  return router.push({ name: 'home' });
};

const finishOrder = async (): Promise<void> => {
  if (!order.value) {
    return toHomePage();
  }
  if (order.value.isOrderPaused) {
    Alerts.error($gettext('Данный заказ обрабатывается оператором, пожалуйста подождите'));
    return;
  }
  try {
    const orderId = props.order_id;
    const result = await useEndOrder(props.order_id);
    if (!result) {
      return;
    }
    deleteItem('roverScan', orderId);
    await toHomePage();
  } catch (error) {
    logger.error(error, { method: 'finishOrder', type: 'order' });
  }
};

const checkNeedReportAfterSherlock = (count): void => {
  // Если на полке НЕ учетное кол-во и товара может не хватать для сборки
  if (count < activeSuggest.value.count!) {
    header.value?.showReportMenu();
  }
  startBarcodeRequest();
};

watch(isOrderDone, val => {
  val && startOrderFinishing();
});

// После размонтирования компонента останавливаем метрики
onUnmounted(() => {
  isOrderDone.value && finishOrderFinishing();
});
</script>
