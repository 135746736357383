<template>
  <svg viewBox="0 0 288 56" fill="none" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg">
    <mask
      id="mask0_6064_102785"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="288"
      height="56"
    >
      <rect width="288" height="56" rx="16" fill="#FAF9F7" />
    </mask>
    <g mask="url(#mask0_6064_102785)">
      <!--    основной фон  -->
      <rect width="288" height="56" rx="16" :fill="bgColor" />
      <!--    квадраты по краям  -->
      <rect
        x="216"
        y="-26.158"
        width="77.6454"
        height="77.6454"
        rx="20"
        transform="rotate(-21.8056 216 -26.158)"
        :fill="earsColor"
      />
      <rect
        x="62.5791"
        y="27.9818"
        width="83.2835"
        height="83.2835"
        rx="20"
        transform="rotate(117.909 62.5791 27.9818)"
        :fill="earsColor"
      />
    </g>
  </svg>
</template>
<script setup lang="ts">
import { ThemeEnum, useUser } from '@/store/modules/user';
import { computed } from 'vue';

interface HintBgProps {
  customBgColor?: string;
  customEarsColor?: string;
}

const { customBgColor, customEarsColor } = defineProps<HintBgProps>();

const userStore = useUser();

const theme = computed(() => {
  return userStore.theme;
});

const bgColor = computed(() => {
  if (customBgColor) return customBgColor;
  switch (theme.value) {
    case ThemeEnum.main:
      return '#FFE7C4';
    case ThemeEnum.deli:
      return '#F3F5FE';
    case ThemeEnum.dark:
      return '#3B3E43';
    default:
      return '#FFE7C4';
  }
});

const earsColor = computed(() => {
  if (customEarsColor) return customEarsColor;
  switch (theme.value) {
    case ThemeEnum.main:
      return '#FDEFE5';
    case ThemeEnum.deli:
      return '#E7EAFB';
    case ThemeEnum.dark:
      return '#34373B';
    default:
      return '#FFE6C4';
  }
});
</script>
