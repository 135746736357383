<template>
  <div data-test="suggest card">
    <ScaleContainer
      v-if="!uiState.isProductLoading"
      :class="{
        'is-rtl': isRTL,
      }"
      :need-scale="needMenu"
      class="py-1 px-4"
      @long-press="openSuggestMenu"
    >
      <div class="product">
        <div class="picture">
          <ImageWrapper :img-src="imageSrc" :luminosity="isGrayscale" :grayscale="isGrayscale">
            <div v-if="showCompleteStatus && completeState" class="picture-overlay">
              <div class="badge" :class="completeState" :data-test="`suggest-card badge ${completeState}`">
                <div class="badge-text">
                  {{ badgeText }}
                </div>
              </div>
            </div>
          </ImageWrapper>
        </div>

        <div class="info">
          <Typography color="primary-text" type="h4" data-test="suggest-card title">
            {{ title }}
          </Typography>
          <div class="flex flex-wrap">
            <Tag
              v-if="['acceptance', 'sale_stowage'].includes(order?.type) && product?.fragile"
              data-test="tag fragile-tag"
              bg-color="purple-high"
              color="purple-dark"
              bg-opacity="40"
              class="mr-2 mb-1"
            >
              {{ $gettext('хрупкий товар') }}
            </Tag>
            <Tag
              v-if="['acceptance', 'sale_stowage'].includes(order?.type) && product?.true_mark"
              data-test="tag true-mark-tag"
              bg-color="blue-toxic"
              color="white"
              bg-opacity="40"
              class="mr-2 mb-1"
            >
              {{ $gettext('Честный знак') }}
            </Tag>
            <Tag
              v-if="['acceptance', 'sale_stowage'].includes(order?.type) && product?.isTrueWeight"
              data-test="tag true-weight-tag"
              bg-color="warmGray-550"
              color="white"
              class="mb-1"
            >
              {{ $gettext('Весовой') }}
            </Tag>
          </div>
          <Rows
            class="text-primary"
            :suggest="suggest"
            :doc-type="docType"
            :order="order"
            :available-product="availableProduct || availableItem"
            :collected="collected"
          />
        </div>
      </div>
    </ScaleContainer>
    <Teleport to="#root">
      <SuggestMenu
        v-if="suggestMenu.visible.value"
        :title="title"
        :subtitle="collectedCountSubtitle"
        :menu-config="suggestMenuConfig"
        @close="() => suggestMenu.executeAndHide($emit, 'close-suggest-menu')"
    /></Teleport>
  </div>
</template>

<script lang="ts">
import Tag from '@/fsd/shared/ui/tag/tag.vue';
import { useComponent } from '@/hooks/useComponent';
import Item, { AvailableItem } from '@/models/Item';
import BaseOrder from '@/models/orders/BaseOrder';
import Product from '@/models/Product';
import Suggest from '@/models/Suggest';
import { useItems } from '@/store/modules/items';
import { useProducts } from '@/store/modules/products';
import { useShelves } from '@/store/modules/shelves';
import { useUser } from '@/store/modules/user';
import { DocTypes } from '@/temp/constants/translations/types';
import { $gettext } from '@/temp/plugins/gettext';
import { AvailableProduct } from '@/types/product';
import ImageWrapper from '@/ui/common/image-wrapper.vue';
import SuggestMenu from '@/ui/common/menu/suggest-menu.vue';
import type { MenuItemConfig } from '@/ui/common/menu/types';
import ScaleContainer from '@/ui/common/scale-container/scale-container.vue';
import Rows from '@/ui/common/suggest-card/data-rows/rows.vue';
import Typography from '@/ui/common/typography.vue';
import { defineComponent, PropType } from 'vue';

interface Data {
  uiState: {
    isProductLoading: boolean;
  };
}
// тип отображаемой сущности
type SuggestCardType = 'suggest' | 'item' | 'product';

// глобально, это место, где мы показываем поле. может быть типом документа или каким то специфичным названием.
// в зависимости от этого поля мы определяем, что должно быть показано и с каким лейблом.

export default defineComponent({
  components: {
    Tag,
    ScaleContainer,
    Typography,
    ImageWrapper,
    SuggestMenu,
    Rows,
  },
  props: {
    order: {
      type: Object as PropType<BaseOrder>,
      default: undefined,
    },
    cardType: {
      type: String as PropType<SuggestCardType>,
      default: 'suggest',
    },
    docType: {
      type: String as PropType<`${DocTypes}`>,
      default: undefined,
    },
    suggest: {
      type: Object as PropType<Suggest>,
      default: undefined,
    },
    availableProduct: {
      type: Object as PropType<AvailableProduct>,
      default: undefined,
    },
    availableItem: {
      type: Object as PropType<AvailableItem>,
      default: undefined,
    },
    needMenu: {
      type: Boolean,
      default: false,
    },
    collected: {
      type: Number,
      default: 0,
    },
    suggestMenuConfig: {
      type: Array as PropType<MenuItemConfig[]>,
      default: () => [],
    },
  },
  emits: ['open-suggest-menu', 'close-suggest-menu'],
  setup() {
    const shelvesStore = useShelves();
    const itemsStore = useItems();
    const productsStore = useProducts();
    const userStore = useUser();
    const suggestMenu = useComponent();

    return { shelvesStore, itemsStore, productsStore, userStore, suggestMenu };
  },
  data(): Data {
    return {
      uiState: {
        isProductLoading: true,
      },
    };
  },
  computed: {
    isRTL(): boolean {
      return this.userStore.isRTL;
    },
    title(): string {
      if (this.cardType === 'item') {
        return this.item?.title || '';
      }
      if (this.cardType === 'product') {
        return this.product?.title || '';
      }
      if (this.suggest?.vars?.mode === 'item') {
        return this.item?.title || '';
      }
      return this.product?.title || '';
    },
    isGrayscale(): boolean {
      if (!this.docType || ['pack', 'check_more', 'inventory_check_more'].includes(this.docType)) {
        return false;
      }
      return this.isComplete;
    },
    showCompleteStatus(): boolean {
      if (this.suggest && this.suggest.status === 'done' && this.docType) {
        return [
          'stowage',
          'sale_stowage',
          'weight_stowage',
          'acceptance',
          'refund',
          'part_refund',
          'writeoff_prepare_day',
          'check_valid_regular',
          'check_valid_short',
          'writeoff',
          'inventory_check_product_on_shelf',
          'shipment',
          'hand_move',
          'kitchen_provision',
          'robot_provision',
          'visual_control',
          'shipment_rollback',
          'check',
          'repacking',
        ].includes(this.docType);
      }
      return false;
    },
    isComplete(): boolean {
      if (this.suggest) {
        return this.suggest.status === 'done';
      }
      return false;
    },
    product(): Product | undefined {
      if (this.cardType === 'item') {
        return undefined;
      }
      if (this.availableProduct) {
        return this.productsStore.productById(this.availableProduct.product_id);
      }
      return this.productsStore.productById(this.suggest?.product_id);
    },
    item(): Item | undefined {
      if (this.suggest?.vars?.mode === 'item') {
        return this.itemsStore.itemById(this.suggest.product_id);
      }
      if (this.cardType === 'item') {
        return this.itemsStore.itemById(this.availableItem?.product_id);
      }
      return undefined;
    },
    completeState(): 'orange' | 'red' | 'green' | 'sale' | '' {
      if (!this.suggest || !this.order) return '';
      if (this.docType === 'sale_stowage' && this.suggest?.result_count && this.suggest.vars.stage !== 'trash') {
        return 'sale';
      }
      //Показываем в КСГ зеленую плашку на готовых заданиях
      if (
        (this.isControl || ['visual_control', 'repacking'].includes(this.order!.type)) &&
        this.suggest.status === 'done'
      ) {
        return 'green';
      }

      if (this.suggest.result_count === 0 && this.suggest.status === 'done') {
        return 'red';
      }
      if (this.suggest.status === 'done' && this.suggest.result_count === this.suggest.count) {
        return 'green';
      }
      if (
        this.suggest.result_count &&
        this.suggest.result_count > 0 &&
        this.suggest.result_count < this.suggest.count!
      ) {
        return 'orange';
      }

      return 'green';
    },
    isControl(): boolean {
      if (!this.order) {
        return false;
      }
      return ['writeoff_prepare_day', 'check_valid_regular', 'check_valid_short'].includes(this.order.type);
    },
    componentCount(): number {
      if (this.availableProduct) {
        if (this.availableProduct.is_components) {
          return Math.ceil(this.availableProduct.count / this.availableProduct.quants);
        }
        return this.availableProduct.count;
      }
      return 0;
    },
    badgeText(): string {
      const badgeTexts = {
        sale: this.$gettext('В продаже'),
        green: this.$gettext('Готово'),
        orange: this.$gettext('Почти'),
        red: this.$gettext('Нет'),
      };
      return badgeTexts[this.completeState];
    },
    imageSrc(): string {
      if (this.cardType === 'item') {
        if (this.item) return this.item.imgSrc;
      }
      if (this.cardType === 'product') {
        return this.product?.images[0] || 'empty';
      }
      // @ts-expect-error pinia
      return this.suggest?.imageSrc;
    },
    collectedCountSubtitle(): string {
      return $gettext('Количество %{n} из %{all} шт.', {
        n: String(this.collected),
        all: String(this.suggest?.count),
      });
    },
  },
  async mounted(): Promise<void> {
    if (this.cardType === 'item') {
      if (this.availableItem) {
        this.uiState.isProductLoading = false;
      }
      return;
    }
    if (!this.suggest) {
      if (this.availableProduct) {
        this.uiState.isProductLoading = false;
      }
      return;
    }
    if (!this.suggest.vars) {
      if (this.suggest.product_id) {
        await this.productsStore.getProductById(this.suggest.product_id);
      }
      this.uiState.isProductLoading = false;
      return;
    }
    if (!this.suggest.vars?.mode || this.suggest.vars.mode === 'product') {
      const product = await this.productsStore.getProductById(this.suggest.product_id);
      // грузим available, нужно для отображения в поле кол-ва
      if (this.docType === 'check') {
        try {
          // @ts-expect-error pinia product был any
          await this.productsStore.fetchAvailable(product.product_id);
          await this.shelvesStore.fetchAvailable(this.suggest.shelf_id);
        } catch (error) {
          console.error(error);
        }
        const available = this.productsStore.availableByProductId(product?.product_id);
        if (!available) {
          this.uiState.isProductLoading = false;
          return;
        }
        const shelvesId = available.map(item => item.shelf_id);
        if (shelvesId.length === 0) {
          this.uiState.isProductLoading = false;
          return;
        }
        await this.shelvesStore.getShelvesByIds(shelvesId);
        this.uiState.isProductLoading = false;
      }
    } else {
      await this.itemsStore.getItemById(this.suggest.product_id);
    }
    if (this.suggest.shelf_id) {
      await this.shelvesStore.getShelfById(this.suggest.shelf_id);
    }
    this.uiState.isProductLoading = false;
  },
  methods: {
    openSuggestMenu(): void {
      this.suggestMenu.show();
      this.$emit('open-suggest-menu');
    },
  },
});
</script>

<style lang="scss" scoped>
.product {
  background: var(--card-bg);
  display: flex;
  padding: 16px 16px 20px 0;
  overflow-x: hidden;
  border-radius: 20px;

  &.is-rtl {
    padding: 16px 0 20px 0;
  }
}

.picture {
  position: relative;
  box-sizing: border-box;
  margin: 0 16px;
  width: 52px;
  height: 52px;
}

.picture-overlay {
  position: relative;
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.info {
  flex: 1 1 auto;
}

.product:last-child .info {
  border-bottom: none;
}

.badge {
  position: relative;
  transform: rotate(-4deg);
  height: 24px;
  padding: 4px 1px;
  white-space: nowrap;
  max-width: 125%;

  &.sale {
    background-color: #4060e3;

    &:before {
      content: url('./img/before-sale.svg');
    }

    &:after {
      content: url('./img/after-sale.svg');
    }
  }

  &.green {
    background-color: #48c600;

    &:before {
      content: url('./img/before-green.svg');
    }

    &:after {
      content: url('./img/after-green.svg');
    }
  }

  &.orange {
    background-color: #fc9000;

    &:before {
      content: url('./img/before-orange.svg');
    }

    &:after {
      content: url('./img/after-orange.svg');
    }
  }

  &.red {
    background-color: #f5523a;

    &:before {
      content: url('./img/before-red.svg');
    }

    &:after {
      content: url('./img/after-red.svg');
    }
  }

  &:before,
  &:after {
    position: absolute;
    width: 12px;
    bottom: 0;
    top: 0;
  }

  &:before {
    left: -10px;
  }

  &:after {
    right: -10px;
  }
}

.badge-text {
  overflow: hidden;
  color: white;
  font-weight: 500;
  font-size: 13px;
}
</style>
