import { OrangeIconWarning } from '@/fsd/shared/icons/warning/OrangeIconWarning';
import { Modal } from '@/fsd/shared/tools/modalNotification';
import { SuggestTypeEnum } from '@/models/Suggest';
import { $gettext } from '@/temp/plugins/gettext';
import { markRaw } from 'vue';

export default (type: SuggestTypeEnum) => {
  if (type === 'box2shelf') {
    return Modal.show({
      title: $gettext('Отсканирована не та марка'),
      text: $gettext('Нужно отсканировать ту же марку, что и при сборке'),
      component: markRaw(OrangeIconWarning),
    });
  }
  if (type === 'shelf2box') {
    return Modal.show({
      title: $gettext('Этот товар нельзя положить в заказ'),
      text: $gettext('Отложите для дальнейших указаний. Возьмите другой товар'),
      component: markRaw(OrangeIconWarning),
    });
  }
};
