import { OrangeIconWarning } from '@/fsd/shared/icons/warning/OrangeIconWarning';
import { Modal } from '@/fsd/shared/tools/modalNotification';
import { $gettext } from '@/temp/plugins/gettext';
import { markRaw } from 'vue';

export default () => {
  return Modal.show({
    title: $gettext('Этот товар уже был собран в другом задании'),
    text: $gettext(
      'Положите его обратно на полку и возьмите следующий. У каждого продукта марка уникальна. Нужно сканировать каждый товар отдельно',
    ),
    component: markRaw(OrangeIconWarning),
  });
};
