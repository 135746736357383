<template>
  <Blur>
    <Layout data-test="rack validation">
      <template #default>
        <Bar is-fixed @close-click="emits('close-click')" />
        <div class="h-full flex flex-col items-center p-4 pt-[80px]">
          <Title2 class="font-bold mb-4">
            {{
              $gettext('Отсканируйте любую ячейку на станции %{rackLocation} из робозоны', {
                rackLocation,
              })
            }}
          </Title2>
          <Body2 class="mb-2">
            {{ $gettext('Дальше вам нужно разместить товары на стеллажах в робозоне.') }}
          </Body2>
          <div class="pt-4">
            <img src="./img/shelf.png" />
          </div>
        </div>
      </template>
    </Layout>
  </Blur>
</template>

<script setup lang="ts">
import { Alerts } from '@/fsd/shared/tools/alertNotification';
import { useRequestBarcode } from '@/hooks/useRequestBarcode';
import Shelf from '@/models/Shelf';
import Suggest from '@/models/Suggest';
import { useShelves } from '@/store/modules/shelves';
import { $gettext } from '@/temp/plugins/gettext';
import Blur from '@/temp/ui/blur/blur.vue';
import Bar from '@/ui/common/bar/bar.vue';
import Layout from '@/ui/common/layout.vue';
import Body2 from '@/ui/common/typo/body-2.vue';
import Title2 from '@/ui/common/typo/title-2.vue';
import { computed, defineEmits, defineProps } from 'vue';

interface ShelfValidationProps {
  suggests: Suggest[];
}

const props = defineProps<ShelfValidationProps>();

const emits = defineEmits<{
  (e: 'validated', shelf?: Shelf): void;
  (e: 'close-click'): void;
}>();

/**
 * Станция на которую приехал|приедет стеллаж в робозоне
 */
const rackLocation = computed((): string => {
  const suggestWithRack = props.suggests.find(suggest => suggest.vars.rack_lock);
  return suggestWithRack?.vars.rack_lock ?? '';
});

/**
 * Возвращает true, если отсканирована ячейка правильного стеллажа
 */
const checkValidShelf = (scannedShelf: Shelf | undefined): boolean => {
  if (!props.suggests.length) {
    return true;
  }

  const suggest = props.suggests[0];
  const suggestShelf = useShelves().shelfById(suggest.shelf_id);

  return scannedShelf?.rack === suggestShelf?.rack;
};

useRequestBarcode(async barcode => {
  try {
    const shelf = await useShelves().getShelfByBarcode(barcode);

    if (checkValidShelf(shelf)) {
      emits('validated', shelf);
      return false;
    }

    return true;
  } catch (error) {
    console.error(error);
    Alerts.error($gettext('Отсканирован неверный штрихкод %{barcode}', { barcode }));
    return true;
  }
});
</script>
