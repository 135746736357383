<template>
  <div class="w-screen h-[calc(100vh-20px)] z-10 fixed top-5 left-0 bg-white">
    <Layout>
      <template #header>
        <Bar
          :icons="{ left: 'back' }"
          :menu-config="menuConfig"
          :menu-title="suggest.product?.title"
          @close-click="$emit('close')"
        />
      </template>
      <template #default>
        <div class="flex flex-col gap-4 bg-main px-4 pb-4 h-full">
          <Title2 class="font-bold">
            {{ $gettext('Отсканируйте Честные знаки c ошибками') }}
          </Title2>
          <Body2>
            {{ $gettext('Мы сообщим о них в поддержку') }}
          </Body2>
          <ReportProductCard :suggest="suggest" />
          <div class="flex justify-center items-center h-full">
            <ScanTrueMarkIcon />
          </div>
        </div>
      </template>
      <template #footer>
        <div class="p-3">
          <UiButton
            data-test="order report-details close-btn"
            class="w-full"
            background-color="secondary"
            @click="$emit('close')"
          >
            {{ $gettext('Закрыть') }}
          </UiButton>
        </div>
      </template>
    </Layout>
  </div>
</template>

<script setup lang="ts">
import ScanTrueMarkIcon from '@/fsd/shared/icons/scan-true-mark-icon.vue';
import { ERROR_NO_SCAN_MARK } from '@/fsd/widgets/TrueMarkMergeReport/constants';
import Suggest from '@/models/Suggest';
import TrueMarkSuggestWrapper from '@/models/TrueMarkSuggestWrapper';
import { $gettext } from '@/temp/plugins/gettext';
import Bar from '@/ui/common/bar/bar.vue';
import Layout from '@/ui/common/layout.vue';
import { MenuItemConfig } from '@/ui/common/menu/types';
import Body2 from '@/ui/common/typo/body-2.vue';
import Title2 from '@/ui/common/typo/title-2.vue';
import UiButton from '@/ui/common/ui-button.vue';
import { computed, defineEmits, defineProps } from 'vue';
import ReportProductCard from './ReportProductCard.vue';

interface ReportItemDetailsProps {
  suggest: Suggest | TrueMarkSuggestWrapper;
}

const props = defineProps<ReportItemDetailsProps>();
const emits = defineEmits<{
  (e: 'close'): void;
  (e: 'noScan', id: Suggest['suggest_id'], errorCode: string): void;
}>();

const menuConfig = computed<MenuItemConfig[]>(() => {
  return [
    {
      buttonText: $gettext('Не могу отсканировать марку'),
      buttonSecondText: $gettext('Не сканируется или нет'),
      dataTest: 'true-mark no-scan',
      iconKey: 'trueMark',
      onClick: () => {
        emits('noScan', props.suggest.suggest_id, ERROR_NO_SCAN_MARK);
      },
    },
  ];
});
</script>
