import Shelf from '@/models/Shelf';
import { $gettext } from '@/temp/plugins/gettext';

export function shelfTypes(shelfType: Shelf['type']): string {
  const shelves = {
    store: $gettext('Склад'),
    incoming: $gettext('Входящий'),
    out: $gettext('Исходящий'),
    trash: $gettext('Списание'),
    lost_and_found: $gettext('Потери и находки'),
    markdown: $gettext('Уценка'),
    office: $gettext('Внутренние нужды'),
    kitchen_menu: $gettext('Меню кухни'),
    kitchen_components: $gettext('Ингредиенты кухни'),
    kitchen_on_demand: $gettext('Выдача кухни'),
    parcel: $gettext('Посылки'),
    repacking: $gettext('Перефасовка'),
    review: $gettext('Разбор'),
    store_robot: $gettext('Робополка'),
  };
  return shelves[shelfType];
}
