<template>
  <div :data-test="`${order?.type || type} bar`">
    <div class="bar" :class="{ fixed: isFixed }">
      <button v-if="icons.left" class="button" data-test="bar left btn" @click="$emit('close-click')">
        <IconBack v-if="icons.left === 'back'" />
        <IconClose v-else />
      </button>
      <div class="content">
        <div class="order">
          <Body2 color="primary" class="truncate font-medium" data-test="bar caption"> {{ caption }}</Body2>
        </div>
        <Caption1 v-if="text" class="mt-0.5" color="secondary" data-test="bar text">{{ text }}</Caption1>
      </div>

      <div class="min-w-[60px]">
        <button v-if="needMenu || customMenu" class="button" data-test="bar right-btn" @click="openMenu">
          <!-- settings сейчас нигде не используется, но как опция на будущее -->
          <IconSettings v-if="icons.right === 'settings'" />
          <IconInfo v-else-if="icons.right === 'info'" />
          <IconBasketRemove v-else-if="icons.right === 'remove'" />
          <IconMenu v-else />
        </button>
      </div>
    </div>

    <SideMenu
      v-if="menuConfig && sideMenu.visible.value"
      :menu-config="menuConfig"
      :title="menuTitle"
      :subtitle="menuSubtitle"
      @close="sideMenu.hide"
    />

    <ProgressLinear v-if="needProgress" :config="progressConfig" :total="total" />
  </div>
</template>

<script lang="ts">
import IconBasketRemove from '@/fsd/shared/icons/icon-basket-remove.vue';
import IconInfo from '@/fsd/shared/icons/icon-info.vue';
import { useComponent } from '@/hooks/useComponent';
import BaseOrder from '@/models/orders/BaseOrder';
import IconBack from '@/temp/icons/icon-back.vue';
import IconClose from '@/temp/icons/icon-close.vue';
import IconMenu from '@/temp/icons/icon-menu.vue';
import IconSettings from '@/temp/icons/icon-settings.vue';
import ProgressLinear from '@/ui/common/bar/progress-linear.vue';
import { ProgressConfig } from '@/ui/common/bar/types';
import SideMenu from '@/ui/common/menu/side-menu.vue';
import type { MenuItemConfig } from '@/ui/common/menu/types';
import Body2 from '@/ui/common/typo/body-2.vue';
import Caption1 from '@/ui/common/typo/caption-1.vue';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  components: {
    ProgressLinear,
    Body2,
    IconMenu,
    IconClose,
    IconBack,
    IconSettings,
    Caption1,
    SideMenu,
    IconInfo,
    IconBasketRemove,
  },
  props: {
    menuConfig: {
      type: Array as PropType<MenuItemConfig[]>,
      default: undefined,
    },
    customMenu: {
      type: Boolean,
      default: false,
    },
    caption: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: undefined,
    },
    order: {
      type: Object as PropType<BaseOrder>,
      default: () => {},
    },
    text: {
      type: String,
      default: undefined,
    },
    progressConfig: {
      type: Array as PropType<ProgressConfig[]>,
      default: () => [],
    },
    total: {
      type: Number,
      default: 100,
    },
    needProgress: {
      type: Boolean,
      default: false,
    },
    icons: {
      type: Object as PropType<{ left?: string; right?: string }>,
      default: () => {
        return {
          left: 'close',
          right: 'menu',
        };
      },
    },
    isFixed: {
      type: Boolean,
      default: false,
    },
    menuTitle: {
      type: String,
      default: '',
    },
    menuSubtitle: {
      type: String,
      default: '',
    },
  },
  emits: ['menu-click', 'close-click'],
  setup() {
    const sideMenu = useComponent();

    return { sideMenu };
  },
  computed: {
    needMenu(): boolean {
      if (!this.menuConfig || this.menuConfig.length === 0) {
        return false;
      }
      return this.menuConfig.some(
        (item: MenuItemConfig) => (!item.condition || item.condition()) && item.type !== 'cancel',
      );
    },
  },
  methods: {
    openMenu(): void {
      this.$emit('menu-click');
      !this.customMenu && this.sideMenu.show();
    },
  },
});
</script>

<style scoped lang="scss">
.bar {
  align-items: center;
  display: flex;
  height: 60px;
  width: 100%;
}

.button {
  align-items: center;
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  height: 100%;
  margin: 0;
  padding: 1rem;
}

.content {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  padding: 12px 0;
  text-align: center;
  overflow: hidden;
}

.empty-btn-fill {
  width: 24px;
}

.fixed {
  position: fixed;
  z-index: 100;
}
</style>
