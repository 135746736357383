<template>
  <div class="w-screen h-[calc(100vh-20px)] overflow-hidden rounded-2xl z-10 fixed top-5 left-0 bg-white">
    <Layout>
      <template #header>
        <Bar
          :caption="$gettext('Размещение Маркета')"
          :text="$gettext('Расхождения')"
          :icons="{ left: 'close' }"
          need-progress
          :progress-config="progressConfig"
          :total="progressConfig.length"
          @close-click="$emit('close')"
        />
      </template>
      <template #default>
        <div class="flex flex-col bg-card">
          <div class="flex flex-col rounded-b-3xl bg-main">
            <Title2 class="px-4 mt-6 font-bold"> {{ $gettext('Подтвердите расхождения') }} </Title2>
            <Body2 class="p-4"> {{ $gettext('Проверьте статусы посылок и убедитесь, что все в порядке.') }} </Body2>
          </div>

          <div class="flex flex-col rounded-3xl bg-main mt-1.5">
            <body1 class="p-4 font-bold"> {{ $gettext('Посылок нет') }} </body1>
            <div v-for="item in noItemSuggests" :key="item">
              <ProductCard
                class="mb-2"
                data-test="differences-no-items product-card"
                :badge-data="{
                  color: 'red-normal',
                  text: $gettext('Нет'),
                }"
                :product-id="item.product_id"
              />
            </div>
          </div>

          <div class="flex flex-col rounded-t-3xl bg-main mt-1.5">
            <body1 class="p-4 font-bold"> {{ $gettext('На полке Возврат посылок') }} </body1>
            <div v-for="item in returnedItemSuggests" :key="item">
              <ProductCard
                data-test="differences-returned-items product-card"
                class="mb-2"
                :need-menu="false"
                :badge-data="{
                  color: 'lime-toxic',
                  text: $gettext('Готово'),
                }"
                :product-id="item.product_id"
                ><template #info>
                  <div class="flex">
                    <div class="flex flex-col">
                      <caption1 color="day-textMinor">
                        {{ $gettext('Полка') }}
                      </caption1>
                      <caption1 class="mt-4" color="day-textMinor">
                        {{ $gettext('Причина') }}
                      </caption1>
                    </div>
                    <div class="flex flex-col">
                      <caption1 class="ml-4"> {{ item.shelf.title }}</caption1>
                      <caption1 class="ml-4 mt-4"> {{ $gettext('Повреждена') }}</caption1>
                    </div>
                  </div>
                </template>
              </ProductCard>
            </div>
          </div>
        </div>
      </template>

      <template #footer>
        <LayoutFooter class="flex">
          <UiButton data-test="confirm decline-btn" background-color="secondary" @click="$emit('close')">
            {{ $gettext('Изменить') }}
          </UiButton>
          <UiButton data-test="confirm ok-btn" class="ml-2" background-color="primary" @click="$emit('close', 'ok')">
            {{ $gettext('Подтвердить') }}
          </UiButton>
        </LayoutFooter>
      </template>
    </Layout>
  </div>
</template>

<script lang="ts">
import Suggest from '@/models/Suggest';
import Bar from '@/ui/common/bar/bar.vue';
import { ProgressConfig } from '@/ui/common/bar/types';
import Layout from '@/ui/common/layout.vue';
import LayoutFooter from '@/ui/common/layout/layout-footer.vue';
import ProductCard from '@/ui/common/product-card/product-card.vue';
import Body2 from '@/ui/common/typo/body-2.vue';
import Title2 from '@/ui/common/typo/title-2.vue';
import UiButton from '@/ui/common/ui-button.vue';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'StowageMarketDifferences',
  components: {
    Bar,
    Layout,
    Body2,
    UiButton,
    LayoutFooter,
    Title2,
    ProductCard,
  },
  props: {
    progressConfig: {
      type: Array as PropType<ProgressConfig[]>,
      default: () => {
        return [] as ProgressConfig[];
      },
    },
    noItemSuggests: {
      type: Array as PropType<Suggest[]>,
      default: () => {
        return [] as Suggest[];
      },
    },
    returnedItemSuggests: {
      type: Array as PropType<Suggest[]>,
      default: () => {
        return [] as Suggest[];
      },
    },
  },
  emits: ['close'],
});
</script>
<style scoped></style>
