import { LangService } from '@/fsd/data/services/lang.service';
import { PlatformService } from '@/fsd/data/services/platform.service';
import { alert } from '@/fsd/shared/tools/alertNotification';
import { modal } from '@/fsd/shared/tools/modalNotification';
import router from '@/router/router';
import { ModeService } from '@/services/mode.service';
import { useModals } from '@/store/modules/modals';
import { useUser } from '@/store/modules/user';
import { Emitter } from '@/temp/plugins/emitter';
import { EventFeedback } from '@/temp/plugins/eventFeedback';
import { gettext } from '@/temp/plugins/gettext';
import { logger } from '@/temp/plugins/logs';
import { notification } from '@/temp/plugins/notification';
import { withUuid } from '@/temp/plugins/uuid';
import Body1 from '@/ui/common/typo/body-1.vue';
import Body2 from '@/ui/common/typo/body-2.vue';
import Body3 from '@/ui/common/typo/body-3.vue';
import Caption1 from '@/ui/common/typo/caption-1.vue';
import Caption2 from '@/ui/common/typo/caption-2.vue';
import Title4 from '@/ui/common/typo/title-4.vue';
import TypoBase from '@/ui/common/typo/typo-base.vue';
import Typography from '@/ui/common/typography.vue';
import createError from '@/utils/create-error';
import { App } from '@capacitor/app';
import { ScreenOrientation } from '@capacitor/screen-orientation';
import { StatusBar } from '@capacitor/status-bar';
import '@yandex-int/rum-counter/dist/bundle/implementation.js';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { createPinia } from 'pinia';
import allSettled from 'promise.allsettled';
import { createApp } from 'vue';
import VueVirtualScroller from 'vue-virtual-scroller';
import Vue3TouchEvents from 'vue3-touch-events';
import Root from './app.vue';
import './styles';

dayjs.extend(utc);
dayjs.extend(timezone);

allSettled.shim();

const pinia = createPinia();
const app = createApp(Root);

const uri = window.location.search.substring(1);
const params = new URLSearchParams(uri);
// включаем пакет для работы с аппаратным сканером только если НЕ дев режим и мы НЕ передали query параметром mode=dialog

if (params.get('mode') === 'dialog') {
  localStorage.setItem('isWeb', 'true');
}

if (!ModeService.isDevelopment() && params.get('mode') !== 'dialog' && !PlatformService.isCapacitor()) {
  const head = document.head || document.getElementsByTagName('head')[0];
  const script = document.createElement('script');
  script.async = true;
  script.src = '/permanent/cordova/cordova.js';
  head.appendChild(script);
}

if (PlatformService.isCapacitor()) {
  StatusBar.hide().catch(logger.error);
  App.addListener('backButton', ({ canGoBack }) => {
    const modals = useModals();

    if (modals.hasOpenModals) modals.closeLastModal();
    else if (canGoBack) router.back();
    else App.exitApp().catch(logger.error);
  }).catch(logger.error);
  ScreenOrientation.lock({ orientation: 'portrait' }).catch(logger.error);
}

app.config.errorHandler = function (error) {
  logger.error(error, { source: 'Vue.config.errorHandler' });
  // роняем тесты при запуске локально
  ModeService.isDevelopment() && createError(error);
  throw error;
};

app.use(pinia);
app.use(Emitter);
app.use(router);
app.mixin(withUuid);
app.mixin(EventFeedback);
app.use(gettext);
// ищем нужный язык и выстаялем его
useUser().setLanguageMutation(LangService.getLang());
app.use(notification);
app.use(alert);
app.use(modal);
// @ts-expect-error не знаю, что здесь происходит, но почему то все ломается
app.use(Vue3TouchEvents, {
  tapTolerance: 100,
  touchHoldTolerance: 1000,
  longTapTimeInterval: 1000,
});
app.use(VueVirtualScroller);

app.component('Typography', Typography);
app.component('TypoBase', TypoBase);
app.component('Caption1', Caption1);
app.component('Caption2', Caption2);
app.component('Body1', Body1);
app.component('Body2', Body2);
app.component('Body3', Body3);
app.component('Title4', Title4);

app.mount('#app');

// отправляем метку, что фреймворк инициализирован
Ya.Rum.sendTimeMark('3036');
