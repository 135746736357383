import { OrangeIconWarning } from '@/fsd/shared/icons/warning/OrangeIconWarning';
import { Modal } from '@/fsd/shared/tools/modalNotification';
import { $gettext } from '@/temp/plugins/gettext';
import { markRaw } from 'vue';

export default () => {
  return Modal.show({
    title: $gettext('Этот товар уже есть в вашем заказе'),
    text: $gettext('Сканировать Честный знак нужно для каждого товара отдельно'),
    component: markRaw(OrangeIconWarning),
  });
};
