<template>
  <Layout>
    <template v-if="needHeader" #header>
      <Bar :caption="$gettext('Чат с поддержкой')" :icons="{ left: 'back' }" type="support-chat" @close-click="close" />
    </template>
    <Body2 v-if="!iframeLoaded">
      {{ $gettext('Загружаем чат с поддержкой') }}
    </Body2>
    <div v-if="iframeSrc" class="flex h-full relative px-4 py-2">
      <iframe :src="iframeSrc" class="flex-1" @load="onLoadIframe" />
    </div>
  </Layout>
</template>

<script lang="ts" setup>
import { api } from '@/fsd/data/api/api.service';
import { useRum } from '@/fsd/shared/metrics';
import { SupportTypeEnum, useUser } from '@/store/modules/user';
import { logger } from '@/temp/plugins/logs';
import Bar from '@/ui/common/bar/bar.vue';
import Layout from '@/ui/common/layout.vue';
import Body2 from '@/ui/common/typo/body-2.vue';
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const enum SupportChat {
  CHAT_LEAVE = 'chatLeave',
}

const userStore = useUser();
const router = useRouter();
const iframeSrc = ref();
const iframeLoaded = ref(false);

// start block metrics
const route = useRoute();
const pageName = route.meta.metrics?.pageName;
let finishChatLeaveMetric: (() => void) | undefined = undefined;
const { startLoading, finishLoading, startDataRendering, startMetric } = pageName
  ? useRum<SupportChat>({
      pageName,
      pageLifecycleHooks: true,
    })
  : {};

startLoading?.();
const unWatch = watch(iframeLoaded, val => {
  if (val) {
    unWatch();
    finishLoading?.();
    startDataRendering?.();
  }
});
// end block metrics

const needHeader = computed(() => {
  return !iframeLoaded.value || userStore.supportType !== SupportTypeEnum.sapsan;
});
const needAutoSend = computed(() => userStore.chatMetaData.initial_message_autosend);

const loadIframeSrc = async (): Promise<void> => {
  try {
    const resp = await api.support.url();
    const url = new URL(resp.data.url);
    if (needAutoSend.value) {
      url.pathname += '/chat';
    }

    for (let key in userStore.chatMetaData) {
      url.searchParams.append(key, userStore.chatMetaData[key]);
    }
    // Признак нужен поддержке, чтобы знать источник обращения
    url.searchParams.append('wms_source', 'tsd');
    iframeSrc.value = url.toString();
  } catch (error) {
    logger.error(error, { type: 'API', block: 'support chat' });
  }
};
const close = () => {
  router.push({ name: 'home' });
};

const onMessage = (event: MessageEvent) => {
  if (event.data === 'backPressed') {
    close();
  }
};

const onLoadIframe = () => {
  iframeLoaded.value = true;
  if (needAutoSend.value) {
    // start metric
    finishChatLeaveMetric = startMetric?.(SupportChat.CHAT_LEAVE).finish;
  }
};

onMounted(() => {
  loadIframeSrc();
  window.addEventListener('message', onMessage);
});

onBeforeUnmount(() => {
  /**
   * Необходимо сбрасывать состояние, чтобы избежать ошибок при открытии других чатов поддержки.
   * При изменении - проверить, что метрика стартует после загрузки iframe
   */
  userStore.setChatMetaData({});
  //  если пользователь возвращается в заказ аппаратной кнопкой назад, то может не сброситься мета
  window.removeEventListener('message', onMessage);
  // finish metric
  finishChatLeaveMetric?.();
});
</script>
