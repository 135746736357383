<template>
  <typo-base style="font-size: 11px; line-height: 12px" :class="colorClass">
    <slot />
  </typo-base>
</template>

<script setup lang="ts">
import { useColor } from '@/fsd/shared/hooks';

const { color } = defineProps<{ color?: string }>();
const { colorClass } = useColor({ color: () => color });
</script>
