<template>
  <Layout>
    <template #header>
      <OrderRetailHeaderMore :order="order" :product="product" @back="$emit('close')" />
    </template>
    <template #default>
      <SuggestDetails :collected="barcodes" :product="product" />
    </template>
    <template #footer>
      <OrderRetailFooterMore @add-more="moreProduct" />
    </template>
  </Layout>
</template>

<script setup lang="ts">
import { SuggestDetails } from '@/fsd/entities/suggest';
import { prepareBarcodes } from '@/fsd/entities/suggest/tools/useShelf2Box';
import { MoreProductSuggestProps } from '@/fsd/features/suggest';
import { Alerts } from '@/fsd/shared/tools/alertNotification';
import { Modal } from '@/fsd/shared/tools/modalNotification';
import { ButtonPositionsEnum } from '@/fsd/shared/universalModal';
import { useRequestBarcode } from '@/hooks/useRequestBarcode';
import Product from '@/models/Product';
import { SuggestStatusEnum } from '@/models/Suggest';
import { default as OrderOrderRetail } from '@/models/orders/OrderOrderRetail';
import { useOrders } from '@/store/modules/orders';
import { useProducts } from '@/store/modules/products';
import { $gettext } from '@/temp/plugins/gettext';
import Layout from '@/ui/common/layout.vue';
import { useLoader } from '@/ui/common/loader/useLoader';
import { computed, defineEmits, defineProps, onMounted, ref, watch } from 'vue';
import OrderRetailFooterMore from './order-retail-footer-more.vue';
import OrderRetailHeaderMore from './order-retail-header-more.vue';

const productsStore = useProducts();
const ordersStore = useOrders();
const { showLoader } = useLoader();

const barcodes = ref<string[]>([]);

interface RetailMoreProduct {
  data: MoreProductSuggestProps;
  order: OrderOrderRetail;
}

const props = defineProps<RetailMoreProduct>();

const emits = defineEmits<{ (e: 'close'): void }>();

const product = computed<Product>(() => {
  return productsStore.productById(props.data.product_id)!;
});

const count = computed<number>(() => {
  return product.value.isTrueWeight ? Product.weightFromBarcode(barcodes.value) : barcodes.value.length;
});

onMounted(() => {
  barcodes.value.push(props.data.barcode);
});

const moreProduct = async () => {
  const confirm = await Modal.show({
    title: $gettext('Этого товара нет в заказе'),
    text: $gettext('Вы уверены, что хотите добавить этот товар в заказ?'),
    btnPosition: ButtonPositionsEnum.horizontal,
    confirmBtnTitle: $gettext('Добавить'),
  });
  if (!confirm) {
    return;
  }
  const { closeLoader } = showLoader();

  try {
    await ordersStore.signal({
      order_id: props.order.order_id,
      signal: 'more_product',
      data: {
        product_id: props.data.product_id,
        count: count.value,
        barcodes: prepareBarcodes(barcodes.value),
        status: SuggestStatusEnum.done,
      },
    });
    await new Promise<void>(resolve => {
      const unWatch = watch(
        () => props.order.suggests,
        suggests => {
          for (const s of suggests) {
            if (s.status === SuggestStatusEnum.done && s.product_id === product.value.product_id) {
              unWatch();
              resolve();
            }
          }
          return;
        },
      );
    });
    emits('close');
  } catch (e) {
    console.error(e);
  } finally {
    closeLoader();
  }
};

useRequestBarcode(async barcode => {
  const { closeLoader } = showLoader();
  try {
    const product = await productsStore.getProductByBarcode(barcode);
    if (props.data.product_id !== product.product_id) {
      Alerts.error($gettext('Отсканирован другой товар'));
      return true;
    }
    barcodes.value.push(barcode);
    return true;
  } catch (error) {
    Alerts.error($gettext('Отсканирован неверный баркод'));
    return true;
  } finally {
    closeLoader();
  }
});
</script>
