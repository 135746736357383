import { ORDERS_WEIGHT } from '@/fsd/widgets/home/carousel/orders_weights';
import BaseOrder, { OrderTypeEnum, OrderWorkStatusEnum } from '@/models/orders/BaseOrder';
import { useOrders } from '@/store/modules/orders';
import {
  createdDateSorter,
  isWaitingForCompleteOrder,
  mergeCreatedSort,
  queueSorter,
  statusSorter,
} from '@/store/utils/sorters';

const hasOrderOrders = (orders: BaseOrder[]) => {
  return orders.find(order => [OrderTypeEnum.order, OrderTypeEnum.order_retail].includes(order.type));
};
export const getWeight = order => {
  if (order.type === OrderTypeEnum.sale_stowage) {
    if (order.vars?.addition_tag) {
      return ORDERS_WEIGHT.sale_stowage[order.vars?.addition_tag];
    }
    if (order.vars.tag === 'parcel') {
      return ORDERS_WEIGHT.sale_stowage.parcel;
    }
  }
  return ORDERS_WEIGHT[order.type].default;
};
export const sameWeightSort = (orders: BaseOrder[]) => {
  if (hasOrderOrders(orders)) {
    const processingOrders = orders
      .filter(order => order.status === OrderWorkStatusEnum.processing)
      .filter(order => !isWaitingForCompleteOrder(order));

    const processingQueueSortedOrders = processingOrders
      .filter(order => useOrders().ordersQueue.indexOf(order.external_id) !== -1)
      .sort(queueSorter);

    const processingNotInQueueSortedOrders = processingOrders
      .filter(order => useOrders().ordersQueue.indexOf(order.external_id) === -1)
      .sort(createdDateSorter);

    const requestOrders = orders.filter(order => order.status === OrderWorkStatusEnum.request);

    const requestQueueSortedOrders = requestOrders
      .filter(order => useOrders().ordersQueue.indexOf(order.external_id) !== -1)
      .sort(queueSorter);

    const requestNotInQueueSortedOrders = requestOrders
      .filter(order => useOrders().ordersQueue.indexOf(order.external_id) === -1)
      .sort(createdDateSorter);

    const processingSortedOrders = mergeCreatedSort(processingQueueSortedOrders, processingNotInQueueSortedOrders);

    const requestSortedOrders = mergeCreatedSort(requestQueueSortedOrders, requestNotInQueueSortedOrders);

    return [...processingSortedOrders, ...requestSortedOrders];
  } else {
    return orders.sort(statusSorter || createdDateSorter);
  }
};
